import { createScales, createTheme, fonts } from "@xcorejs/ui";
import CloseIcon from "components/icons/16/close.svg";
import { theme as xcoreUI } from "design-system/xcore/theme";
import React from "react";

import { breakpointsTheme } from "./theme/breakpoints";
import { buttonTheme } from "./theme/button";
import { cardTheme } from "./theme/card";
import { colorsTheme } from "./theme/colors";
import { containerTheme } from "./theme/container";
import { linkTheme } from "./theme/link";
import { listTheme } from "./theme/list";
import { modalTheme } from "./theme/modal";
import { tagTheme } from "./theme/tag";
import { textTheme } from "./theme/text";
import { typographyTheme } from "./theme/typography";

const baseTheme = createTheme({
  name: "Robe",
  ...containerTheme,
  ...buttonTheme,
  ...textTheme,
  ...typographyTheme,
  ...linkTheme,
  ...tagTheme,
  ...listTheme,
  ...modalTheme,
  ...cardTheme,
  ...createScales({
    ...colorsTheme,
    ...breakpointsTheme,
    ...fonts({
      avenir: "'Avenir', sans-serif",
      heading: "'Avenir', sans-serif",
      text: "'Avenir', sans-serif"
    })
  })
});

export const theme = {
  ...xcoreUI,
  ...baseTheme,
  cards: {
    innerPadding: "2rem",
    default: {
      color: "black",
      background: "#fff",
      borderRadius: 0,
      _title: {
        fontWeight: 600
      }
    },
    variant: {
      elevated: {
        borderRadius: "0",
        boxShadow: "0 0.3rem 0.8rem 0 rgba(36, 49, 70, 0.25)"
      },
      outline: {
        border: "2px solid #dee1e5;"
      }
    }
  },
  input: {
    invalid: {
      borderColor: "#e13030",
      _hover: {
        borderColor: "#e13030"
      },
      _focus: {
        borderColor: "#e13030"
      }
    },
    default: {
      padding: "1.9rem 1.9rem",
      fontFamily: "avenir",
      borderRadius: 0,
      fontSize: { _: "1.6rem", sm: "1.5rem" },
      lineHeight: { _: "1.9rem", sm: "2rem" },
      border: "2px",
      transition: "border 300ms, color 300ms, background 300ms",
      _disabled: {
        opacity: 0.5,
        cursor: "not-allowed"
      }
    },
    sizes: {
      sm: {
        fontSize: { _: "1.6rem", sm: "1.4rem" },
        padding: { _: "1.2rem 1.4rem", sm: "1.4rem 1.4rem" }
      },
      md: {
        fontSize: { _: "1.6rem", sm: "1.5rem" },
        padding: { _: "1.7rem 1.9rem", sm: "1.8rem 1.9rem" }
      },
      lg: {
        fontSize: "1.6rem",
        padding: "1.8rem 1.9rem"
      }
    },
    variant: {
      outline: {
        border: "2px solid #dee1e5",
        color: "rgb(30, 30, 30)",
        _placeholder: {
          color: "rgb(94, 96, 98)"
        },
        _hover: {
          border: "2px solid #c7cace"
        },
        _focus: {
          border: "2px solid #939598",
          outline: 0
        },
        _focusWithin: {
          border: "2px solid #939598",
          outline: 0
        }
      },
      line: {
        paddingLeft: "2rem",
        borderBottom: "2px solid #DEE1E5",
        borderRadius: 0,
        background: "transparent",
        color: "rgb(30, 30, 30)",
        _placeholder: {
          color: "rgb(94, 96, 98)"
        },
        _hover: {
          borderBottom: "2px solid #c7cace"
        },
        _focus: {
          borderBottom: "2px solid #939598",
          outline: 0
        }
      },
      filled: {
        background: "#DEE1E5",
        color: "rgb(30, 30, 30)",
        _placeholder: {
          color: "rgb(94, 96, 98)"
        },
        _hover: {
          background: "#D4D7DC"
        },
        _focus: {
          background: "#c7cace",
          outline: 0
        },
        _focusWithin: {
          background: "#c7cace",
          outline: 0
        }
      },
      unstyled: {
        padding: 0,
        fontSize: "inherit",
        lineHeight: "2rem",
        borderRadius: 0,
        background: "transparent",
        color: "rgb(30, 30, 30)",
        _hover: {
          color: "rgb(30, 30, 30)"
        },
        _focus: {
          color: "rgb(30, 30, 30)",
          outline: "none"
        }
      }
    },
    addon: {
      px: "2rem",
      bg: "#DEE1E5",
      borderLeft: "2px solid #DEE1E5",
      borderRight: "2px solid #DEE1E5",
      borderRadius: 0,
      fontSize: "1.5rem",
      lineHeight: "2rem",
      fontFamily: "avenir"
    }
  },
  formControl: {
    additional: {
      color: "rgba(69, 86, 99, 0.5)",
      fontFamily: "avenir",
      fontSize: "1.2rem",
      lineHeight: "1.8rem"
    },
    error: {
      color: "#e13030",
      fontFamily: "avenir",
      fontSize: "1.2rem",
      lineHeight: "1.8rem"
    },
    label: {
      _text: {
        marginBottom: "0.8rem",
        fontSize: "1.5rem",
        lineHeight: "2rem",
        color: "#1E1E1E",
        fontWeight: 600
      }
    }
  },
  checkbox: {
    control: {
      border: "2px solid #DEE1E5",
      borderRadius: 0,
      controlIconColor: "#e00034",
      _hover: {
        borderColor: "#c7cace"
      },
      _checked: {
        bg: "#fff",
        border: "2px solid #1e1e1e"
      },
      sizes: {
        sm: {
          width: "2rem",
          minWidth: "2rem",
          height: "2rem"
        },
        md: {
          width: "3rem",
          minWidth: "3rem",
          height: "3rem"
        },
        lg: {
          width: "4rem",
          minWidth: "4rem",
          height: "4rem"
        }
      }
    },
    children: {
      marginLeft: "1.5rem",
      fontFamily: "Avenir",
      sizes: {
        sm: {
          fontSize: "1.3rem"
        },
        md: {
          fontSize: "1.4rem"
        },
        lg: {
          fontSize: "1.6rem"
        }
      }
    },
    radio: {
      bg: "#e00034"
    }
  },
  tables: {
    default: {
      fontFamily: "avenir",
      color: "#1e1e1e",
      fontSize: "1.4rem",
      lineHeight: "2.1rem"
    },
    variant: {
      normal: {
        borderTop: "2px solid #dee1e5",
        td: {
          pl: "1.5rem",
          pr: "1.5rem",
          borderBottom: { _: 0, sm: "2px solid #dee1e5" },
          height: { _: "auto", sm: "6rem" },
          _first: {
            pl: { _: "1.5rem", sm: "3rem" }
          },
          _last: {
            pr: { _: "1.5rem", sm: "3rem" },
            borderBottom: "2px solid #dee1e5"
          }
        },
        tr: {},
        th: {}
      },
      stripped: {
        border: 0,
        oddLineColor: "#fff",
        evenLineColor: "#dee1e5",
        td: {},
        tr: {},
        th: {}
      }
    },
    tr: {},
    th: {
      fontSize: "1.5rem",
      lineHeight: "2rem",
      borderBottom: "0.2rem solid #1e1e1e",
      padding: "0.9rem 2.6rem 1.8rem"
    },
    td: {
      padding: "0.9rem 2.6rem 0.9rem",
      fontFamily: "avenir",
      fontSize: "1.4rem",
      lineHeight: "2.1rem"
    }
  },
  select: {
    control: {
      display: "flex",
      width: "100%",
      flexWrap: "nowrap",
      alignItems: "center",
      border: "2px solid #dee1e5",
      borderRadius: 0,
      cursor: "pointer",
      _focusWithin: {
        outline: 0,
        borderColor: "#939598"
      },
      _hover: {
        borderColor: "#c7cace"
      }
    },
    dropdownIndicator: {
      padding: "0 2rem"
    },
    separator: {
      background: "transparent"
    },
    input: {
      color: "#1e1e1e",
      margin: 0,
      fontFamily: "avenir",
      fontSize: "1.6rem",
      transform: "scale(0.9375)",
      fontWeight: 400,
      lineHeight: "2rem"
    },
    menu: {
      _inner: {
        borderRadius: 0,
        mt: "-0.2rem",
        border: "2px solid #939598",
        padding: "1rem"
      }
    },
    menuList: {
      border: "2px solid #939598",
      padding: "1rem"
    },
    option: {
      fontFamily: "avenir",
      fontSize: "1.4rem",
      lineHeight: "1.9rem",
      color: "#5E6062",
      transition: "background 300ms",

      _hover: {
        background: "#c7cace",
        color: "#1e1e1e",
        fontWeight: 600
      },

      _focus: {
        outline: "none"
      },

      _inner: {
        padding: "1rem 2rem",
        transition: "color 300ms"
      }
    },
    selectedOption: {
      color: "#1e1e1e",
      fontWeight: 600
    },
    placeholder: {
      color: "#5E6062",
      fontFamily: "avenir",
      fontSize: "1.5rem",
      fontWeight: 400,
      lineHeight: "2rem"
    },
    singleValue: {
      color: "#1e1e1e",
      fontFamily: "avenir",
      fontSize: "1.5rem",
      fontWeight: 400,
      lineHeight: "2rem"
    },
    multiValue: {
      mr: "1rem",
      color: "#fff",
      fontFamily: "avenir",
      fontSize: "1.2rem",
      fontWeight: 700,
      lineHeight: "1.6rem",
      p: 0
    },
    multiValueContainer: {
      display: "flex",
      alignItems: "center",
      bg: "rgba(94, 96, 98, 1)",
      px: "1.5rem",
      py: "0.4rem"
    },
    valueContainer: {
      minHeight: "5.6rem",
      padding: "0.8rem 1.8rem",
      color: "#5E6062",
      fontFamily: "avenir",
      fontSize: "1.5rem",
      fontWeight: 400,
      lineHeight: "2rem"
    },
    noOptions: {
      fontFamily: "avenir"
    }
  },
  closeButton: {
    default: {
      cursor: "pointer",
      _icon: {
        fill: "#455663",
        fillHover: "#1e1e1e",
        width: "100%",
        height: "100%",
        svg: <CloseIcon />
      }
    },
    sizes: {
      xs: {
        width: "0.8rem",
        height: "0.8rem"
      },
      sm: {
        width: "1.2rem",
        height: "1.2rem"
      },
      md: {
        width: "1.6rem",
        height: "1.6rem"
      },
      lg: {
        width: "2.4rem",
        height: "2.4rem"
      }
    }
  },
  accordion: {
    header: {
      p: "2rem",
      bg: "#fff",
      alignItems: "center",
      justifyContent: "space-between",

      _expanded: {
        _icon: {
          transform: "rotate(180deg)",
          fill: "robe"
        }
      }
    },
    panel: {
      bg: "#fff",
      mt: "0.2rem",
      pb: "2rem"
    }
  },
  carousel: {
    dot: {
      display: "block",
      margin: "0.5rem 0.5rem 0",
      padding: "0",
      width: "3rem",
      height: "0.4rem",
      borderRadius: 0,
      background: "rgba(255, 255, 255, 0.25)",
      _hover: {
        bg: { _: "rgba(255, 255, 255, 0.25)", md: "rgba(255, 255, 255, 0.5)" }
      },
      _focus: {
        bg: { _: "rgba(255, 255, 255, 0.25)", md: "#fff" },
        outline: "none"
      }
    },
    dotActive: {
      background: "#fff",
      boxShadow: "0 2px 5px 0 rgba(255, 255, 255, 0.25)",
      _hover: {
        bg: { _: "#fff", md: "rgba(255, 255, 255, 0.5)" }
      },
      _focus: {
        bg: "#fff",
        outline: "none"
      }
    },
    arrow: {
      position: "absolute",
      padding: 0,
      bg: "transparent",
      border: 0,

      _hover: {
        bg: "transparent"
      },

      _active: {
        bg: "transparent"
      },

      _focus: {
        bg: "transparent",
        outline: "none",
        boxShadow: "none"
      }
    }
  },
  dropdown: {
    box: {
      boxShadow: "0 0.5rem 1rem 0 rgba(30, 30, 30, 0.5)",
      borderRadius: "0",
      minWidth: "22rem",
      maxWidth: "32rem",
      bg: "#fff",
      py: "0"
    },
    item: {
      px: "2rem",
      minHeight: "5rem",
      fontFamily: "avenir",
      fontSize: "1.4rem",
      lineHeight: "2.1rem",
      fontWeight: 600,
      cursor: "pointer",
      color: "#5e6062",

      _hover: {
        background: "#c7cace",
        color: "#1e1e1e"
      }
    },
    leftIcon: {
      fill: "#939598",

      _group: {
        _hover: {
          _icon: {
            fill: "#1e1e1e"
          }
        }
      }
    },
    rightIcon: {
      fill: "#939598",

      _group: {
        _hover: {
          _icon: {
            fill: "#1e1e1e"
          }
        }
      }
    },
    divider: {
      display: "block",
      height: "1px",
      marginTop: ".8rem",
      marginBottom: ".7rem",
      backgroundColor: "rgba(69, 86, 99, 0.1)"
    },
    group: {},
    groupHeader: {
      px: "2rem",
      py: "0.6rem",
      m: 0,
      color: "#0f1f28",
      fontFamily: "avenir",
      fontSize: "1.2rem",
      fontWeight: 500,
      lineHeight: "2rem",
      textTransform: "uppercase"
    }
  },
  iconBox: {
    width: "5rem",
    height: "5rem",
    backgroundColor: "#ee0037",
    borderRadius: "50%",
    _icon: {
      fill: "#fff"
    }
  }
};
