import { Box, Container } from "@xcorejs/ui";
import { useContent } from "@appiodev/xcore-client/xcore-ui";
import GobosColor from "components/product/GobosColors";
import { FC } from "react";
import { ProductDetailPage } from "xcore/types";
import { useLayout } from "xcore";

const ProductColors: FC = () => {
  const [detailPage] = useContent<[ProductDetailPage]>();
  const { stringify } = useLayout();
  return (
    <Box id={"#" + stringify(detailPage.values.anchorGobos)}>
      <Box id={stringify(detailPage.values.anchorGobos)} transform={{ _: "translateY(-6rem)", md: "translateY(-13.9rem)" }} />
      <Container
        flexDirection="column"
        py={{ _: "1.5rem", sm: "5rem" }}
      >
        <Box>
          <GobosColor />
        </Box>
      </Container>
    </Box>
  );
};

export default ProductColors;
