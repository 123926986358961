import { tag } from "@xcorejs/ui";

export const tagTheme = tag({
  default: {
    borderRadius: "0",
    border: "2px solid #5e6062",
    px: "1.5rem",
    py: "0.8rem",
    fontFamily: "avenir",
    fontSize: "1.2rem",
    fontWeight: 700,
    lineHeight: "1.6rem"
  },
  variants: {
    solid: {
      bg: "#5E6062",
      color: "#fff"
    },
    outline: {
      color: "#5E6062",
      py: "0.8rem"
    },
    clear: {
      borderColor: "transparent"
    }
  }
});
