/* eslint-disable @typescript-eslint/no-extra-parens */
import { AspectRatio, BoxProps, Flex, FlexProps, Icon, Typography } from "@xcorejs/ui";
import NextIcon from "components/icons/16/next.svg";
import PrevIcon from "components/icons/16/prev.svg";
import { FC, ReactNode, useMemo } from "react";
import Carousel from "react-multi-carousel";
import styled from "styled-components";
import * as system from "styled-system";
import { Swipeable } from "react-swipeable";

import { RobeCarouselStyle } from ".";
import { useCarousel } from "./data";
import { useLayout } from "xcore";

export type CarouselDoubleProps = {
  children: ReactNode[];
  labels?: string[];
} & BoxProps;

const RobeDoubleCarousel: FC<CarouselDoubleProps> = ({ labels, children, ...props }) => {
  const big = useCarousel();
  const small = useCarousel();

  const shiftedChildren = useMemo(() => [children.slice(1), children[0]], [children]);
  const { general, stringify } = useLayout();

  return (
    <RobeDoubleCarouselStyle
      flexWrap="nowrap"
      flexDirection={{ _: "column", lg: "row" }}
      mx={{ _: "-1.6rem", lg: "0" }}
      {...props}
    >
      <Flex
        flex={{ _: "0 0 100%", lg: "0 0 74rem" }}
        alignItems="flex-start"
        transition="box-shadow 300ms"
        _hover={{ boxShadow: "0 3.5rem 5rem -2rem rgb(30 30 30 / 50%)" }}
      >
        <AspectRatio ratio={9 / 16}>
          <div style={{ width: "100%" }}>
            <Swipeable
              onSwipedLeft={() => {
                big.next();
                small.next();
              }}
              onSwipedRight={() => {
                big.prev();
                small.prev();
              }}
            >
              <RobeCarouselStyle>
                <Carousel
                  ssr
                  swipeable={false}
                  draggable={false}
                  renderDotsOutside
                  keyBoardControl={false}
                  infinite
                  responsive={{
                    xs: {
                      breakpoint: { max: 4000, min: 0 },
                      items: 1
                    }
                  }}
                  showDots={false}
                  arrows={false}
                  ref={big.ref}
                >
                  {children}
                </Carousel>
              </RobeCarouselStyle>
            </Swipeable>
          </div>
        </AspectRatio>
      </Flex>

      <Flex flex="1 1 0%" paddingLeft={{ lg: "3rem" }} flexDirection="column" justifyContent="space-between" overflow="hidden">
        <AspectRatio
          ratio={9 / 16}
          display={{ _: "none", lg: "block" }}
          _hover={{ boxShadow: "0 3.5rem 5rem -2rem rgb(30 30 30 / 50%)" }}
          transition="box-shadow 300ms"
          width="100%"
        >
          <div style={{ width: "100%" }}>
            <RobeCarouselStyle>
              <Carousel
                ssr
                swipeable={false}
                draggable={false}
                keyBoardControl={false}
                renderDotsOutside
                responsive={{
                  xs: {
                    breakpoint: { max: 4000, min: 0 },
                    items: 1
                  }
                }}
                showDots={false}
                arrows={false}
                ref={small.ref}
                infinite
              >
                {shiftedChildren}
              </Carousel>
            </RobeCarouselStyle>
          </div>
        </AspectRatio>

        <Flex justifyContent="space-between" height="9rem">
          <Flex
            alignItems="center"
            cursor="pointer"
            width="50%"
            minWidth="50%"
            maxWidth="50%"
            pl={{ _: "1.5rem", lg: "3rem" }}
            className="prev"
            userSelect="none"
            onClick={() => {
              big.prev();
              small.prev();
            }}
          >
            <Icon svg={<PrevIcon />} fill="#e52b57" />
            <Flex pl="3rem" flexDirection="column" maxWidth="calc(100% - 3rem)">
              <Typography
                variant="lead"
                color="#939598"
                fontSize="2.4rem"
                fontWeight={500}
                lineHeight="3.6rem"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {stringify(general.values.previousBtn)}
              </Typography>
              {labels && labels[(labels.length + small.index - 1) % labels.length] && (
                <Typography
                  variant="p"
                  color="#1e1e1e"
                  fontSize="1.4rem"
                  fontWeight={600}
                  lineHeight="2.1rem"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  maxWidth="100%"
                >
                  {labels[(labels.length + small.index - 1) % labels.length]}
                </Typography>
              )}
            </Flex>
          </Flex>

          <Flex
            alignItems="center"
            cursor="pointer"
            width="50%"
            minWidth="50%"
            maxWidth="50%"
            justifyContent="flex-end"
            pr={{ _: "1.5rem", lg: "3rem" }}
            className="next"
            onClick={() => {
              big.next();
              small.next();
            }}
          >
            <Flex pr="3rem" flexDirection="column" alignItems="flex-end" maxWidth="calc(100% - 3rem)">
              <Typography
                variant="lead"
                color="#939598"
                fontSize="2.4rem"
                fontWeight={500}
                lineHeight="3.6rem"
                overflow="hidden"
                textOverflow="ellipsis"
                width="100%"
              >
                {stringify(general.values.btnNext)}
              </Typography>
              {labels && labels[(labels.length + small.index + 1) % labels.length] && (
                <Typography
                  variant="p"
                  color="#1e1e1e"
                  fontSize="1.4rem"
                  fontWeight={600}
                  lineHeight="2.1rem"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  maxWidth="100%"
                >
                  {labels[(labels.length + small.index + 1) % labels.length]}
                </Typography>
              )}
            </Flex>
            <Icon svg={<NextIcon />} fill="#e52b57" />
          </Flex>
        </Flex>
      </Flex>
    </RobeDoubleCarouselStyle>
  );
};

export default RobeDoubleCarousel;

const RobeDoubleCarouselStyle = styled(RobeCarouselStyle)<FlexProps>`
  display: flex;
  position: relative;
  
  & .prev, & .next {
    transition: background 300ms;
  }

  & .prev:hover, & .next:hover {
    background: #c7cace;
    
    svg path {
      fill: #1e1e1e;
    }
    
    p {
      color: #1e1e1e;
    }
  }
  ${system.flexbox}
`;
