import { usePageContent } from "@appiodev/xcore-client/xcore-ui";
import { Box, BoxProps, Collapse, Flex, Icon } from "@xcorejs/ui";
import DropIcon8 from "components/icons/8/drop.svg";
import Accordion from "design-system/xcore/Accordion";
import AccordionHeader from "design-system/xcore/Accordion/AccordionHeader";
import AccordionItem from "design-system/xcore/Accordion/AccordionItem";
import AccordionPanel from "design-system/xcore/Accordion/AccordionPanel";
import { useAccordion } from "design-system/xcore/Accordion/data";
import { FC, useState } from "react";
import { resolveLocaleField } from "utils/useLocalizedRelation";
import { useLayout } from "xcore";

import { ActiveNavLinkStyle, NavItemProps, SubNavLinkStyle } from ".";
import NavItemLink from "./NavItemLink";
import { Relation } from "@appiodev/xcore-core";
import { Product, ProductValues } from "xcore/types";

interface Props {
  dropdown?: [NavItemProps, NavItemProps[] | undefined][];
  overviewLink?: NavItemProps;
  activeOn?: string[];
}

const NavItemAccordion: FC<Props & BoxProps & NavItemProps> = ({ dropdown, overviewLink, activeOn, ...props }) => {
  const [content] = usePageContent();
  const { accordion } = useAccordion({ multiple: true });

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <Flex
      display={{ _: "flex", md: "none" }}
      flexDirection="column"
      flex="0 0 auto"
      justifyContent="flex-start"
      width={{ _: "100vw", md: "auto" }}
    >
      <Flex position="relative">
        <NavItemLink
          flexDirection="row"
          color={showMobileMenu ? "black" : "#939598"}
          {...!!dropdown?.length && {
            onClick: (e) => {
              e.preventDefault();
              setShowMobileMenu(!showMobileMenu);
            }
          }}
          {...(activeOn?.includes(content.type) && ActiveNavLinkStyle)}
          display={{ _: "flex", md: "none" }}
          {...props}
          variant="accordion"
        />
        {!!dropdown?.length && (
          <Box
            position="absolute"
            right="2rem"
            top={0}
            width="2.2rem"
            height="2.2rem"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
            cursor="pointer"
          >
            <Icon
              svg={<DropIcon8 />}
              fill={showMobileMenu ? "black" : "#939598"}
              transition="transform 300ms"
              position="absolute"
              top="50%"
              left="50%"
              transform={showMobileMenu ? "translate(-50%, -50%) rotate(-180deg)" : "translate(-50%, -50%)"}
            />
          </Box>
        )}
      </Flex>

      <Collapse
        open={showMobileMenu}
        borderTop="2px solid #dee1e5"
        borderBottom="2px solid #dee1e5"
        py="0.5rem"
        mt="1rem"
      >
        <Accordion {...accordion} width={{ _: "100vw", md: "auto" }}>
          {overviewLink && (
            <AccordionItem>
              <NavItemLink
                {...overviewLink}
                {...SubNavLinkStyle}
                pl="2rem"
                pr="2rem"
                {...overviewLinkStyle}
                variant="accordion"
                justifyContent="flex-start"
              />
            </AccordionItem>
          )}
          {dropdown?.map(([item, subItems], i) => (
            <AccordionItem key={i}>
              {subItems?.length ? (
                <>
                  <AccordionHeader
                    icon={<DropIcon8 />}
                    _icon={{ width: "2.2rem", height: "2.2rem", display: "flex", alignItems: "center", justifyContent: "center" }}
                    _expanded={{ _icon: { fill: "black" } }}
                    {...AccordionLinkStyle}
                  >
                    <NavItemLink
                      {...item}
                      variant="accordion"
                      onClick={e => e.preventDefault()}
                      {...SubNavLinkStyle}
                      justifyContent="flex-start"
                    />
                  </AccordionHeader>
                  <AccordionPanel {...AccordionPanelStyle}>
                    {subItems?.map((s, ii) => <SubItem key={ii} {...s} />)}
                  </AccordionPanel>
                </>
              ) : (
                <NavItemLink
                  {...item}
                  {...SubNavLinkStyle}
                  pl="2rem"
                  pr="2rem"
                  variant="accordion"
                  justifyContent="flex-start"
                />
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </Collapse>
    </Flex>
  );
};

export default NavItemAccordion;

const SubItem: FC<NavItemProps> = (p) => {
  const { cms, stringify } = useLayout();

  if (typeof p.content !== "string" && p.content?.type === "productOverviewPage" && p.content.values) {
    const items = resolveLocaleField(
      "itemsRelation",
      p.content.values,
      [] as Relation<Product<keyof ProductValues>>[],
      cms.locales.content
    ) ?? [];

    if (items) {
      return (
        <AccordionItem index={p.content!.id}>
          <AccordionHeader
            icon={<DropIcon8 />}
            _icon={{ width: "2.2rem", height: "2.2rem", display: "flex", alignItems: "center", justifyContent: "center" }}
            _expanded={{ _icon: { fill: "black" } }}
            {...AccordionLinkStyle}
            bg="transparent"
            px="0"
          >
            <NavItemLink
              {...p}
              variant="accordion"
              onClick={e => e.preventDefault()}
              {...SubNavLinkStyle}
              justifyContent="flex-start"
            />
          </AccordionHeader>
          <AccordionPanel {...AccordionPanelStyle}>
            {items?.map((s, ii) => <SubItem key={ii} content={s}>{stringify(s.title)}</SubItem>)}
          </AccordionPanel>
        </AccordionItem>
      );
    }
  }

  return (
    <NavItemLink
      {...p}
      {...SubNavLinkStyle}
      variant="accordion"
      justifyContent="flex-start"
    />
  );
};

const overviewLinkStyle = {
  textTransform: "uppercase"
};

const AccordionLinkStyle = {
  alignItems: "center",
  justifyContent: "space-between",
  px: "2rem",
  py: 0
};

const AccordionPanelStyle = {
  bg: "#dee1e5",
  pb: "0",
  px: "2rem",
  py: "0.5rem"
};
