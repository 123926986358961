import { InlineValue, useContent, usePageContent } from "@appiodev/xcore-client/xcore-ui";
import { Box, Collapse, Container } from "@xcorejs/ui";
import VideoCarousel from "components/VideoCarousel";
import RobeProductDetailHeading from "design-system/robe/RobeProductDetailHeading";
import { FC } from "react";
import { Product, ProductDetailPage } from "xcore/types";
import { useSection } from "./data";

const ProductVideo: FC = () => {
  const [product, { value, stringify }] = usePageContent<Product>();
  const [detailPage] = useContent<[ProductDetailPage]>();
  const anchor = "#" + stringify(detailPage.values.anchorVideo);
  const [active, toggle] = useSection(anchor);

  return (
    <Box py={{ _: "1.5rem", sm: "5rem" }} id={anchor}>
      <Box id={stringify(detailPage.values.anchorVideo)} transform={{ _: "translateY(-6rem)", md: "translateY(-13.9rem)" }} />
      <Container flexDirection="column">
        <RobeProductDetailHeading onClick={toggle} active={active} {...active && { mb: { _: "3rem", sm: "4rem" } }}>
          <InlineValue value={detailPage.values.productVid} />
        </RobeProductDetailHeading>
        <Collapse open={active}>
          <VideoCarousel videos={product.values.videos.map(video => value(video))} />
        </Collapse>
      </Container>
    </Box>
  );
};

export default ProductVideo;
