import { useRef, useCallback } from "react";

const useDelayedAction = (update: () => unknown, delay: number = 200) => {
  const canceled = useRef(false);

  const prevent = useCallback(() => {
    canceled.current = true;
  }, []);

  const act = useCallback((d?: number | null) => {
    canceled.current = false;
    d === null
      ? update()
      : setTimeout(() => !canceled.current && update(), d ?? delay);
  }, [delay, update]);

  return [act, prevent] as const;
};

export default useDelayedAction;
