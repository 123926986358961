import { FC, useState } from "react";
import { Box, Flex, Heading1, CloseControl } from "@xcorejs/ui";
import { useLayout } from "xcore";
import Cookies from "js-cookie";
import RobeRichtext from "components/RobeRichtext";

const UnsupportedBrowser: FC = () => {
  const { stringify, general, ie, ieConsent } = useLayout();
  const [state, setState] = useState(ie && !ieConsent);

  return state ? (
    <Flex
      alignItems="center"
      justifyContent="center"
      zIndex={1000}
      bg="robe"
      background={{
        _: "url(/static/news/news-767.jpg)",
        sm: "url(/static/news/news-1024.jpg)",
        md: "url(/static/news/news-1440.jpg)",
        lg: "url(/static/news/news-1920.jpg)",
        xl: "url(/static/news/news-1920.jpg)"
      }}
      backgroundRepeat="none"
      backgroundPosition="center"
      position="fixed"
      top={0}
      left={0}
      right={0}
      bottom={0}
    >
      <Box padding="10rem" bg="white" position="relative">
        <CloseControl
          position="absolute"
          right="2.4rem"
          top="2.4rem"
          onClick={() => {
            Cookies.set("ie-consent", "1", { expires: 365 });
            setState(false);
          }}
        />

        <Flex justifyContent="center">
          <Heading1>{stringify(general.values.titleBrowser)}</Heading1>
        </Flex>
        <Flex flexDirection="column" alignItems="center" mt="4rem">
          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
              <circle cx="32" cy="32" r="30" stroke="#e00034" strokeWidth="4" />
              <line x1="41" x2="23" y1="23" y2="41" stroke="#e00034" strokeWidth="4" />
              <line x1="23" x2="41" y1="23" y2="41" stroke="#e00034" strokeWidth="4" />
            </g>
          </svg>

          <Box mt="3rem">
            <RobeRichtext value={general.values.textBrowser} />
          </Box>
        </Flex>
      </Box>
    </Flex>
  ) : null;
};

export default UnsupportedBrowser;
