import { FC, forwardRef, InputHTMLAttributes, useContext } from "react";

import { XcoreTheme } from "design-system/xcore/theme";
import styled, { ThemeContext } from "styled-components";
import * as system from "styled-system";
import { useFormControlContext, useFormErrorControlContext } from "design-system/xcore/Form/FormControl";
import { BoxProps, Box, useTheme } from "@xcorejs/ui";

export type SizesProps = { inputSize?: "sm" | "md" | "lg" };
export type VariantProps = { inputVariant?: "outline" | "line" | "filled" | "unstyled" };

export type InputProps = {
  disabled?: boolean;
  invalid?: boolean;
  required?: boolean;
  readOnly?: boolean;
} & VariantProps
& SizesProps
& BoxProps
& system.TypographyProps
& InputHTMLAttributes<unknown>;

export const InputStyle = styled(Box)<InputProps>`
  -webkit-appearance: none;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 3rem white inset !important;
  }
  ${system.typography}
`;

InputStyle.defaultProps = {
  margin: 0
};

const Input = forwardRef<HTMLInputElement, InputProps>((
  {
    disabled,
    invalid,
    required,
    readOnly,
    inputSize = "md",
    inputVariant = "outline",
    ...props
  },
  ref
) => {
  const theme = useTheme() as XcoreTheme;
  const inputProps = theme.input.default;
  const sizeProps = theme.input.sizes[inputSize];
  const variantProps = theme.input.variant[inputVariant];
  const invalidProps = theme.input.invalid;

  const formControl = useFormControlContext();
  const formErrorControl = useFormErrorControlContext();
  const isInvalid = invalid || formErrorControl === "isInvalid";
  const isDisabled = disabled || formControl === "isDisabled";
  const isReadOnly = readOnly || formControl === "isReadOnly";
  const isRequired = required || formControl === "isRequired";

  return (
    <>
      <InputStyle
        {...{ as: "input" } as any}
        disabled={isDisabled}
        required={isRequired}
        aria-invalid={isInvalid}
        aria-required={isRequired}
        aria-disabled={isDisabled}
        readOnly={isReadOnly}
        ref={ref}
        {...inputProps}
        {...sizeProps}
        {...variantProps}
        {...isInvalid && { ...invalidProps }}
        {...props}
      />
    </>

  );
});

export default Input;
