import { Link } from "@appiodev/xcore-client/xcore-ui";
import { Category } from "@appiodev/xcore-core";
import { Box, Button, Container, Flex, SimpleGrid, Tag } from "@xcorejs/ui";
import NewsCarousel from "components/news/NewsCarousel";
import NewsCard from "design-system/robe/cards/News";
import RobeHeading from "design-system/robe/RobeHeading";
import { ComponentProps, FC, ReactNode } from "react";
import { useLayout } from "xcore";
import { News } from "xcore/types";

interface Props {
  title: ReactNode;
  categories: Category[];
  news: News[];
  buttonText: ReactNode;
  buttonLink: ComponentProps<typeof Link>;
  backTo?: number;
}

const NewsSection: FC<Props> = ({ title, news, categories, buttonText, buttonLink, backTo }) => {
  const { header, stringify } = useLayout();

  return (
    <Box
      bg="robe"
      background={{
        _: "url(/static/news/news-767.jpg)",
        sm: "url(/static/news/news-1024.jpg)",
        md: "url(/static/news/news-1440.jpg)",
        lg: "url(/static/news/news-1920.jpg)",
        xl: "url(/static/news/news-1920.jpg)"
      }}
      backgroundRepeat="none"
      backgroundPosition="center"
      py={{ _: "3rem", sm: "8rem" }}
    >
      <Container flexDirection="column" alignItems="center">
        <RobeHeading mb="2rem" color="#fff">{title}</RobeHeading>

        <Flex mb="4rem" maxWidth="70rem" justifyContent="center" alignItems="center" display={{ _: "none", sm: "block" }}>
          <Flex justifyContent="center" flexWrap="wrap" margin="-0.5rem">
            {categories.map(n => (
              <Link content={header.nav.stories} key={n.id} query={`?category=${n.id}`} variant="simple">
                <Tag variant="outline" {...TagStyle}>{stringify(n.name)}</Tag>
              </Link>
            ))}
          </Flex>
        </Flex>

        <Flex mb="6rem" width="100%" justifyContent="center" display={{ _: "none", sm: "flex" }}>
          <SimpleGrid
            gap="3rem"
            columns={{ _: 1, sm: 2, lg: 4 }}
            column="minmax(100px, 300px)"
            width={{ _: "auto", lg: "100%" }}
          >
            {news.map(c => (
              <Link key={c.id} content={c} {...backTo && { query: `?backto=${backTo}` }} variant="simple">
                <NewsCard content={c} variant="default" />
              </Link>
            ))}
          </SimpleGrid>
        </Flex>

        <Box width="100%" display={{ _: "block", sm: "none" }}>
          <NewsCarousel news={news} backTo={backTo} />
        </Box>

        <Link {...backTo && { query: `?backto=${backTo}` }} {...buttonLink}>
          <Button mb={{ _: "-6rem", sm: 0 }} mt={{ _: "-2rem", sm: 0 }}>
            {buttonText}
          </Button>
        </Link>
      </Container>
    </Box>
  );
};

export default NewsSection;

const TagStyle = {
  margin: "0.5rem",
  cursor: "pointer",
  transition: "background 300ms",
  color: "#fff",

  _hover: {
    background: "#5E6062"
  }
};
