import { create } from "zustand";

export interface SubHeaderState {
  showSubheader: boolean;
  setShowSubheader: (value: boolean) => void;
}

const useSubHeaderStore = create<SubHeaderState>((set) => ({
  showSubheader: true,
  setShowSubheader: (value) => set(() => ({ showSubheader: value }))
}));

export default useSubHeaderStore;
