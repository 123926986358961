
import { FC, ReactNode } from "react";
import styled from "styled-components";
import IconBox from "design-system/xcore/IconBox";

import { Card, CardProps, Flex, Stack, Text } from "@xcorejs/ui";

export type DownloadCardProps = {
  title?: string;
  icon?: ReactNode;
} & CardProps;

const DownloadCardStyle = styled(Card)<DownloadCardProps>``;

const DownloadCard: FC<DownloadCardProps> = (
  {
    title,
    icon,
    ...props
  }
) => {
  return (
    <DownloadCardStyle
      variant="outline"
      body={(
        <Flex width="100%" justifyContent="center" position="relative">
          <Stack direction={{ _: "row", md: "column" }} gap="1.6rem" align="center" justify="center" flex="1">
            <IconBox
              width="3.2rem"
              height="3.2rem"
              minWidth="3.2rem"
              icon={icon}
              position={{ _: "absolute", xs: "relative" }}
              left={{ _: "-4.8rem", xs: 0 }}
            />
            <Text
              variant="strong"
              width="100%"
              textAlign={{ _: "left", md: "center" }}
              lineHeight="2rem"
              overflow="hidden"
              display="-webkit-box"
              WebkitLineClamp={2}
              WebkitBoxOrient="vertical"
            >
              {title}
            </Text>
          </Stack>
        </Flex>
      )}
      _body={{
        height: { _: "6rem", xs: "13rem" },
        padding: { _: "1rem 4.7rem 1rem 6.3rem", xs: "2rem" }
      }}
      transition="border 300ms"
      _hover={{ border: "2px solid rgba(69, 86, 99, 0.75)" }}
      maxWidth={{ _: "none", md: "30rem" }}
      {...props}
    />
  );
};

export default DownloadCard;
