import { useRouter } from "next/router";
import { FC } from "react";

import Subheader, { DefaultLeftContent, DefaultMiddleContent, DefaultRightContent } from ".";
import BackToProduct from "../BackToProduct";

export const DefaultSubheader: FC = () => {
  const { query } = useRouter();

  return (
    <Subheader
      leftContent={query?.backto
        ? <BackToProduct />
        : <DefaultLeftContent />}
      middleContent={<DefaultMiddleContent />}
      rightContent={<DefaultRightContent />}
    />
  );
};
