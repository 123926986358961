import { ExtendedTypographyProps, Typography } from "@xcorejs/ui";
import { FC } from "react";

export type RobeHeadingProps = Omit<ExtendedTypographyProps, "type" | "t">;

const RobeHeading: FC<RobeHeadingProps> = ({ children, ...props }) => (
  <Typography
    variant="h1"
    textAlign="center"
    _after={{ content: '"."', color: "robe" }}
    {...props}
  >
    {children}
  </Typography>
);

export default RobeHeading;
