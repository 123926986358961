import { useContent, usePageContent } from "@appiodev/xcore-client/xcore-ui";
import { Flex, Icon, Link, Stack } from "@xcorejs/ui";
import PdfIcon from "components/icons/16/pdf.svg";
import { SubheaderNavLinkStyle } from "components/Layout/Subheader";
import ScrollLink from "components/ScrollLink";
import { FC, useMemo } from "react";
import styled from "styled-components";
import { Product, ProductDetailPage, ShortVideo, ShortVideoValues } from "xcore/types";

import { datasheetUrl } from "./data";
import { useRouter } from "next/router";
import { resolveLocaleField } from "utils/useLocalizedRelation";
import { Relation } from "@appiodev/xcore-core";

const ProductSubHeaderContent: FC = () => {
  const { locale } = useRouter();
  const [product, { stringify, value, cms }] = usePageContent<Product>();
  const [productDetail] = useContent<[ProductDetailPage]>();
  const keyFeatures = resolveLocaleField(
    "keyFeatures",
    value(product.values),
    [] as Relation<ShortVideo<keyof ShortVideoValues>>[],
    cms.locales.content
  );

  const showKeyFeatures = useMemo(() =>
    !!keyFeatures?.filter(k => k.values && stringify(k.values?.title)).length
  , [keyFeatures, stringify]);

  return (
    <Flex alignItems="center" justifyContent="center">
      <Stack gap={{ _: "1rem", lg: "2rem" }}>
        <ScrollLink
          target={"#" + stringify(productDetail.values.anchorAbout)}
          hashSpy={false}
          {...SubheaderNavLinkStyle}
          padding={0}
          position="relative"
        >
          {stringify(productDetail.values.aboutMenu)}
        </ScrollLink>

        {showKeyFeatures && (
          <ScrollLinkMerge
            target={"#" + stringify(productDetail.values.anchorKeyFeatures)}
            {...SubheaderNavLinkStyle}
            padding={0}
            position="relative"
          >
            {stringify(productDetail.values.keyFeaturesTitle)}
          </ScrollLinkMerge>
        )}

        <ScrollLinkMerge
          target={"#" + stringify(productDetail.values.anchorProductPictures)}
          hashSpy={false}
          {...SubheaderNavLinkStyle}
          padding={0}
          position="relative"
        >
          {stringify(productDetail.values.pictures)}
        </ScrollLinkMerge>

        {product.values.videos && (
          <ScrollLinkMerge
            target={"#" + stringify(productDetail.values.anchorVideo)}
            {...SubheaderNavLinkStyle}
            padding={0}
            position="relative"
          >
            {stringify(productDetail.values.productVid)}
          </ScrollLinkMerge>
        )}

        <ScrollLink
          target={"#" + stringify(productDetail.values.anchorDownloads)}
          {...SubheaderNavLinkStyle}
          padding={0}
          position="relative"
        >
          {stringify(productDetail.values.downloads)}
        </ScrollLink>

        {product.values.wheels && (
          <ScrollLink target={"#" + stringify(productDetail.values.anchorGobos)} {...SubheaderNavLinkStyle} padding={0} position="relative">
            {stringify(productDetail.values.gobosColors)}
          </ScrollLink>
        )}

        {product.values.accessories && (
          <ScrollLink
            target={"#" + stringify(productDetail.values.anchorAccessories)}
            {...SubheaderNavLinkStyle}
            padding={0}
            position="relative"
          >
            {stringify(productDetail.values.accessories)}
          </ScrollLink>
        )}

        {product.values.specification && (
          <ScrollLink
            target={"#" + stringify(productDetail.values.anchorTechnicalSpecs)}
            {...SubheaderNavLinkStyle}
            padding={0}
            position="relative"
          >
            {stringify(productDetail.values.technicalSpec)}
          </ScrollLink>
        )}

        {product.fetchedReverseRelations!.inspiration.length > 0 && (
          <ScrollLink
            target={"#" + stringify(productDetail.values.anchorInspiration)}
            {...SubheaderNavLinkStyle}
            padding={0}
            position="relative"
          >
            {stringify(productDetail.values.inspiration)}
          </ScrollLink>
        )}

        {product.fetchedReverseRelations!.relatedNews.length > 0 && (
          <ScrollLink
            target={"#" + stringify(productDetail.values.anchorRelatedNews)}
            {...SubheaderNavLinkStyle}
            padding={0}
            position="relative"
          >
            {stringify(productDetail.values.relatedNews)}
          </ScrollLink>
        )}

        <Link
          variant="simple"
          href={datasheetUrl(product.id, locale ?? "en")}
          target="_blank"
          {...SubheaderNavLinkStyle}
          padding={{ _: "0", lg: "0" }}
          title={stringify(productDetail.values.dataSheetMenu)}
        >
          <Icon svg={<PdfIcon />} mr={0} />
        </Link>
      </Stack>
    </Flex>
  );
};

export default ProductSubHeaderContent;

const ScrollLinkMerge = styled(ScrollLink)` // merges several sections into about section on smaller devices
@media (max-width: 1400px) {
      display: none;
}
`;
