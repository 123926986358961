import { Box, useTheme } from "@xcorejs/ui";
import { XcoreTheme } from "design-system/xcore/theme";

import { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

const IndicatorSeparatorStyle = styled(Box)``;

export const IndicatorSeparator = (props: any) => {
  const { children } = props;
  const theme = useTheme() as XcoreTheme;
  const separatorProps = theme.select.separator;

  return (
    <IndicatorSeparatorStyle {...separatorProps}>
      {children}
    </IndicatorSeparatorStyle>
  );
};
