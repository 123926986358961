import { useEffect, useMemo, useState } from "react";

export const useMediaQuery = (queryString: string) => {
  const [active, setActive] = useState<boolean>();

  const q = useMemo(
    () => typeof window !== "undefined" ? window.matchMedia(queryString) : undefined,
    [queryString]
  );

  useEffect(() => {
    const h = () => setActive(q!.matches);
    "addEventListener" in (q as any)
      ? q!.addEventListener("change", h)
      : q!.addListener(h);

    return () => {
      "removeEventListener" in (q as any)
        ? q!.removeEventListener("change", h)
        : q!.removeListener(h);
    };
  }, [q]);

  useEffect(() => {
    setActive(q!.matches);
  }, [q?.matches]);

  return [active, q];
};
