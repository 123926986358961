import { Content } from "@appiodev/xcore-core";
import { Box, BoxProps, Flex, Typography, useTheme, Container } from "@xcorejs/ui";
import DropIcon8 from "components/icons/8/drop.svg";
import DropdownItem from "design-system/xcore/Dropdown/DropdownItem";
import { Dispatch, FC, useMemo, MutableRefObject } from "react";
import styled from "styled-components";
import { useLayout } from "xcore";
import { ActiveNavLinkStyle, NavItemProps } from ".";
import NavItemLink from "./NavItemLink";
import useDerivedState from "utils/useDerivedState";
import useDelayedAction from "utils/useDelayedAction";
import { usePageContent } from "@appiodev/xcore-client/xcore-ui";
import { XcoreTheme } from "design-system/xcore/theme";
import ProductDropdown from "./ProductDropdown";
import useSWR from "swr";
import { GetProductGridResult } from "pages/api/robe/product-grid";

interface Props {
  content?: Content | string;
  activeOn?: string[];
  dropdown?: [NavItemProps, NavItemProps[] | undefined][];
  delayedState?: MutableRefObject<NodeJS.Timeout>;

  open?: boolean;
  setOpen?: Dispatch<boolean>;
  withLabels?: boolean;
  productDropdown?: boolean;
  isAnythingOpen?: boolean;
}

const NavItemDropdown: FC<Props & Omit<NavItemProps, "content">> = (
  {
    dropdown,
    activeOn,
    open,
    setOpen,
    withLabels,
    productDropdown,
    delayedState,
    isAnythingOpen,
    ...props
  }
) => {
  const [content] = usePageContent();
  const [index, setIndex] = useDerivedState<number | null>(null, [open]);
  const theme = useTheme() as XcoreTheme;
  const { cms } = useLayout();

  const { data } = useSWR<GetProductGridResult>(`/api/robe/product-grid?${cms.locales.content.map(l => `locale=${l}`).join("&")}`);

  const anySubItems = useMemo(() => dropdown?.some(([, subItems]) => subItems?.length !== 0), [dropdown]);
  const anyItems = useMemo(() => dropdown?.length !== 0, [dropdown]);

  const handleMouseEnter = () => {
    if (isAnythingOpen) {
      delayedState && (delayedState.current = setTimeout(() => setOpen?.(true), 500));
    } else {
      setOpen?.(true);
    }
  };

  return (
    <Flex
      position={productDropdown ? "static" : "relative"}
      onMouseLeave={() => clearTimeout(delayedState?.current)}
      display={{ _: "none", md: "flex" }}
    >
      <NavItemLink
        display={{ _: "none", md: "flex" }}
        {...(activeOn?.includes(content.type) && ActiveNavLinkStyle)}
        {...props}
        variant="dropdown"
        fontWeight={700}
        onMouseEnter={handleMouseEnter}
      />
      {productDropdown ? (
        <ScrollBarBoxStyle
          opacity={Number(open)}
          transition="200ms opacity"
          pointerEvents={open ? null : "none"}
          zIndex={open ? 200 : 0}
          position="absolute"
          top="100%"
          left="50%"
          transform="translateX(-50%)"
        >
          <Container>
            {data && <ProductDropdown setOpen={setOpen!} data={data} />}
          </Container>
        </ScrollBarBoxStyle>
      ) : (
        <ScrollBarBoxStyle
          opacity={Number(open)}
          transition="200ms opacity"
          pointerEvents={open ? null : "none"}
          backgroundColor={anyItems ? theme.dropdown.box.bg : "transparent"}
          zIndex={open ? 200 : 0}
          position="absolute"
          left={0}
          top="100%"
          maxHeight={anySubItems && !withLabels ? "auto" : "calc(100vh - 12.6rem)"}
          overflowY={anySubItems && !withLabels ? "visible" : "auto"}
          overflowX={anySubItems && !withLabels ? "visible" : "hidden"}
          padding="2px"
          boxShadow="0 0.5rem 1rem 0 rgba(30, 30, 30, 0.5)"
        >
          <Box>
            {dropdown?.map(([item, subItems], i) => (
              <Box key={i} position="relative">
                {withLabels ? (
                  <>
                    <Typography
                      minWidth="30rem"
                      background="#fff"
                      fontWeight={600}
                      minHeight={{ md: "5rem" }}
                      px="2rem"
                      py="1.4rem"
                      lineHeight="2.2rem"
                      color="black"
                    >
                      {item.children}
                    </Typography>
                    {subItems?.map((s, ii) => (
                      <DropdownItem key={ii} bg="#fff" width="100%">
                        <NavItemLink {...s} variant="dropdown" px="1.4rem" />
                      </DropdownItem>
                    ))}
                  </>
                ) : (
                  <>
                    <NavDropdownItem
                      {...item}
                      subItems={subItems?.length !== 0}
                      activateFast={index === null}
                      setActive={() => setIndex(i)}
                    />
                    <ScrollBarBoxStyle
                      display={index === i ? "block" : "none"}
                      position="absolute"
                      top="0"
                      left="100%"
                      minWidth="30rem"
                      maxHeight={i ? "calc(90vh - 9.4rem - (" + i + "*5rem))" : "calc(100vh - 9.4rem)"}
                      overflowY="auto"
                      boxShadow="0 0.5rem 1rem 0 rgba(30, 30, 30, 0.5)"
                      mr="2px"
                      ml="2px"
                      bg="#fff"
                    >
                      {subItems?.map((s, ii) => (
                        <DropdownItem key={ii} bg="#fff" width="100%">
                          <NavItemLink {...s} variant="dropdown" />
                        </DropdownItem>
                      ))}
                    </ScrollBarBoxStyle>
                  </>
                )}
              </Box>
            ))}
          </Box>
        </ScrollBarBoxStyle>
      )}
    </Flex>
  );
};

export default NavItemDropdown;

interface NavDropdownItem extends NavItemProps {
  setActive: () => unknown;
  activateFast: boolean;
  subItems: boolean;
  overviewLink?: boolean;
}

const NavDropdownItem: FC<NavDropdownItem> = ({ setActive, activateFast, subItems, overviewLink, ...props }) => {
  const [act, prevent] = useDelayedAction(setActive, 120);

  return (
    <DropdownItem
      borderLeft="2px solid transparent"
      borderRight="2px solid transparent"
      minWidth="30rem"
      background="transparent"
      {...subItems && { rightIcon: <DropIcon8 />, _rightIcon: { transform: "rotate(-90deg)" } }}
      bg="#fff"
      {...overviewLink && { display: { _: "flex", md: "none" } }}
      onMouseEnter={() => act(activateFast ? null : 150)}
      onMouseLeave={prevent}
    >
      <NavItemLink {...props} variant="dropdown" />
    </DropdownItem>
  );
};

export const ScrollBarBoxStyle = styled(Box)<BoxProps>`
      ::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      ::-webkit-scrollbar:vertical {
        width: 1.1rem;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: .8rem;
        border: .2rem solid white;
        background-color: rgba(30,30,30,0.5);
      }

      ::-webkit-scrollbar-track {
        background-color: white;
        border-radius: 0;
      }
`;
