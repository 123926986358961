import { InlineValue, Link, useContent, usePageContent } from "@appiodev/xcore-client/xcore-ui";
import { Button, Flex, Icon } from "@xcorejs/ui";
import ContactIcon from "components/icons/16/contact.svg";
import InquiryButton from "components/InquiryButton";
import { FC } from "react";
import { useLayout } from "xcore";
import { Product, ProductDetailPage } from "xcore/types";

const ProductFooter: FC = () => {
  const { header } = useLayout();
  const [product] = usePageContent<Product>();
  const [detailPage] = useContent<[ProductDetailPage]>();

  return (
    <Flex position="fixed" display={{ _: "flex", md: "none" }} width="100%" bottom={0} zIndex={1000}>
      <InquiryButton product={product} width="50%" height="5rem" justifyContent="center" />
      <Link content={header.nav.offices.values.officeList![0].offices![0]} width="50%">
        <Button
          s="sm"
          width="100%"
          justifyContent="center"
          textTransform="none"
          bg="darkGray"
          _hover={{
            bg: "gray"
          }}
          _active={{
            bg: "gray"
          }}
          _focus={{
            bg: "gray",
            outline: "2px solid rgba(15, 31, 40, 0.2)",
            outlineOffset: "-2px"
          }}
          margin={0}
        >
          <Icon svg={<ContactIcon />} fill="#fff" mr="1.5rem" />
          <InlineValue value={detailPage.values.contactBtn} />
        </Button>
      </Link>
    </Flex>
  );
};

export default ProductFooter;
