
import { components } from "react-select";
import styled from "styled-components";

const GroupStyle = styled.div`
  & > div {
    margin: 0;
    padding: 0;
  }
`;

export const Group = props => {
  const { children } = props;

  return (
    <GroupStyle>
      <components.Group {...props}>
        {children}
      </components.Group>
    </GroupStyle>
  );
};
