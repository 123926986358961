
import styled, { ThemeContext } from "styled-components";
import { components } from "react-select";
import css from "@styled-system/css";
import { useContext } from "react";
import { XcoreTheme } from "design-system/xcore/theme";
import { BoxProps, Box, useTheme } from "@xcorejs/ui";

const PseudoSelectors = {
  inner: "& > div"
};

export type MenuSelectProps =
  {
    _inner?: BoxProps;
  } & BoxProps;

const MenuStyle = styled(Box)<MenuSelectProps>`
  ${({ _inner }) => css({
    [PseudoSelectors.inner]: _inner
  })}
`;

export const Menu = (props: any) => {
  const { children } = props;
  const theme = useTheme() as XcoreTheme;
  const menuProps = theme.select.menu;

  return (
    <MenuStyle {...menuProps}>
      <components.Menu {...props}>{children}</components.Menu>
    </MenuStyle>
  );
};
