import { BoxProps, Flex, FlexProps, useTheme } from "@xcorejs/ui";

import { FC, ReactNode, useContext } from "react";

import styled, { ThemeContext } from "styled-components";

import Input, { InputProps, SizesProps } from ".";
import { XcoreTheme } from "../../theme";
import InputAddon from "./InputAddon";

export type InputGroupVariantProps = { inputVariant?: "outline" | "filled" };

export type InputGroupProps = {
  leftAddon?: ReactNode;
  rightAddon?: ReactNode;
  _leftAddon?: BoxProps;
  _rightAddon?: BoxProps;
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  _leftElement?: BoxProps;
  _rightElement?: BoxProps;
  _input?: InputProps;
} & SizesProps
& InputGroupVariantProps
& FlexProps;

const InputGroupStyle = styled(Flex)<FlexProps>`
   display: inline-flex;
   padding: 0;
`;

InputGroupStyle.defaultProps = {
  background: "#fff"
};

const InputGroup: FC<InputGroupProps> = (
  {
    children,
    inputSize = "md",
    inputVariant = "outline",
    _input,
    leftAddon,
    rightAddon,
    _leftAddon,
    _rightAddon,
    leftElement,
    rightElement,
    _leftElement,
    _rightElement,
    ...props
  }
) => {
  const theme = useTheme() as XcoreTheme;
  const inputStyle = theme.input.default;
  const sizeStyle = theme.input.sizes[inputSize];
  const variantStyle = theme.input.variant[inputVariant];

  return (
    <InputGroupStyle role="group" as="label" {...!children && { ...inputStyle }} {...!children && { ...variantStyle }} {...props}>
      {children || (
        <>
          {leftAddon && (
            <InputAddon
              {..._leftAddon}
              borderTopRightRadius="0"
              borderBottomRightRadius="0"
              borderLeft="0"
            >
              {leftAddon}
            </InputAddon>
          )}

          {leftElement && <Flex flexShrink={0} alignItems="center" pl="1.5rem" {..._leftElement}>{leftElement}</Flex>}
          <Input
            inputVariant="outline"
            {...inputStyle}
            {...sizeStyle}
            {...variantStyle}
            {...leftAddon && { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            {...rightAddon && { borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            border="0"
            width="100%"
            _hover={{ border: 0 }}
            _focus={{ outline: 0 }}
            _focusWithin={{ outline: 0 }}
            {..._input}
          />
          {rightElement && <Flex flexShrink={0} alignItems="center" pr="1.5rem" {..._rightElement}>{rightElement}</Flex>}

          {rightAddon && (
            <InputAddon
              {..._rightAddon}
              borderTopLeftRadius="0"
              borderBottomLeftRadius="0"
              borderRight="0"
            >
              {rightAddon}
            </InputAddon>
          )}
        </>
      )}

    </InputGroupStyle>
  );
};

export default InputGroup;
