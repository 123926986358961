import { Box, BoxProps } from "@xcorejs/ui";
import { FC, ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import useSWR from "swr";

type Props = {
  src: string;
  fill?: string;
  loadingFallback?: ReactElement;
} & BoxProps;

const ExternalSVGStyle = styled(Box)<{ fill?: string }>`
  svg {
    width: 100%;
    height: 100%;

    path {
      fill: ${props => props.fill};
      transition: fill 300ms;
    }
  }
`;

const ExternalSVG: FC<Props> = ({ src, fill, loadingFallback, ...props }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const { data: svg } = useSWR(
    src,
    (s) => fetch(s, { mode: "cors" })
      .then(res => res.text())
      .catch((e) => {
        console.error({ e });

        return errorSvg;
      })
  );

  useEffect(() => {
    if (svg) {
      setLoading(false);
    }
  }, [svg]);

  if (loading && loadingFallback) {
    return loadingFallback;
  }

  return (
    <ExternalSVGStyle dangerouslySetInnerHTML={{ __html: svg ?? "" }} {...fill && { fill }} {...props} />
  );
};

export default ExternalSVG;

const errorSvg = `
  <svg viewBox="0 0 64 64"><text x="20" y="43" fill="red" style="font-weight: 700; font-size: 3rem;">?!</text></svg>
`;
