import { SFCategory, SFMedia } from "@robe/admin/data/types";
import { Link } from "@appiodev/xcore-client/xcore-ui";
import { Box, Button, Flex, Icon, Modal, Typography } from "@xcorejs/ui";
import DownloadButton from "components/DownloadButton";
import DropIcon from "components/icons/16/drop.svg";
import Accordion from "design-system/xcore/Accordion";
import AccordionHeader from "design-system/xcore/Accordion/AccordionHeader";
import AccordionItem from "design-system/xcore/Accordion/AccordionItem";
import AccordionPanel from "design-system/xcore/Accordion/AccordionPanel";
import { useAccordion } from "design-system/xcore/Accordion/data";
import { FC, useMemo } from "react";
import { duplicationDestroyer, getExt, getFileSizeString } from "support/utils";
import urljoin from "url-join";
import { useLayout } from "xcore";
import { encodePath } from "@robe/admin/data/RobeSupportFilesMedia";

interface Props {
  id: number;
  category: SFCategory;
  downloads: SFMedia[];
  buttonSuppFiles: string;
}

const ProductDownloadsModal: FC<Props> = (
  {
    id,
    category,
    downloads,
    buttonSuppFiles
  }
) => {
  const { header: { nav }, stringify } = useLayout();
  const validFiles = useMemo(() => downloads.filter(f => f.categoryId === category.id), [category.id, downloads]);

  const { accordion } = useAccordion({
    multiple: true
  });

  const dedupedValidFiles = useMemo(() => duplicationDestroyer(validFiles), [validFiles]);

  return (
    <Modal
      maxWidth="120rem"
      header={category.localeName ? stringify(category.localeName) : category.name}
      _header={{ justifyContent: "center" }}
      px={{ sm: "6rem !important" }}
      onClose={() => void (window.location.hash = "#downloads")}
    >
      <Flex width="100%" height="100%" flexDirection="column" overflow="auto">
        <Box overflow="auto">
          <Accordion {...accordion}>
            {dedupedValidFiles.map((f, k) => (
              <AccordionItem key={k}>
                <AccordionHeader
                  _expanded={{ _icon: { fill: "black" } }}
                  cursor={f.description ? "pointer" : "default"}
                  alignItems="center"
                  justifyContent="space-between"
                  borderTop="2px solid rgb(222, 225, 229)"
                  withAnimatedIcon
                  customIcon
                >
                  <Flex
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                    flexDirection={{ _: "column", md: "row" }}
                  >
                    <Flex alignItems="center" width="100%" flexDirection={{ _: "column", md: "row" }}>
                      <Typography
                        display="flex"
                        fontWeight={700}
                        width={{ _: "100%", md: "40%" }}
                        alignItems="center"
                      >
                        {f.title}
                        {f.description && <Icon svg={<DropIcon />} ml="1.5rem" />}
                      </Typography>

                      <Typography width={{ _: "100%", md: "60%" }} pl={{ _: 0, md: "2rem" }}>
                        {f.categories?.filter((v, i, a) =>
                          a.findIndex(t => t.id === v.id) === i).map(c => c.localeName ? stringify(c.localeName) : c.name).join(", ")}
                      </Typography>
                    </Flex>

                    <Flex
                      flex="0 0 auto"
                      minWidth="24rem"
                      flexDirection={{ _: "column", md: "row" }}
                      justifyContent={{ _: "center", md: "flex-end" }}
                    >
                      <DownloadButton
                        href={f.url ?? urljoin("/res", encodePath(f.path!))}
                        mt={{ _: "1rem", md: 0 }}
                        flex="0 0 auto"
                      >
                        {getExt(f.path!) && getExt(f.path!) + ","}
                        {f.size
                          ? getFileSizeString(f.size)
                          : f.content
                            ? "Internal file"
                            : "External file"}
                      </DownloadButton>
                    </Flex>
                  </Flex>
                </AccordionHeader>

                {f.description && (
                  <AccordionPanel>
                    <Box p="2rem">
                      <Typography>
                        <div dangerouslySetInnerHTML={{ __html: f.description }} />
                      </Typography>
                    </Box>
                  </AccordionPanel>
                )}
              </AccordionItem>
            ))}
          </Accordion>
        </Box>

        <Flex justifyContent="center" mt="3rem" flex="0 0 auto">
          <Link content={nav.support} query={`?product=${id}&category=${category.id}`}>
            <Button>{buttonSuppFiles}</Button>
          </Link>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ProductDownloadsModal;
