import { useLink, usePageContent } from "@appiodev/xcore-client/xcore-ui";
import Head from "next/head";
import { FC, useEffect, useState } from "react";
import { useLayout } from "xcore";
import { File } from "@appiodev/xcore-core";
import { useRouter } from "next/router";
import { isNewsGoogleStructuredDataType, NewsGoogleStructuredDataType } from "utils/googleStructuredData/useNewsGoogleStructuredData";
import {
  isProductGoogleStructuredDataType,
  ProductGoogleStructuredDataType
} from "utils/googleStructuredData/useProductGoogleStructuredData";

// todo xcc move this to xcore config json
const DOMAINS_SPECIFIC_LOCALES: Record<string, { contentLocale: string; regionLocale?: string }> = {
  "www.robe.cz": {
    contentLocale: "en"
  },
  "www.robeuk.com": {
    contentLocale: "en",
    regionLocale: "en-GB"
  },
  "www.robelighting.es": {
    contentLocale: "en",
    regionLocale: "es"
  },
  "www.robelighting.it": {
    contentLocale: "en",
    regionLocale: "it"
  },
  "www.robelighting.com": {
    contentLocale: "en-NA"
  },
  "www.robelighting.asia": {
    contentLocale: "en-SEA"
  },
  "www.robelighting.de": {
    contentLocale: "de"
  },
  "robelighting.fr": {
    contentLocale: "fr"
  },
  "www.robe.ru": {
    contentLocale: "ru"
  },
  "www.roberussia.com": {
    contentLocale: "ru"
  }
};

interface Props {
  title?: string | string[];
  metaTitle?: string | string[];
  description?: string;
  url?: string;
  img?: string | File;
  googleStructuredData?: NewsGoogleStructuredDataType | ProductGoogleStructuredDataType | null;
}

const RobeHead: FC<Props> = ({ title: titleSpecified, metaTitle, googleStructuredData, description: _description, url, img, children }) => {
  const { locale } = useRouter();
  const [content, { domain, stringify, title: getTitle, file }] = usePageContent();
  const { general, contentTypes } = useLayout();
  const { as } = useLink();
  const [currentDomain, setCurrentDomain] = useState<null | string>(null);
  const [resolvedPathname, setResolvedPathname] = useState<null | string>(null);
  const [pathHasQueryParamsOrAnchor, setPathHasQueryParamsOrAnchor] = useState(false);

  const title = titleSpecified ?? getTitle(content);
  const titleResolved = resolveTitle(title);
  const metaTitleResolved = resolveTitle(metaTitle ?? title);

  const description = _description ?? stringify(general.values.metaDescription);

  useEffect(() => {
    setCurrentDomain(window.location.origin);
    setResolvedPathname(window.location.pathname);
    (window.location.search !== "" || window.location.hash !== "") && setPathHasQueryParamsOrAnchor(true);
  }, []);

  const validateGoogleStructuredData = () =>
    googleStructuredData?.["@type"] === "Product"
      ? isProductGoogleStructuredDataType(googleStructuredData)
      : googleStructuredData?.["@type"] === "Article"
        ? isNewsGoogleStructuredDataType(googleStructuredData)
        : false;

  const isValidGoogleStructuredData = validateGoogleStructuredData();

  const stringifyGoogleStructuredData = (): string | null => {
    try {
      return JSON.stringify(googleStructuredData);
    } catch (error) {
      console.error("Error stringifying structured data:", error);
      return null;
    }
  };

  const stringifiedGoogleStructuredData = stringifyGoogleStructuredData();

  return (
    <Head>
      <title key="title">{titleResolved}</title>

      {/* Add google structured data only if the structure is valid and if the stringify function does not throw an error in order to make dangerouslySetInnerHTML as safe as possible */}
      { /* It is not possible to pass JSON.stringify(googleStructuredData) directly to the script because the text gets encoded and Google Search Console does not recognise it as valid Google Structured Data. DangerouslySetInnerHTML required to bypass the encoding */}
      {stringifiedGoogleStructuredData && isValidGoogleStructuredData && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: stringifiedGoogleStructuredData
          }}
        />
      )}

      <meta key="og_title" property="og:title" content={metaTitleResolved} />
      <meta key="meta_title" name="title" content={metaTitleResolved} />

      <meta key="og_description" property="og:description" content={description} />
      <meta key="description" name="description" content={description} />

      {contentTypes[content.type]?.routes?.[locale ?? "en"] && (
        <meta
          key="og_url"
          property="og:url"
          content={url ?? as(content, { domain })}
        />
      )}

      <meta
        key="og_image"
        property="og:image"
        content={(typeof img === "string" ? img : file(img, { width: 1200, height: 630 })) ?? "/static/og-image@2x.png"}
      />

      <meta
        key="og_locale"
        property="og:locale"
        content={locale}
      />

      {/* todo xcc add default domain option to xcore config json */}
      {process.env.NODE_ENV === "production" && <link rel="alternate" hrefLang="x-default" href="https://www.robe.cz/" />}

      {/* Provide different language versions for specific regions if content locale is available for current page content type */}
      {process.env.NODE_ENV === "production" &&
        Object.entries(DOMAINS_SPECIFIC_LOCALES).map(([d, { contentLocale, regionLocale }], i) =>
          contentTypes[content.type]?.routes?.[contentLocale] &&
          <link key={i} rel="alternate" href={`https://${d}${as(content)}`} hrefLang={regionLocale ?? contentLocale} />
        )}

      {pathHasQueryParamsOrAnchor && <meta name="robots" content="noindex" />}
      {currentDomain && resolvedPathname && <link rel="canonical" href={`${currentDomain}${resolvedPathname}`} />}

      {children}
    </Head>
  );
};

export default RobeHead;

const resolveTitle = (title: string | string[]) => {
  return typeof title === "string" ? title : title.filter(Boolean).join(" | ");
};
