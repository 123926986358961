import { AspectRatio, Box, Card, Img, Stack, Text, Typography, BoxProps } from "@xcorejs/ui";
import { forwardRef, useEffect, useState } from "react";
import { useLayout } from "xcore";
import { News } from "xcore/types";
import { Skeleton } from "@mui/joy";

type NewCardProps = {
  content: News<"title" | "intro" | "thumbnailIntro" | "content" | "images" | "size" | "thumbnail">;
  variant: "default" | "large" | "fullBg" | "outline";
  couldBeLarge?: boolean;
} & BoxProps;

const NewsCard = forwardRef<HTMLDivElement, NewCardProps>((
  {
    content: { values, publishedAt, categories },
    couldBeLarge,
    ...props
  },
  ref
) => {
  const { file, stringify, formatDate, richtextToString, localize } = useLayout();
  const size = stringify(values.size);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
  const [showLoading, setShowLoading] = useState<boolean>(false); // Shows debounced loading if image is still being downloaded
  const timeUntilShowLoading = 400; // in ms

  useEffect(() => {
    if (isImageLoading) { // Show loading with debounce
      const timeout = setTimeout(() => setShowLoading(true), timeUntilShowLoading);

      return () => clearTimeout(timeout);
    }

    setShowLoading(false); // Do not show loading if image is loaded
  }, [isImageLoading]);

  const img = values.thumbnail
    ? file(values.thumbnail, { width: 300, height: 225 })
    : values.images ? size === "2"
      ? file(values.images?.[0], { width: 300, height: 450 })
      : size === "3"
        ? file(values.images?.[0], { width: 630, height: 450 })
        : file(values.images?.[0], { width: 300, height: 225 }) : "";

  const imgRetina = values.thumbnail
    ? size === "2"
      ? file(values.thumbnail, { width: 300, height: 450 })
      : file(values.thumbnail, { width: 300, height: 225, enlargement: 2 })
    : values.images ? size === "2"
      ? file(values.images?.[0], { width: 300, height: 450, enlargement: 2 })
      : size === "3"
        ? file(values.images?.[0], { width: 630, height: 450, enlargement: 2 })
        : file(values.images?.[0], { width: 300, height: 225, enlargement: 2 }) : "";

  const titleColor = img ? size === "2" || size === "3" ? "#ffffff" : "#1e1e1e" : "#fff";
  const textColor = img ? size === "2" || size === "3" ? "#ffffff" : "#5e6062" : "#fff";
  const innerPadding = size === "3" ? { _: "2rem", sm: "2rem 2rem 7rem 2rem" } : "2rem";

  return (
    <Card
      ref={ref}
      {...props}
      variant="elevated"
      media={(
        <AspectRatio
          ratio={size === "3" ? 113 / 300 : 225 / 300}
          pb={size === "3" ? { _: "75%", sm: couldBeLarge ? "39%" : "75%" } : "75%"}
        >
          {size !== "2" && size !== "3" && img && (
            <Skeleton loading={showLoading}>
              <Img
                src={img}
                srcSet={`${img} 1x, ${imgRetina} 2x`}
                alt={stringify(values.title)}
                width="100%"
                height="100%"
                loading="lazy"
                onLoad={() => setIsImageLoading(false)}
              />
            </Skeleton>
          )}
        </AspectRatio>
      )}
      _media={{
        justifyContent: "center",
        marginTop: 0,
        marginX: 0,
        flex: "0 0 auto"
      }}
      body={(
        <>
          {(size === "2" || size === "3") && (
            <Box width="100%" height="100%" position="absolute" top={0} left={0}>
              <Skeleton loading={showLoading}>
                <Img
                  src={imgRetina}
                  alt={stringify(values.title)}
                  width="100%"
                  height="100%"
                  loading="lazy"
                  objectFit="cover"
                  onLoad={() => setIsImageLoading(false)}
                />
                <Box
                  height="23.5rem"
                  bg="linear-gradient(180deg, rgba(30, 30, 30, 0) 0%, black 100%)"
                  position="absolute"
                  bottom={0}
                  left={0}
                  right={0}
                />
              </Skeleton>
            </Box>
          )}
          <Stack gap="1rem" direction="column" position="relative" zIndex={1000}>
            <Text
              color={size === "2" || size === "3" ? "#fff" : "gray"}
              fontSize="1.2rem"
              lineHeight="1.6rem"
              {...(size === "2" || size === "3") && { textShadow: "1px 1px #000000" }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: formatDate(
                    typeof publishedAt === "string" ? publishedAt : localize(publishedAt)
                  ).replace(/\//g, "&nbsp;/&nbsp;")
                }}
              />
            </Text>
            <Text
              variant="strong"
              color={titleColor}
              fontSize="1.5rem"
              lineHeight="2rem"
              {...(size === "2" || size === "3") && { textShadow: "1px 1px #000000" }}
            >
              {stringify(values.title)}
            </Text>
            <Typography
              variant="p"
              fontSize="1.5rem"
              lineHeight="2rem"
              color={textColor}
              display="-webkit-box"
              WebkitLineClamp={5}
              WebkitBoxOrient="vertical"
              overflow="hidden"
              {...(size === "2" || size === "3") && { textShadow: "1px 1px #000000" }}
            >
              {stringify(values.thumbnailIntro)
                ? stringify(values.thumbnailIntro)
                : stringify(values.intro)
                  ? stringify(values.intro)
                  : richtextToString(values.content)}
            </Typography>
          </Stack>
        </>
      )}
      _body={{ padding: innerPadding, bg: img ? "#fff" : "text", height: "100%" }}
      tag={stringify(categories?.["news-categories"]?.[0]?.name)}
      _tag={{
        position: "absolute",
        margin: 0,
        right: 0,
        top: 0,
        _group: { _hover: { bg: "#1e1e1e", borderColor: "#1e1e1e" } },
        zIndex: 1
      }}
      transition="box-shadow 300ms, border 300ms"
      boxShadow="0 3px 10px 0 rgba(30, 30, 30, 0.25);"
      _hover={{ boxShadow: "0 0.6rem 1.5rem 0 rgba(30, 30, 30, 0.5)" }}
      maxWidth="none"
      height="100%"
    />
  );
});

export default NewsCard;
