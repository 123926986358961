import { FlexProps, Text, TextProps, useTheme } from "@xcorejs/ui";
import { XcoreTheme } from "design-system/xcore/theme";
import { ReactNode, useContext } from "react";
import { components } from "react-select";
import styled, { ThemeContext } from "styled-components";
import * as system from "styled-system";

import CloseButton from "../CloseButton";

export type MultiValueSelectProps =
  & {
    icon: ReactNode;
  }
  & FlexProps
  & TextProps;

const ResetStyle = styled.div`
  & > * {
    padding: 0 !important;
    margin: 2px 2px 2px 0 !important;
    background: transparent;
    border: 0;
    font-size: inherit;
    line-height: inherit;
    
    &:hover {
      background: transparent !important;
    }
  }
`;

const MultiValueStyle = styled(Text)<MultiValueSelectProps>`
  & > * {
    padding: 0 !important;
    margin: 0;
    background: transparent;
    border: 0;
    font-size: inherit;
    line-height: inherit;
    
    &:hover {
      background: transparent;
    }
  }
  ${system.typography};
`;

export const MultiValue = (props: any) => {
  const { children } = props;
  const theme = useTheme() as XcoreTheme;
  const multiValueProps = theme.select.multiValue;

  return (
    <ResetStyle>
      <components.MultiValue {...props}>
        <MultiValueStyle {...multiValueProps as any}>
          {children}
        </MultiValueStyle>
      </components.MultiValue>
    </ResetStyle>
  );
};

export const MultiValueRemove = (props: any) => {
  return (
    <ResetStyle>
      <components.MultiValueRemove {...props}>
        <CloseButton size="xs" _icon={{ fill: "rgba(222, 225, 229, 1)", fillHover: "#fff" }} />
      </components.MultiValueRemove>
    </ResetStyle>
  );
};

export const MultiValueContainer = (props: any) => {
  const { children } = props;
  const theme = useTheme() as XcoreTheme;
  const multiValueContainerProps = theme.select.multiValueContainer;

  return (
    <ResetStyle>
      <components.MultiValueContainer {...props}>
        <MultiValueStyle {...multiValueContainerProps as any}>
          {children}
        </MultiValueStyle>
      </components.MultiValueContainer>
    </ResetStyle>
  );
};
