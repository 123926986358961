import { Link } from "@appiodev/xcore-client/xcore-ui";
import { XcoreCMS } from "@appiodev/xcore-core";
import { Box, Flex, Icon, Stack, Text } from "@xcorejs/ui";
import ArrowIcon from "components/icons/8/arrow-down.svg";
import useDropdown from "design-system/xcore/Dropdown";
import DropdownItem from "design-system/xcore/Dropdown/DropdownItem";
import { useRouter } from "next/router";
import { FC } from "react";
import { useUser } from "utils/useUser";
import { useLayout } from "xcore";

import { swrFetcher, useRevalidateCache } from "../../pages/_app";

export interface WebUser {
  id: number;
  username: string;
  email: string;
  name: string;
  firstName?: string;
  lastName?: string;
  phone: string;
  company: string;
  title: string;
}

const LoggedBar: FC = () => {
  const { isPreview, asPath, push } = useRouter();
  const { user } = useUser();
  const { general, stringify, cms } = useLayout();
  const revalidate = useRevalidateCache();

  const { OnClick } = useDropdown(
    {
      closeOnSecondClick: true,
      position: "absolute",
      locationY: "top",
      locationX: "left",
      _dropdownProps: {
        right: "0 !important",
        top: "100% !important"
      }
    }
  );

  return (
    <>
      <Box position="fixed" top={0} left={0} right={0} zIndex={1005}>
        {user && (
          <StripBar color="rgba(24, 29, 40, 1)">
            <Text fontSize="14px" lineHeight="17px" color="#fff">
              {stringify(general.values.userLoginBarLogo)}
            </Text>

            <Flex alignItems="center">
              <Stack gap="12px">
                <Stack gap="4px" alignItems="center">
                  <Text fontSize="14px" color="rgba(191, 195, 220, 0.5)">
                    {user.company}
                  </Text>
                </Stack>

                <OnClick
                  props={{
                    children: (
                      <>
                        <Link content={general.values.accountUpdate} width="100%">
                          <DropdownItem width="100%">
                            {stringify(general.values.accountUpdate.title)}
                          </DropdownItem>
                        </Link>

                        <DropdownItem
                          onClick={userLogout(cms, revalidate)}
                        >
                          Logout
                        </DropdownItem>
                      </>
                    )
                  }}
                >
                  {active => (
                    <Stack gap="8px" alignItems="center" userSelect="none" cursor="pointer">
                      <Text fontSize="14px" color="rgba(191, 195, 220, 0.5)">
                        {user.firstName}{" "}{user.lastName}
                      </Text>
                      <Icon
                        svg={<ArrowIcon />}
                        fill="rgba(191, 195, 220, 0.5)"
                        transform={active ? "rotate(180deg)" : "rotate(0deg)"}
                        transition="transform 300ms"
                      />
                    </Stack>
                  )}
                </OnClick>
              </Stack>
            </Flex>
          </StripBar>
        )}
        {isPreview && (
          <StripBar color="robe">
            <Flex justifyContent="center" textAlign="center" width="100%">
              <Flex position="absolute" left={45} cursor="pointer" onClick={() => exitPreview(asPath, push)}>
                <Icon
                  svg={<ArrowIcon />}
                  transform="rotate(90deg)"
                  fill="white"
                  mr="1.3rem"
                  mb=".25rem"
                />
                <Text as="strong" color="white">Exit</Text>
              </Flex>

              <Text as="strong" color="white">Preview mode</Text>
            </Flex>
          </StripBar>
        )}
      </Box>
      <Box mb={{ _: 0, md: `${(+!!user + +isPreview) * 32}px` }} />
    </>
  );
};

export default LoggedBar;

const exitPreview = (asPath: string, push) => {
  fetch("/api/robe/exit-preview", { method: "POST" }).then((res) => res.ok && push(asPath));
};

export const userLogout = (cms: XcoreCMS, revalidate: () => unknown) => () =>
  swrFetcher(cms.apiUrl)("/api/user/logout", { method: "POST" }, false)
    .then(r => r.ok
      ? revalidate()
      : Promise.reject(new Error("invalid-logout"))
    );

const StripBar: FC<{ color: string }> = ({ children, color }) => {
  return (
    <Flex
      display={{ _: "none", sm: "flex" }}
      alignItems="center"
      justifyContent="space-between"
      px="12px"
      bg={color}
      width="100%"
      height="32px"
      zIndex={1004}
    >
      {children}
    </Flex>
  );
};
