import { Box, BoxProps, AspectRatio, Img } from "@xcorejs/ui";
import { FC } from "react";

import styled from "styled-components";

export type FocusProps =
  {
    imageSrc?: string;
    srcSet?: string;
  }
  & BoxProps;

const FocusStyle = styled(Box)<FocusProps>`
  position: relative;

  @media screen and (min-height: 600px) {
    position: sticky;
    top: 12rem;
  }
`;

const Focus: FC<FocusProps> = (
  {
    imageSrc,
    srcSet,
    children,
    ...props
  }
) => {
  return (
    <FocusStyle width="100%" {...props}>
      <AspectRatio ratio={1}>
        <Img
          src={imageSrc}
          srcSet={srcSet}
          alt="Focus image"
          width="100%"
          maxWidth="80%"
          maxHeight="80%"
          loading="lazy"
        />
      </AspectRatio>
    </FocusStyle>
  );
};

Focus.displayName = "Focus";

export default Focus;
