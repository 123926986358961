import { XcoreCMS } from "@appiodev/xcore-core";
import { makeQuery } from "@appiodev/xcore-utils";
import {
  CreateInternalLinkInput,
  CreateLinkInput,
  EditMediaInput,
  GetMediaByPathOptions,
  GetMediaResponse,
  UploadSupportFilesOptions
} from "./options";
import { SFCategory, SFMedia } from "./types";

export class RobeSupportFilesMedia {
  private cms: XcoreCMS;

  constructor (cms: XcoreCMS) {
    this.cms = cms;
  }

  public getMediaCategories () {
    return this.cms.fetch("/api/media/categories")
      .then<SFCategory[]>(r => r.json());
  }

  public getMediaDetail (id: number) {
    return this.cms.fetch(`/api/media/detail/${id}`).then<SFMedia>(r => r.json());
  }

  public getMediaByPath (path: string, options?: GetMediaByPathOptions) {
    return this.cms.fetch(
      `/api/media/${encodePath(path)}?${makeQuery(`sort[0][${options?.sort?.[0]}]`, "term")(options?.sort?.[1], options?.term)}`
    ).then<GetMediaResponse>(async r => r.ok ? r.json() : Promise.reject(await r.json()));
  }

  public getMediaCategory (id: number) {
    return this.cms.fetch(`/api/media/categories/${id}`).then(r => r.json());
  }

  public editMedia (ids: number[], input: EditMediaInput) {
    return this.cms.fetch(`/api/media?${makeQuery("ids")(ids)}`, {
      method: "PUT",
      body: JSON.stringify(input)
    });
  }

  public renameMedia (filePath: string, name: string, link: boolean) {
    return this.cms.fetch("/api/media/file", {
      method: "PUT",
      body: JSON.stringify({ name, link, filePath })
    });
  }

  public createFolder (parentPath: string, directoryName: string) {
    return this.cms.fetch("/api/media/directory", {
      method: "POST",
      body: JSON.stringify({ directoryName, parentPath })
    });
  }

  public renameFolder (directoryPath: string, directoryName: string) {
    return this.cms.fetch("/api/media/directory", {
      method: "PUT",
      body: JSON.stringify({ directoryName, directoryPath })
    });
  }

  public deleteFolder (directoryPath: string) {
    return this.cms.fetch("/api/media/directory", {
      method: "DELETE",
      body: JSON.stringify({ directoryPath })
    });
  }

  public deleteFolders (ids: number[]) {
    return this.cms.fetch(`/api/media?${makeQuery("ids")(ids)}`, {
      method: "DELETE"
    });
  }

  public createInternalLink (input: CreateInternalLinkInput) {
    return this.cms.fetch("/api/media/link?external=false", {
      method: "POST",
      body: JSON.stringify(input)
    });
  }

  public createLink (input: CreateLinkInput) {
    return this.cms.fetch("/api/media/link?external=true", {
      method: "POST",
      body: JSON.stringify(input)
    });
  }

  public deleteMediaCategory (id: number) {
    return this.cms.fetch(`/api/media/categories/${id}`, { method: "DELETE" });
  }

  public createMediaCategory (name: string, parentId: number) {
    return this.cms.fetch("/api/media/categories", {
      method: "POST",
      body: JSON.stringify(!parentId ? { name } : { name, parentId })
    });
  }

  public editMediaCategory (id: number, values: Record<string, string>) {
    return this.cms.fetch(`/api/media/categories/${id}`, {
      method: "PUT",
      body: JSON.stringify({ name: values.en, localeName: values })
    });
  }

  public getFeaturedFiles () {
    return this.cms.fetch("/api/media/featured").then<Record<string, SFMedia[]>>(r => r.json());
  }

  public setFeaturedFiles (values: Record<string, number[] | null>) {
    return this.cms.fetch("/api/media/featured", {
      method: "PUT",
      body: JSON.stringify(values)
    });
  }

  public featuredFilesCompletion (term: string) {
    return this.cms.fetch(`/api/media/completion?${makeQuery("term")(term)}`)
      .then<SFMedia[]>(r => r.json());
  }

  public uploadSupportFiles (files: File[] | FileList, parentPath: string, { query, setProgress }: UploadSupportFilesOptions) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      Array.from(files).forEach(f => formData.append("files", f));
      formData.append("parentPath", parentPath);

      const ajax = new XMLHttpRequest();
      ajax.onreadystatechange = function () {
        if (this.readyState === 4) {
          switch (this.status) {
            case 200:
              resolve(undefined);
              break;
            case 400:
              reject(Object.values(JSON.parse(this.response).errors).map((i: any) => i[0]));
              break;
            default:
              reject(new Error("Unexpected error"));
          }
          this.status === 200 ? resolve(undefined) : reject(JSON.parse(this.response));
          setProgress?.(100);
        }
      };
      const totalSize = Array.from(files).reduce((acc, val) => acc + val.size, 0);
      ajax.upload.addEventListener("progress", (u) => setProgress?.(Math.round(u.loaded / totalSize * 100)));
      ajax.open("POST", concatUrls(this.cms.apiUrl, "/api/media/files/", query || ""));
      ajax.setRequestHeader("accept", "*/*");
      ajax.withCredentials = true;
      ajax.send(formData);
    });
  }
}

export const concatUrls = (...urls: string[]) =>
  urls.slice(1).reduce((a, b) =>
    a.endsWith("/") && b.startsWith("/")
      ? `${a}${b.slice(1)}`
      : `${a}${a.endsWith("/") || b.startsWith("/") ? "" : "/"}${b}`,
  urls[0]);

export const encodePath = (path: string) => path.split("/").map(encodeURIComponent).join("/");
