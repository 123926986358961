import { FC } from "react";
import { Link } from "@appiodev/xcore-client/xcore-ui";
import { NavItemProps } from ".";
import { ExtendedLinkProps, Link as UILink } from "@xcorejs/ui";
import { Content } from "@appiodev/xcore-core";

interface NavItemLinkProps extends Omit<NavItemProps, "content" | "variant"> {
  variant: "dropdown" | "accordion";
  content?: Content | string;
}

const NavItemLink: FC<NavItemLinkProps> = ({ content, variant, ...props }) => {
  const DropdownStyles: ExtendedLinkProps = {
    type: "simple",
    fontWeight: 600,
    minHeight: { md: "5rem" },
    py: "1.4rem",
    lineHeight: "2.2rem",
    width: { md: "100%" },
    display: "block",
    textAlign: "left",
    // justifyContent: { md: "flex-start" },
    _group: { _hover: { color: "text" } }
  };

  const AccordionStyles: ExtendedLinkProps = {
    type: "simple",
    position: "relative",
    width: "100%"
  };

  const p = {
    ...NavLinkStyle,
    ...variant === "dropdown" ? DropdownStyles : AccordionStyles,
    ...props
  };

  return typeof content === "string"
    ? <UILink {...p} href={content} target="_blank" />
    : !content
      ? <UILink as="span" {...p} style={{ cursor: "default" }} />
      : <Link content={content} {...p} prefetch={false} />
  ;
};

export default NavItemLink;

export const NavLinkStyle = {
  justifyContent: "center",
  color: "#939598",
  fontSize: { _: "1.5rem", md: "1.3rem", lg: "1.5rem" },
  fontWeight: 700,
  lineHeight: "2.2rem",
  minHeight: "2.2rem",
  display: "flex",
  alignItems: "center",
  textDecoration: "none",

  _hover: {
    color: "black"
  }
};
