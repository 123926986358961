import { Box, Flex, FlexProps, Text, Icon, useTheme } from "@xcorejs/ui";
import { XcoreTheme } from "design-system/xcore/theme";
import { FC, ReactNode, useContext } from "react";

import styled, { ThemeContext } from "styled-components";
import * as system from "styled-system";

import CheckIcon from "design-system/xcore/icons/check.svg";
import ControlBox, { ControlBoxProps } from "./ControlBox";

export type CheckboxProps = {
  size?: "sm" | "md" | "lg";
  controlIcon?: ReactNode;
  controlIconColor?: string;
  _control?: ControlBoxProps;
  type?: "checkbox" | "radio";
  icon?: ReactNode;
  disabled?: boolean;
  name?: string;
  checked?: boolean;
} & FlexProps
& system.OpacityProps;

export const CheckboxStyle = styled(Flex)`
  ${system.opacity}
`;

const VisuallyHidden = styled(Flex)<CheckboxProps>`
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0px;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
 `;

const Checkbox: FC<CheckboxProps> = (
  {
    controlIcon,
    controlIconColor,
    size = "md",
    type = "checkbox",
    disabled,
    name,
    _control,
    children,
    checked,
    onChange,
    ...props
  }
) => {
  const theme = useTheme() as XcoreTheme;
  const {
    sizes: controlSizeProps,
    controlIconColor: controlIconColorProps,
    ...controlProps
  } = theme.checkbox.control;
  const { sizes: childrenSizeProps, ...childrenProps } = theme.checkbox.children as any;
  const radioProps = theme.checkbox.radio;

  return (
    <CheckboxStyle
      as="label"
      display="inline-flex"
      verticalAlign="top"
      alignItems="center"
      cursor="pointer"
      {...disabled && { opacity: 0.5 }}
      {...props}
    >
      <VisuallyHidden as="input" type={type} disabled={disabled} name={name} checked={checked} onChange={onChange} />

      {type === "checkbox" ? (
        <ControlBox {...controlProps} {...controlSizeProps[size]} {..._control}>
          <Icon
            opacity={0}
            transition="opacity 300ms"
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            svg={controlIcon || <CheckIcon />}
            fill={controlIconColorProps || (controlIconColor || "#fff")}
          />
        </ControlBox>
      ) : (
        <ControlBox {...controlProps} {...controlSizeProps[size]} borderRadius="50%" bg="#fff" {..._control}>
          <Box
            opacity={0}
            transition="opacity 300ms"
            border="2px solid #fff"
            width="100%"
            height="100%"
            borderRadius="50%"
            {...radioProps}
          />
        </ControlBox>
      )}

      {children &&
        <Text userSelect="none" {...childrenProps} {...childrenSizeProps[size]}>{children}</Text>}
    </CheckboxStyle>
  );
};

export default Checkbox;
