import { makeQuery, Relation } from "@appiodev/xcore-core";
import { Box, Flex } from "@xcorejs/ui";
import ApplicationPicker from "components/ApplicationPicker";
import CategoryDisplay from "components/Layout/CategoryDisplay";
import Navigation, { NavigationProps } from "design-system/appio/navigation";
import { FC, memo, useCallback, useState } from "react";
import useSWR from "swr";
import useDelayedAction from "utils/useDelayedAction";
import { resolveLocaleField, useLocalizedRelation } from "utils/useLocalizedRelation";
import { useLayout } from "xcore";
import { Accessory, Product, ProductOverviewPage } from "xcore/types";

import { useRouter } from "next/router";
import { useUser } from "utils/useUser";
import SubheaderItem, { DefaultSubheaderActiveNavLinkStyle } from "./SubheaderItem";
import useSubHeaderStore from "./useSubHeaderStore";

const Subheader: FC<NavigationProps> = ({ leftContent, middleContent, rightContent, ...props }) => {
  const { user } = useUser();
  const { isPreview } = useRouter();
  const isLogged = !!user;
  const { showSubheader } = useSubHeaderStore();

  if (!showSubheader) {
    return null;
  }

  return (
    <Navigation
      leftContent={leftContent}
      middleContent={middleContent}
      rightContent={rightContent}
      height={{ _: "4rem", md: "5rem" }}
      bg="blackRGB.o9"
      _container={{ justifyContent: "space-between", alignItems: "center" }}
      _grid={{ columns: "1fr auto 1fr", rows: "auto" }}
      position="sticky"
      zIndex={1002}
      top={`${(+isLogged + +isPreview) * 3.2 + 9}rem`}
      display={{ _: "none", md: "flex" }}
      {...props}
    />
  );
};

export default Subheader;

export const DefaultLeftContent: FC = () => (
  <Flex alignItems="center">
    <ApplicationPicker />
  </Flex>
);

export const DefaultMiddleContent: FC = memo(() => {
  const { header: { nav }, stringify, cms } = useLayout();
  const [activeItem, setActiveItem] = useState<number>();

  const c = useCallback(() => setActiveItem(undefined), []);
  const [close, prevent] = useDelayedAction(c, 300);
  const [longClose, longPrevent] = useDelayedAction(c, 2000);

  const items = useLocalizedRelation(
    "itemsRelation",
    nav.products.values.middlePageProductFamily!.values,
    undefined as Relation<ProductOverviewPage | Product | Accessory>[] | undefined
  ) ?? [];

  const subHeaderItemsIds = items.map(item => item.id);

  const { data } = useSWR(
      `/api/robe/subheader?${cms.locales.content.map((l) => `locale=${l}`).join("&")}&${makeQuery(
        ...subHeaderItemsIds.map(() => "ids")
      )(...subHeaderItemsIds)}`
  );

  return (
    <Box onMouseLeave={() => close()} onMouseEnter={prevent}>
      <Flex alignItems="center" justifyContent="center" role="group">
        {items?.map(r => (
          <SubheaderItem
            key={r.id}
            content={r}
            set={() => setActiveItem(r.id)}
            {...activeItem && r.id === activeItem && { ...DefaultSubheaderActiveNavLinkStyle }}
          >
            {"thumbnailTitle" in r.values && stringify(r.values.thumbnailTitle) ? stringify(r.values.thumbnailTitle) : stringify(r.title)}
          </SubheaderItem>
        ))}
      </Flex>
      {items?.map(p => (
        <CategoryDisplay
          key={p.id}
          active={activeItem === p.id}
          pop={activeItem === p.id ? data?.find(item => item.id === activeItem) : undefined}
          empty={!resolveLocaleField("itemsRelation", p.values as any, [], cms.locales.content)?.length}
          prevent={longPrevent}
          close={longClose}
        />
      ))}
    </Box>
  );
});

export const DefaultRightContent = () => <></>;

export const SubheaderNavLinkStyle = {
  display: "flex",
  alignItems: "center",
  fontSize: { _: "1.3rem", lg: "1.4rem" },
  padding: { _: "0 .5rem", lg: "0 1rem" },
  height: "5rem",
  fontWeight: 600,
  lineHeight: "2.1rem",
  color: "lightGray",
  transition: "color 300ms, background 300ms",
  textAlign: "center" as const,

  _hover: {
    color: "#fff"
  }
};
