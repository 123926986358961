
import { FC } from "react";
import { Text, Typography } from "@xcorejs/ui";

const Copyright: FC = ({ children }) => (
  <Typography variant="p">
    &copy; {(new Date().getFullYear())}{" "}
    <Text color="white" fontWeight={600} display="inline-block" as="span">{children}</Text>
  </Typography>
);

export default Copyright;
