import { useTheme } from "@xcorejs/ui";
import { InputProps, InputStyle } from "design-system/xcore/Form/Input";
import { FC } from "react";
import styled, { CSSProperties } from "styled-components";
import * as system from "styled-system";

import { XcoreTheme } from "../../theme";

export type TextareaProps = {
  placeholder?: string;
  resize?: system.ResponsiveValue<CSSProperties["resize"]>;
} & InputProps;

const TextareaStyle = styled(InputStyle)<TextareaProps>`
  resize: ${props => props.resize};
`;

TextareaStyle.defaultProps = {
  resize: "none",
  minHeight: "12rem"
};

const Textarea: FC<TextareaProps> = (
  {
    placeholder,
    children,
    ...props
  }
) => {
  const theme = useTheme() as XcoreTheme;
  const textareaStyle = theme.input.default;
  const outlineVariantStyle = theme.input.variant.outline;

  return (
    <TextareaStyle as="textarea" placeholder={placeholder} {...textareaStyle} {...outlineVariantStyle} {...props}>
      {children}
    </TextareaStyle>
  );
};

export default Textarea;
