import { modal } from "@xcorejs/ui";

export const modalTheme = modal({
  default: {
    _overlay: {
      background: "rgba(15, 31, 40, 0.5)",
      zIndex: 5
    },
    _header: {
      marginBottom: "2rem",
      fontSize: "2.4rem",
      lineHeight: "3.6rem",
      fontFamily: "avenir",
      fontWeight: 600,
      textAlign: "center",
      flex: "0 0 auto"
    },
    _close: {
      position: "absolute",
      top: "2rem",
      right: "2rem"
    },
    bg: "#fff",
    padding: { _: "4rem 2rem", sm: "4rem 11rem" },
    overflow: "auto",
    width: "100%",
    borderRadius: 0,
    maxHeight: "90vh"
  },
  sizes: {
    sm: {
      maxWidth: "41rem"
    },
    md: {
      maxWidth: "63rem"
    },
    lg: {
      maxWidth: "85rem"
    },
    full: {
      maxWidth: "none"
    }
  }
});
