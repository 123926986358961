import { createContext, Key, useState, useRef } from "react";
import { AccordionProps } from "./index";

export const AccordionContext = createContext({
  expanded: [] as Key | Key[],
  onSelect: (_: Key) => ({} as unknown),
  refs: { current: null as unknown as Key[] }
});

export const AccordionItemContext = createContext({
  index: null! as Key,
  expanded: false
});

export const isExpanded = (ex: boolean, expanded: Key | Key[] | [] | null, index: Key) =>
  ex || expanded instanceof Array ? (expanded as Key[]).includes(index) : expanded === index;

export type Accordion = {
  onSelect?: any;
} & (
  | {
    multiple: true;
    toggle?: undefined;
    expanded?: Key[];
  }
  | {
    multiple?: false;
    toggle?: boolean;
    expanded?: Key;
  }
);

export interface AccordionHook {
  accordion: AccordionProps;
  collapseAll: () => unknown;
  expandAll: () => unknown;
  open: (key: Key) => unknown;
  close: (key: Key) => unknown;
  toggle: (key: Key) => unknown;
}

export const useAccordion = (props: Accordion): AccordionHook => {
  const { multiple } = props;
  const [expanded, setExpanded] = useState(props.expanded || (multiple ? [] : null));
  const refs = useRef<Key[]>([]);

  const open = (key: Key) =>
    multiple
      ? setExpanded((expanded as Key[]).includes(key) ? expanded : [...(expanded as Key[]), key])
      : setExpanded(key);
  const close = (key: Key) =>
    multiple
      ? setExpanded(
        (expanded as Key[]).includes(key) ? (expanded as Key[]).filter(k => k !== key) : expanded
      )
      : setExpanded(key);
  const toggle = (key: Key) => isExpanded(false, expanded, key) ? close(key) : open(key);

  return {
    accordion: {
      ...props,
      expanded: expanded as any,
      controlled: true,
      refs,
      onSelect: (key: Key) => toggle || multiple ? toggle(key) : open(key)
    },
    collapseAll: () => setExpanded(multiple ? [] : null),
    expandAll: () => multiple && setExpanded(refs.current),
    open,
    close,
    toggle
  };
};
