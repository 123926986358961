import { usePageContent } from "@appiodev/xcore-client/xcore-ui";
import { FC } from "react";
import { Product, ShortVideo, ShortVideoValues } from "xcore/types";

import ProductAccessories from "./ProductAccessories";
import ProductColors from "./ProductColors";
import ProductDownloads from "./ProductDownloads";
import ProductFooter from "./ProductFooter";
import ProductIntro from "./ProductIntro";
import ProductNews from "./ProductNews";
import ProductPictures from "./ProductPictures";
import ProductSpecification from "./ProductSpecification";
import ProductVideo from "./ProductVideo";
import ProductKeyFeatures from "./ProductKeyFeatures";
import { Relation } from "@appiodev/xcore-core";

type ProductDetailProps = {
  keyFeatures?: Relation<ShortVideo<keyof ShortVideoValues>>[] | null;
};

const ProductDetail: FC<ProductDetailProps> = ({ keyFeatures }) => {
  const [product] = usePageContent<Product>();

  return (
    <>
      <ProductIntro />
      {keyFeatures && <ProductKeyFeatures keyFeatures={keyFeatures} />}
      {product.values.images && <ProductPictures />}
      {product.values.videos && <ProductVideo />}
      <ProductDownloads />
      {product.values.wheels && <ProductColors />}
      {product.values.accessories && <ProductAccessories />}
      {product.values.specification && <ProductSpecification />}
      {product.fetchedReverseRelations!.relatedNews.length > 0 && <ProductNews />}
      <ProductFooter />
    </>
  );
};

export default ProductDetail;
