import { typography } from "@xcorejs/ui";

export const typographyTheme = typography({
  default: {
    fontFamily: "avenir",
    m: 0
  },
  variants: {
    p: {
      fontSize: "1.5rem",
      lineHeight: "2.5rem",
      minHeight: "2.5rem",
      color: "darkGray"
    },
    h1: {
      fontSize: { _: "2.4rem", sm: "4.2rem" },
      fontWeight: 700,
      lineHeight: { _: "3.3rem", sm: "5.7rem" },
      color: "text"
    },
    h2: {
      fontSize: { _: "2.4rem", sm: "4.2rem" },
      fontWeight: 700,
      lineHeight: "5.7rem",
      color: "text"
    },
    h3: {
      fontSize: { _: "1.8rem", sm: "2.4rem" },
      fontWeight: 600,
      lineHeight: { _: "2.7rem", sm: "3.6rem" },
      color: "text"
    },
    lead: {
      color: "darkGray",
      fontSize: { _: "1.8rem", sm: "2.4rem" },
      lineHeight: { _: "2.7rem", sm: "3.6rem" }
    }
  }
});
