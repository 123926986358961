import { Box, BoxProps } from "@xcorejs/ui";
import { FC } from "react";

const RobeLoader: FC<BoxProps> = ({ ...props }) => {
  return (
    <Box as="object" type="image/svg+xml" data="/static/loader.svg" width="192px" {...props as any} />
  );
};

export default RobeLoader;
