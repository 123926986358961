import { InlineValue, Link, useContent, usePageContent } from "@appiodev/xcore-client/xcore-ui";
import { Box, Collapse, Container, SimpleGrid, Stack } from "@xcorejs/ui";
import ProductCard from "design-system/robe/cards/Product";
import RobeProductDetailHeading from "design-system/robe/RobeProductDetailHeading";
import { FC } from "react";
import { Product, ProductDetailPage } from "xcore/types";
import { useSection } from "./data";

const ProductAccessories: FC = () => {
  const [product, { stringify, value }] = usePageContent<Product>();
  const [detailPage] = useContent<[ProductDetailPage]>();
  const anchor = "#" + stringify(detailPage.values.anchorAccessories);

  const [active, toggle] = useSection(anchor);

  return (
    <Box id={anchor}>
      <Box id={stringify(detailPage.values.anchorAccessories)} transform={{ _: "translateY(-6rem)", md: "translateY(-13.9rem)" }} />
      <Container
        flexDirection="column"
        py={{ _: "1.5rem", sm: "5rem" }}
      >
        <Stack direction="column" gap={{ _: "3rem", sm: "4rem" }}>
          <RobeProductDetailHeading onClick={toggle} active={active}>
            <InlineValue value={detailPage.values.accessories} />
          </RobeProductDetailHeading>
          <Collapse open={active}>
            <SimpleGrid
              gap={{ _: "1.5rem", sm: "3rem" }}
              columns={{ _: 2, sm: 3 }}
              column="minmax(0, 300px)"
              width="96rem"
              maxWidth="100%"
              marginX="auto"
              justifyContent="center"
              mb={{ _: "3rem", sm: 0 }}
            >
              {product.values.accessories.map(a => (
                <Link key={value(a.id)} query={`?backto=${product.id}`} content={value(a)} variant="simple" overflow="hidden">
                  <ProductCard product={value(a)} />
                </Link>
              ))}
            </SimpleGrid>
          </Collapse>
        </Stack>
      </Container>
    </Box>
  );
};

export default ProductAccessories;
