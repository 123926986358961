export const AppCookies = {
  userData: "user-data",
  cookieConsent: "cookie-consent"
};

// In days
export const USER_DATA_COOKIE_EXPIRATION = 365;

export interface UserDataCookie {
  company: string;
  name: string;
  email: string;
  phone: string;
}
