import { Flex, FlexProps, Icon, IconProps, useTheme } from "@xcorejs/ui";
import { FC } from "react";
import styled from "styled-components";

import CloseIcon from "components/icons/8/close.svg";
import { XcoreTheme } from "./theme";

type SizesProps = { size?: "xs" | "sm" | "md" | "lg" };

export type CloseButtonProps = {
  _icon?: IconProps;
} & SizesProps
& FlexProps;

const CloseButtonStyle = styled(Flex)`
  svg {
    width: 100%;
    height: 100%;
  }
`;

CloseButtonStyle.defaultProps = {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center"
};

const CloseButton: FC<CloseButtonProps> = (
  {
    _icon,
    size = "md",
    ...props
  }
) => {
  const theme = useTheme() as XcoreTheme;
  const buttonProps = theme.closeButton.default;
  const sizeProps = theme.closeButton.sizes[size];

  return (
    <CloseButtonStyle {...buttonProps} {...sizeProps} {...props}>
      <Icon svg={<CloseIcon />} {...buttonProps._icon} {...sizeProps._icon} {..._icon} />
    </CloseButtonStyle>
  );
};

export default CloseButton;
