import { AspectRatio, AspectRatioProps, Flex, Icon } from "@xcorejs/ui";
import PlayIcon from "design-system/appio/icons/play.svg";
import IconBox from "design-system/xcore/IconBox";
import { AnchorHTMLAttributes, FC } from "react";
import styled from "styled-components";

export type VideoPlaceholderProps = AspectRatioProps & AnchorHTMLAttributes<unknown>;

const VideoPlaceholder: FC<VideoPlaceholderProps> = ({ ratio, children, ...props }) => (
  <VideoPlaceholderStyle ratio={ratio} role="group" {...props}>
    {children}
    <Flex
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      justifyContent="center"
      alignItems="center"
      boxShadow="0 15px 30px 0 rgb(30,30,30)"
    >
      <IconBox
        node={<Icon svg={<PlayIcon />} transition="transform 300ms" _group={{ _hover: { transform: "scale(1.33)" } }} />}
        width="6rem"
        height="6rem"
        position="relative"
        borderRadius={0}

      />
    </Flex>
  </VideoPlaceholderStyle>
);

export default VideoPlaceholder;

const VideoPlaceholderStyle = styled(AspectRatio)`
  position: relative;
  cursor: pointer;
`;
