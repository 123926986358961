import { Content } from "@appiodev/xcore-core";
import { Link } from "@appiodev/xcore-client/xcore-ui";
import { ExtendedLinkProps, Flex } from "@xcorejs/ui";
import { ComponentProps, Dispatch, FC, MutableRefObject, ReactNode } from "react";

import NavItemAccordion from "./NavItemAccordion";
import NavItemDropdown from "./NavItemDropdown";

export type NavItemProps =
  & Omit<ComponentProps<typeof Link>, "content">
  & ExtendedLinkProps
  & {
    content?: Content | string;
    children: ReactNode;
  };

interface Props {
  activeOn?: string[];
  dropdown?: [NavItemProps, NavItemProps[] | undefined][];
  overviewLink?: NavItemProps;
  withLabels?: boolean;
  open?: boolean;
  setOpen?: Dispatch<boolean>;
  view?: "dropdown" | "accordion";
  productDropdown?: boolean;
  delayedState?: MutableRefObject<NodeJS.Timeout>;
  isAnythingOpen?: boolean;
}

const NavItem: FC<Props & NavItemProps> = ({ dropdown = [], withLabels, view, ...props }) => {
  return (
    <Flex justifyContent="center" mb={{ _: "2rem", md: "0" }}>
      {view === "dropdown" && <NavItemDropdown dropdown={dropdown} withLabels={withLabels} {...props} />}
      {view === "accordion" && <NavItemAccordion dropdown={dropdown} {...props} />}
    </Flex>
  );
};

export default NavItem;

export const SubNavLinkStyle = {
  fontFamily: "avenir",
  color: "#5e6062",
  fontSize: "1.4rem",
  fontWeight: 600,
  lineHeight: "2.1rem",
  minHeight: "2.1rem",
  py: "1rem",
  display: "flex",
  alignItems: "center",
  textDecoration: "none",

  _hover: {
    color: "black"
  }
};

export const ActiveNavLinkStyle = {
  color: "robe"
};
