
import { components } from "react-select";
import { Typography } from "@xcorejs/ui";
import styled from "styled-components";

const GroupHeadingStyle = styled.div`
  & > div {
    display: block;
    font-size: 100%;
    font-weight: 500;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    text-transform: none;
    box-sizing: border-box;
  }
`;

export const GroupHeading = props => {
  const { children } = props;

  return (
    <GroupHeadingStyle>
      <components.GroupHeading {...props}>
        <Typography fontWeight={700} color="black">
          {children}
        </Typography>
      </components.GroupHeading>
    </GroupHeadingStyle>
  );
};
