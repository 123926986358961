
import { FC, ReactNode } from "react";
import styled from "styled-components";
import { BoxProps, FlexProps, Flex, Text, TextProps, Icon } from "@xcorejs/ui";

export type LabelProps = {
  text?: string;
  required?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  _leftIcon?: BoxProps;
  _rightIcon?: BoxProps;
  _text?: TextProps;
} & FlexProps;

const LabelStyle = styled(Flex)``;

LabelStyle.defaultProps = {
  flexDirection: "column"
};

const Label: FC<LabelProps> = (
  {
    leftIcon,
    rightIcon,
    _leftIcon,
    _rightIcon,
    required = false,
    children,
    text,
    _text,
    ...props
  }
) => {
  return (
    <LabelStyle as="label" {...props}>
      {leftIcon &&
        <Icon mr="1rem" svg={leftIcon} {..._leftIcon} />}
      {text && (
        <Text {..._text}>
          <Text variant="strong" {..._text}>{text}</Text>
          {required && <Text variant="strong" ml="1px" {..._text} color="#E13030">*</Text>}
        </Text>
      )}
      {children && children}
      {rightIcon &&
        <Icon ml="1rem" svg={rightIcon} {..._rightIcon} />}
    </LabelStyle>
  );
};

export default Label;
