import { ContentLinkProps, Link } from "@appiodev/xcore-client/xcore-ui";
import { Box, ExtendedLinkProps, Flex, Grid, Icon, Typography } from "@xcorejs/ui";
import ISeriesSvg from "components/icons/iseries-drop.svg";
import React, { Dispatch, FC } from "react";
import IconsLegend from "./IconsLegend";
import RightSection from "./RightSection";
import type { GetProductGridResult } from "pages/api/robe/product-grid";
import { useLayout } from "xcore";
import RobeLoader from "components/RobeLoader";
import ExternalSVG from "components/ExternalSVG";

const ProductDropdown: FC<{ data: GetProductGridResult; setOpen: Dispatch<boolean> }> = ({ data, setOpen }) => {
  const { stringify, file } = useLayout();

  const gridSection1 = data?.menuGridSection1;
  const gridSection2 = data?.menuGridSection2;

  return (
    <Box
      background="#fff"
      overflowY="auto"
      maxHeight="calc(100vh - 12rem)"
      width="100%"
      maxWidth="1300px"
      boxShadow="0 0.5rem 1rem 0 rgba(30, 30, 30, 0.5)"
    >
      {data ? (
        <Flex>
          <Grid
            padding="10px 30px"
            rows={`repeat(${data.productTypes.length + 1}, auto)`}
            columns={`repeat(${data.productSizes.length + 1}, auto)`}
            minWidth="80%"
          >
            {data.productTypes.map((pt, i) => (
              <Box
                key={pt.id}
                padding="10px 0"
                {...i !== data.productTypes.length - 1 && { borderBottom: "1px solid lightGray" }}
                gridColumn={0}
                gridRow={i + 2}
              >
                {pt.content && (
                  <CustomLink content={pt.content} setOpen={setOpen}>
                    <Typography color="black" fontWeight={600} pr="2rem" {...TextStyle} textTransform="uppercase">
                      {stringify(pt.name)}
                    </Typography>
                  </CustomLink>
                )}
              </Box>
            ))}

            {data.productSizes.map((ps, i) =>
              ps.content && (
                <CustomLink content={ps.content} key={ps.id} gridColumn={i + 2} setOpen={setOpen}>
                  <Typography mb="0.5rem" color="black" fontWeight={600}>
                    {stringify(ps.name)}
                  </Typography>
                </CustomLink>
              )
            )}

            {data.productTypes.map((pt, y) =>
              data.productSizes.map((ps, x) => (
                <Box
                  key={`${x};${y}`}
                  padding="10px 0"
                  {...y !== data.productTypes.length - 1 && { borderBottom: "1px solid lightGray" }}
                >
                  {data.grid[pt.id]?.[ps.id]?.map(p => (
                    <CustomLink key={p.id} content={p} display="block" width="fit-content" px="2rem" py="0.25rem" setOpen={setOpen}>
                      <Flex>
                        <Typography fontWeight={600} position="relative" _hover={{ color: "black" }} {...TextStyle}>
                          {"productMenuIcon" in p.values && p.values.productMenuIcon && data.iconLegends && (
                            <ExternalSVG
                              position="absolute"
                              left="-30px"
                              fill="lightgrey"
                              width="24px"
                              height="24px"
                              src={file(data.iconLegends.find(i => i.type === p.values.productMenuIcon)?.image ??
                                data.iconLegends[0].image, { height: 24, width: 24 })}
                            />
                          )}
                          {stringify(p.title)}
                        </Typography>
                        {"ipCertifiedIcon" in p.values && p.values.ipCertifiedIcon &&
                        <Icon style={{ width: "24px", height: "24px" }} svg={ISeriesSvg} marginLeft="5px" />}
                        {"newIcon" in p.values && p.values.newIcon && (
                          <Box padding="0 0.5rem" {...TagStyle}>
                            {stringify(data.newIconText)}
                          </Box>
                        )}
                      </Flex>
                    </CustomLink>
                  ))}
                </Box>
              ))
            )}
          </Grid>

          {gridSection1 && gridSection2 && (
            <RightSection
              otherTypes={data.rest}
              gridSection1={gridSection1}
              gridSection2={gridSection2}
              setOpen={setOpen}
              newIconText={stringify(data.newIconText) ?? ""}
            />
          )}
        </Flex>
      ) : (
        <Flex justifyContent="center">
          <RobeLoader />
        </Flex>
      )}
      {data && <IconsLegend legends={data.iconLegends} title={stringify(data.title) ?? ""} />}
    </Box>
  );
};

export const CustomLink: FC<ContentLinkProps & ExtendedLinkProps & {setOpen: Dispatch<boolean>}> = (props) =>
  <Link {...props} onClick={() => props.setOpen(false)}>{props.children}</Link>;

export const TextStyle = {
  fontSize: { _: "1.2rem", lg: "1.4rem" },
  transition: "color 0.2s"
};

export const TagStyle = {
  backgroundColor: "primary",
  color: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "5px",
  fontSize: "1.1rem",
  fontWeight: 600,
  height: "20px",
  minWidth: "40px"
};

export default ProductDropdown;
