
import styled, { ThemeContext } from "styled-components";
import { components } from "react-select";
import * as system from "styled-system";
import { useContext } from "react";
import { XcoreTheme } from "design-system/xcore/theme";
import { Box, BoxProps, Text, TextProps, useTheme } from "@xcorejs/ui";

export type PlaceholderSelectProps =
  & BoxProps
  & TextProps;

const PlaceholderStyle = styled(Box)<PlaceholderSelectProps>`
  ${system.typography};
  
  & > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

export const Placeholder = (props: any) => {
  const theme = useTheme() as XcoreTheme;
  const placeholderProps = theme.select.placeholder;

  return (
    <PlaceholderStyle {...placeholderProps as any}>
      <components.Placeholder {...props} />
    </PlaceholderStyle>
  );
};
