import { card } from "@xcorejs/ui";

export const cardTheme = card({
  default: {
    color: "black",
    background: "#fff",
    borderRadius: 0,
    innerPadding: "2rem",
    _title: {
      fontWeight: 600
    }
  },
  variants: {
    elevated: {
      borderRadius: "0",
      boxShadow: "0 0.3rem 0.8rem 0 rgba(36, 49, 70, 0.25)"
    },
    outline: {
      border: "2px solid #dee1e5;"
    }
  }
});
