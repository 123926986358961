
import { FC } from "react";
import RobeHeading from "design-system/robe/RobeHeading";
import DropIcon from "components/icons/16/drop.svg";
import styled from "styled-components";
import { FlexProps, Flex, Icon, LinkProps } from "@xcorejs/ui";

type FooterHeadingProps =
  {
    active?: boolean;
  }
  & LinkProps
  & FlexProps
;

const FooterHeadingStyle = styled(Flex)<FooterHeadingProps>``;

const FooterHeading: FC<FooterHeadingProps> = (
  {
    active,
    children,
    ...props
  }) => {
  const headingColor = active ? "#fff" : "rgba(255,255,255,.8)";
  const iconFill = active ? "robe" : "#7f8083";
  const iconRotate = active ? "rotate(180deg)" : "rotate(0deg)";

  return (
    <FooterHeadingStyle width="100%" justifyContent="center" position="relative" cursor="pointer" {...props}>
      <RobeHeading fontSize="2.4rem" lineHeight="3.3rem" color={headingColor} textAlign={{ _: "center", sm: "left" }}>
        {children}
      </RobeHeading>
      <Icon svg={<DropIcon />} fill={iconFill} position="absolute" right="5px" top="10px" transform={iconRotate} />
    </FooterHeadingStyle>
  );
};

export default FooterHeading;
