import { useRef, useState, Children } from "react";
import Carousel from "react-multi-carousel";
import { getOriginalIndexLookupTableByClones } from "react-multi-carousel/lib/utils/clones";

export const useCarousel = () => {
  const ref = useRef<Carousel>(null);
  const [index, setIndex] = useState(0);

  const go = (n: number) => {
    ref.current!.goToSlide(ref.current!.state.currentSlide + n);
    const table = getOriginalIndexLookupTableByClones(ref.current!.state.slidesToShow, Children.toArray(ref.current!.props.children));

    setIndex(table[ref.current!.state.currentSlide]);
  };

  const goToStart = () => {
    ref.current!.goToSlide(0);
    const table = getOriginalIndexLookupTableByClones(ref.current!.state.slidesToShow, Children.toArray(ref.current!.props.children));

    setIndex(table[0]);
  };

  const goToEnd = () => {
    const lastSlide = Children.toArray(ref.current!.props.children).length - 1;
    ref.current!.goToSlide(lastSlide);
    const table = getOriginalIndexLookupTableByClones(ref.current!.state.slidesToShow, Children.toArray(ref.current!.props.children));

    setIndex(table[lastSlide]);
  };

  return {
    ref,
    index,
    go,
    next: () => go(1),
    prev: () => go(-1),
    start: goToStart,
    end: goToEnd
  };
};
