import { button } from "@xcorejs/ui";
import { darken } from "polished";

export const buttonTheme = button({
  default: {
    fontWeight: 700,
    fontFamily: "avenir",
    fontSize: "1.5rem",
    lineHeight: "2.4rem",
    borderRadius: 0,
    textTransform: "uppercase",
    boxShadow: "0 3.5rem 2.5rem -2.5rem rgba(224,0,52,1)",
    _leftIcon: {
      mr: "1.5rem"
    },
    _rightIcon: {
      ml: "1.5rem"
    }
  },
  sizes: {
    xs: {
      px: "2rem",
      py: "0.7rem",
      fontSize: "1.2rem",
      borderRadius: "2.3rem"
    },
    sm: {
      px: "2rem",
      py: "1.2rem",
      fontSize: "1.4rem"
    },
    md: {
      px: "5rem",
      py: "1.7rem"
    },
    lg: {
      px: "5rem",
      py: "1.7rem",
      fontSize: "2rem",
      lineHeight: "3.4rem"
    }
  },
  variants: {
    solid: {
      bg: "#e00034",
      color: "white",
      _hover: {
        bg: darken(0.025, "#e00034"),
        boxShadow: "0 4.5rem 2.5rem -2.5rem rgba(224,0,52,1)"
      },
      _active: {
        bg: darken(0.05, "#e00034")
      },
      _focus: {
        bg: darken(0.05, "#e00034"),
        outline: "none",
        boxShadow: "0 4.5rem 2.5rem -2.5rem rgba(224,0,52,1), inset 0 0 0 .2rem rgba(15,31,40,0.2)"
      },
      _disabled: {
        opacity: 0.5
      }
    },
    clear: {
      color: "#e00034",
      boxShadow: "none",
      _hover: {
        bg: "rgba(224, 0, 52, 0.1)"
      },
      _active: {
        bg: "rgba(224, 0, 52, 0.2)"
      },
      _focus: {
        bg: "rgba(224, 0, 52, 0.2)",
        outline: "2px solid rgba(15, 31, 40, 0.2)",
        outlineOffset: "-2px"
      },
      _disabled: {
        opacity: 0.5
      }
    },
    outline: {
      border: "1px solid #e00034",
      color: "#0171b6",
      boxShadow: "none",
      _hover: {
        bg: "rgba(224, 0, 52, 0.1)"
      },
      _active: {
        bg: "rgba(224, 0, 52, 0.2)"
      },
      _focus: {
        bg: "rgba(224, 0, 52, 0.2)",
        outline: "2px solid rgba(15, 31, 40, 0.2)",
        outlineOffset: "-2px"
      },
      _disabled: {
        opacity: 0.5
      }
    },
    link: {
      padding: 0,
      color: "#e00034",
      borderRadius: 0,
      border: 0,
      borderBottom: "1px solid transparent",
      _hover: {
        borderBottom: "1px solid #e00034",
        color: darken(0.025, "#e00034")
      },
      _active: {
        color: "#036199"
      },
      _focus: {
        outline: "2px solid rgba(15, 31, 40, 0.2)",
        outlineOffset: "-2px"
      },
      _disabled: {
        opacity: 0.5
      }
    }
  }
});
