import css from "@styled-system/css";
import { BoxProps, Flex, FlexProps } from "@xcorejs/ui";

import { FC } from "react";
import styled from "styled-components";
import * as system from "styled-system";

const PseudoSelectors = {
  checkedAndDisabled: "input:checked:disabled + &, input[data-indeterminate=true]:disabled + &",
  checkedAndHover: "input:checked:hover:not(:disabled) + &, input[data-indeterminate=true]:hover:not(:disabled) + &",
  checkedAndFocus: "input:checked:focus + &, input[data-indeterminate=true]:focus + &",
  disabled: "input:disabled + &",
  focus: "input:focus + &",
  hover: "input:hover:not(:disabled):not(:checked) + &",
  checked: "input:checked + &, input[data-indeterminate=true] + &",
  checkedAndChild: "input:checked + & > *, input[data-indeterminate=true] + & > *",
  invalid: "input[aria-invalid=true] + &"
};

export type ControlBoxProps =
  {
    type?: "checkbox" | "radio";
    _hover?: BoxProps;
    _invalid?: BoxProps;
    _disabled?: BoxProps;
    _focus?: BoxProps;
    _checked?: BoxProps;
    _child?: BoxProps;
    _checkedAndChild?: BoxProps;
    _checkedAndDisabled?: BoxProps;
    _checkedAndFocus?: BoxProps;
    _checkedAndHover?: BoxProps;
  } & FlexProps
  & system.BorderRadiusProps;

const ControlBoxStyle = styled(Flex)<ControlBoxProps>`
  ${(
    {
      _checkedAndDisabled,
      _checkedAndHover,
      _checkedAndFocus,
      _checkedAndChild = { opacity: 1 },
      _disabled,
      _hover,
      _checked,
      _invalid,
      _focus
    }) => css({
    [PseudoSelectors.focus]: _focus,
    [PseudoSelectors.checkedAndDisabled]: _checkedAndDisabled,
    [PseudoSelectors.checkedAndFocus]: _checkedAndFocus,
    [PseudoSelectors.checkedAndHover]: _checkedAndHover,
    [PseudoSelectors.checkedAndChild]: _checkedAndChild,
    [PseudoSelectors.disabled]: _disabled,
    [PseudoSelectors.hover]: _hover,
    [PseudoSelectors.checked]: _checked,
    [PseudoSelectors.invalid]: _invalid
  })}
  ${system.borderRadius}
`;

ControlBoxStyle.defaultProps = {
  transition: "border 300ms, background 300ms, background 300ms",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer"
};

const ControlBox: FC<ControlBoxProps> = ({ children, ...props }) => {
  return (
    <ControlBoxStyle {...props}>
      {children}
    </ControlBoxStyle>
  );
};

export default ControlBox;
