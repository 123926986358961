import { getXcoreConfig } from "@appiodev/xcore-core";
import type { Redis as RedisType, ValueType } from "ioredis";

let redis: RedisType;

export const ensureRedis = async () => {
  if (!redis) {
    const { default: Redis } = await import("ioredis");
    redis = new Redis({ db: +process.env.ROBE_REDIS_DB! || 2, password: process.env.REDIS_PASSWD });
  }
};

export const createCache = <TResult extends any, TArgs extends any[]>(
  get: (...args: TArgs) => Promise<TResult>,
  getKey: (...args: TArgs) => string,
  {
    serialize = r => Buffer.from(JSON.stringify(r), "utf8"),
    deserialize = b => JSON.parse(b.toString("utf8")),
    staleAge = 1200000 // Max age for determining staleness in miliseconds. Defaults to 20 minutes
  }: {
    serialize?: (r: TResult) => Buffer | null;
    deserialize?: (s: Buffer) => TResult;
    staleAge?: number;
  } = {}
): (...args: TArgs) => Promise<TResult> => {
  if (typeof window === "undefined") {
    return async (...args: TArgs) => {
      const config = await getXcoreConfig();
      if (config.etc?.robe?.bypassCache) {
        return get(...args);
      }

      await ensureRedis();

      const key = getKey(...args);

      const [cached, stat] = await Promise.all([await redis.getBuffer(key), await redis.get(`_keys:${key}`)]);

      // If data missing in cache, fetch fresh data and store in cache with TTL
      if (!cached) {
        const fresh = await get(...args);
        await setValue(key)(serialize(fresh));
        return fresh;
      }

      const age = Math.abs(+stat! - Date.now());

      // If the cache is stale, get cached data and asynchronously refresh the value
      if (age > staleAge) {
        get(...args)
          .then(serialize)
          .then(setValue(key))
          .catch(() => null);
      }

      return deserialize(cached);
    };
  }
  return get;
};

const setValue = (key: string) => async (value: ValueType | null) => {
  (process.env.NODE_ENV !== "production" || process.env.VERBOSE === "1") && console.info(`\x1b[33mredis\x1b[0m - updating value for ${key}`);
  const ttl = Number(process.env.REDIS_TTL_SECONDS ?? 3600); // Time to live in seconds

  if (key && value) {
    // Set value with TTL
    await redis.set(key, value, "EX", ttl);
  }

  // Set metadata key with TTL
  await redis.set(`_keys:${key}`, Date.now(), "EX", ttl);
};
