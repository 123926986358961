import { Box, Text } from "@xcorejs/ui";
import Instagram from "components/Instagram";
import Footer from "components/Layout/Footer";
import LoggedBar from "components/Layout/LoggedBar";
import RobeHead from "components/RobeHead";
import CookieBar from "design-system/appio/CookieBar";
import { FC, ReactNode, memo } from "react";
import { useLayout } from "xcore";

import Header from "./Header";
import UnsupportedBrowser from "./UnsupportedBrowser";
import RobeRichtext from "components/RobeRichtext";

interface Props {
  noHead?: boolean;
  children?: ReactNode;
}

const Layout: FC<Props> = ({ noHead, children }) => {
  const { cookies, stringify } = useLayout();

  return (
    <>
      {!noHead && <RobeHead />}
      <Box>
        <LoggedBar />

        <Header />

        {children}

        <Instagram />
        <Footer />

        <UnsupportedBrowser />

        <CookieBar
          agreeBtn={stringify(cookies.values.btnAgreed)}
          moreBtn={stringify(cookies.values.btnMore)}
          link={cookies.values.btnMoreRel!}
        >
          <Text textAlign={{ _: "center", sm: "left" }}>
            <RobeRichtext value={cookies.values.message} />
          </Text>
        </CookieBar>
      </Box>
    </>
  );
};

export default memo(Layout);

export const LayoutNoHead: FC = p => <Layout noHead {...p} />;
