import { Text, useTheme } from "@xcorejs/ui";
import { XcoreTheme } from "design-system/xcore/theme";
import { useContext } from "react";
import { components } from "react-select";

export const NoOptionsMessage = (props: any) => {
  const theme = useTheme() as XcoreTheme;
  const noOptionsProps = theme.select.noOptions;

  return (
    <Text {...noOptionsProps}>
      <components.NoOptionsMessage {...props} />
    </Text>
  );
};
