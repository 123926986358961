import { Dispatch, SetStateAction } from "react";
import { useLayout } from "xcore";

import { ShortVideoValuesRestructured } from "./ShortVideo";

interface ShortVideoBaseProps {
  video: ShortVideoValuesRestructured;
  id: number;
  carouselIndex?: number;
  setLoadedVideosIds?: Dispatch<SetStateAction<number[]>>;
}

const ShortVideoBase = ({ video, id, carouselIndex, setLoadedVideosIds }: ShortVideoBaseProps) => {
  const { file, cms } = useLayout();

  if (!video.shortVideo) {
    return null;
  }

  return (
    <video
      width="100%"
      height="100%"
      controls={!video.noControl}
      autoPlay={video.autoPlay}
      loop={video.loop}
      // Only applicable for homepage slider - browsers often freeze autoplay un-muted videos for better user experience (so they don't play randomly). If video is the very first one in the carousel, mute it regardless its CMS value.
      muted={carouselIndex === 0 ? true : video.mute}
      poster={!video.autoPlay ? file(video.videoThumbnail) : undefined}
      preload="auto"
      key={id}
      // After video is loaded add its ID to loaded videos
      onCanPlayThrough={() => setLoadedVideosIds?.((keys) => keys.includes(id) ? [...keys] : [id, ...keys])}
      playsInline
    >
      <source src={`${cms.filesUrl}/api/files/${video.shortVideo.path}${video.shortVideo.ext.startsWith(".") ? video.shortVideo.ext : `.${video.shortVideo.ext}`}`} type={video.shortVideo?.mime} />
    </video>
  );
};

export default ShortVideoBase;
