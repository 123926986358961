import { Container, Flex, FlexProps, GridProps, Grid } from "@xcorejs/ui";

import { FC, ReactNode } from "react";
import styled from "styled-components";

export type ContentProps = {
  leftContent?: ReactNode | ReactNode[];
  middleContent?: ReactNode | ReactNode[];
  rightContent?: ReactNode | ReactNode[];
  _container?: FlexProps;
  _grid?: GridProps;
};

export type NavigationProps =
  & ContentProps
  & FlexProps;

const NavigationStyle = styled(Flex)``;

NavigationStyle.defaultProps = {
  width: "100%",
  bg: "white",
  top: "0",
  zIndex: 3
};

const Navigation: FC<NavigationProps> = ({
  leftContent, middleContent, rightContent, _container, _grid, ...props
}) => (
  <NavigationStyle {...props}>
    <Container {..._container}>
      <Grid columns="auto" rows="auto" width="100%" height="100%" {..._grid}>
        {leftContent && leftContent}
        {middleContent}
        {rightContent && rightContent}
      </Grid>
    </Container>
  </NavigationStyle>
);

export default Navigation;
