
import styled, { ThemeContext } from "styled-components";
import { FC, useContext } from "react";
import { XcoreTheme } from "design-system/xcore/theme";
import { useTheme, Button } from "@xcorejs/ui";

const DotsStyle = styled(Button)``;

const Dot: FC<
{
  onClick?: any;
  active?: boolean;
  index?: any;
  carouselState?: any;
}
> = ({ onClick, active, index, carouselState, ...props }) => {
  const theme = useTheme() as XcoreTheme;
  const dotProps = theme.carousel.dot;
  const dotActiveProps = theme.carousel.dotActive;

  return (
    <div onClick={() => onClick()}>
      <DotsStyle
        {...dotProps}
        {...active && dotActiveProps}
      />
    </div>
  );
};

export default Dot;
