import { InlineValue, useContent, usePageContent } from "@appiodev/xcore-client/xcore-ui";
import { Box } from "@xcorejs/ui";
import { FC } from "react";
import NewsSection from "templates/Homepage/NewsSection";
import { useLayout } from "xcore";
import { News, Product, ProductDetailPage } from "xcore/types";

const ProductNews: FC = () => {
  const [product, { stringify }] = usePageContent<Product>();
  const [detailPage] = useContent<[ProductDetailPage]>();
  const { general, header } = useLayout();

  return (
    <Box id={"#" + stringify(detailPage.values.anchorRelatedNews)}>
      <Box id={stringify(detailPage.values.anchorRelatedNews)} transform={{ _: "translateY(-6rem)", md: "translateY(-13.9rem)" }} />
      <NewsSection
        title={<InlineValue value={detailPage.values.relatedNews} />}
        news={product.fetchedReverseRelations!.relatedNews as News[]}
        categories={[]}
        buttonText={stringify(general.values.btnExploreMore)}
        buttonLink={{ content: header.nav.stories, query: `?product=${product.id}` }}
        backTo={product.id}
      />
    </Box>
  );
};

export default ProductNews;
