import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 10px;
    -webkit-font-smoothing: antialiased;
  }
  
  html, body {
    margin: 0;
    padding: 0;
  }

  body {
    background: #fff;
    font-size: 1.6rem;
  }
  
  *, *:before, *:after {
    box-sizing: border-box;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  }
  
  ::-moz-selection {
    color: white;
    background: #E00034;
  }

  ::selection {
    color: white;
    background: #E00034;
  }
  
  //temporary Lightbox fix (dokud se nenakodi Xcore Lightbox
  .ril-toolbar {
    background-color: transparent;
  }
`;
