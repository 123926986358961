import { FC } from "react";
import styled from "styled-components";
import { Box, Link, AspectRatio, ExtendedLinkProps, Img } from "@xcorejs/ui";

export type InstaCardProps = {
  image?: string;
  alt?: string;
} & ExtendedLinkProps;

const InstaCardStyle = styled(Link)<InstaCardProps>`
  img {
    transition: filter 600ms;
    
    & + div {
      transition: opacity 600ms;
    }
  }
  &:hover {
    
    img {
      filter: none;
      
      & + div {
        opacity: 0;
      }
    }
  }
`;

const Image = styled(Img)`
  width: 100%;
  height: 100%;
`;

const InstaCard: FC<InstaCardProps> = (
  {
    image,
    alt,
    ...props
  }
) => {
  return (
    <InstaCardStyle type="simple" target="_blank" {...props}>
      <AspectRatio ratio={1} position="relative">
        <Image src={image} alt={alt} filter="saturate(0%)" loading="lazy" />
        <Box position="absolute" top={0} bottom={0} left={0} right={0} backgroundColor="rgba(211, 0, 49, .7)" />
      </AspectRatio>
    </InstaCardStyle>
  );
};

export default InstaCard;
