
import styled, { ThemeContext } from "styled-components";
import { components } from "react-select";
import * as system from "styled-system";
import { useContext } from "react";
import { XcoreTheme } from "design-system/xcore/theme";
import { BoxProps, Box, TextProps, useTheme } from "@xcorejs/ui";

export type SingleValueSelectProps =
  & BoxProps
  & TextProps;

const SingleValueStyle = styled(Box)<SingleValueSelectProps>`
  & > * {
    padding-top: 2px;
    font-size: inherit;
    line-height: inherit;
  }
  ${system.typography};
`;

export const SingleValue = (props: any) => {
  const { children } = props;
  const theme = useTheme() as XcoreTheme;
  const singleValueProps = theme.select.singleValue;

  return (
    <SingleValueStyle {...singleValueProps as any}>
      <components.SingleValue {...props}>{children}</components.SingleValue>
    </SingleValueStyle>
  );
};
