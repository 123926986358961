
import { FC } from "react";
import styled from "styled-components";
import { Box, BoxProps, Icon } from "@xcorejs/ui";
import Loader from "components/icons/loader.svg";

export const ErrorAnimatedIcon: FC<BoxProps> = ({ ...props }) => (
  <ErrorAnimatedIconStyle {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <circle cx="32" cy="32" r="30" stroke="#1E1E1E" strokeWidth="4" />
        <line x1="41" x2="23" y1="23" y2="41" stroke="#1E1E1E" strokeWidth="4" />
        <line x1="23" x2="41" y1="23" y2="41" stroke="#1E1E1E" strokeWidth="4" />
      </g>
    </svg>
  </ErrorAnimatedIconStyle>
);

export const SuccessAnimatedIcon: FC<BoxProps> = ({ ...props }) => (
  <SuccessAnimatedIconStyle {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path
          stroke="#099d50"
          strokeWidth="4"
          d="M62,29.24 L62,32 C61.9924034,45.2860576 53.2468819,56.9846389 40.5060572,60.7517029 C27.7652326,64.5187669 14.0641806,59.4569364 6.83286526,48.311199 C-0.398450127,37.1654616 0.562590337,22.5909169 9.19482258,12.4912164 C17.8270548,2.39151593 32.0741379,-0.827424147 44.21,4.58"
        />
        <polyline stroke="#099d50" strokeWidth="4" points="62 8 32 38 23 29.009" />
      </g>
    </svg>
  </SuccessAnimatedIconStyle>
);

export const LoadingAnimatedIcon: FC<BoxProps> = ({ ...props }) => (
  <LoadingAnimatedIconStyle {...props}>
    <Loader />
  </LoadingAnimatedIconStyle>
);

const ErrorAnimatedIconStyle = styled(Box)`
  svg {
    path, circle, line {
      stroke-dashoffset: 1000;
      stroke-dasharray: 1000;
      animation-name: keyframes;
      animation-duration: 3s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      stroke: rgba(224, 0, 52, 1);
    }
    
    line {
      animation-duration: 1s;
      stroke-dashoffset: 100;
      stroke-dasharray: 100;
      
      &:first-of-type {
        animation-delay: 0.5s;
      }
      
      &:last-child {
        animation-delay: 1s;
      }
    }
  }
  
  @keyframes keyframes {
    to {
      stroke-dashoffset: 0;
    }
  }
`;

const SuccessAnimatedIconStyle = styled(Box)`
  svg {

    path, polyline {
      stroke-dashoffset: 1000;
      stroke-dasharray: 1000;
      animation-name: keyframes;
      animation-duration: 3s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      stroke: #099d50;
    }
    
    polyline {
      &:last-child {
        stroke-dashoffset: -100;
        stroke-dasharray: -100;
        animation-duration: 1.5s;
        animation-timing-function: ease-in;
      }
    }
  }
  
  @keyframes keyframes {
    to {
      stroke-dashoffset: 0;
    }
  }
`;

const LoadingAnimatedIconStyle = styled(Box)`
  svg {
    path:nth-child(2) {
      animation-name: keyframes;
      animation-duration: 2s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      //transform: rotate(180deg);

      transform-origin: 50% 37.6%;
    }
    
  }
  
  @keyframes keyframes {
    to {
      transform: rotate(360deg);
    }
  }
`;
