var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"P9xbaYJoZ8fN-Jzaj81uT"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";


import * as Sentry from "@sentry/nextjs";

const ignoreErrors = [
  "Cannot redefine property: googletag" // Happens when using Ad Blocker extensions and browsers like "Stands"
];

export const sentryBaseConfig = {
  dsn: "https://1420bf935730411a96bb58c9d6417d09@o321242.ingest.sentry.io/5278501",
  enabled: process.env.NODE_ENV === "production"
};

Sentry.init({
  ...sentryBaseConfig,
  integrations: [],
  ignoreErrors
});
