import { link } from "@xcorejs/ui";
import { darken } from "polished";

export const linkTheme = link({
  default: {
    lineHeight: "initial",
    fontFamily: "avenir",
    color: "#e00034",
    borderBottom: "1px solid #e00034",
    fontSize: "1.5rem",
    textDecoration: "none",
    _hover: {
      color: darken(0.025, "#e00034"),
      borderBottom: "1px solid transparent"
    }
  },
  variants: {
    underline: {},
    simple: {
      borderBottom: 0,

      _hover: {
        color: darken(0.025, "#e00034"),
        borderBottom: 0
      }
    }
  }
});
