import { FC, useEffect, useState } from "react";
import DownloadIcon from "components/icons/16/download.svg";
import LinkIcon from "components/icons/16/link.svg";
import { Button, ExtendedButtonProps, Spinner } from "@xcorejs/ui";
import { Link } from "@appiodev/xcore-client/xcore-ui";
import { useRouter } from "next/router";

const DownloadButton: FC<
ExtendedButtonProps &
{ isLink?: boolean; isInternal?: boolean; content?: any; download?: boolean }> = ({ isLink, isInternal, content, children, ...props }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { locale } = useRouter();

  useEffect(() => {
    isDownloading && setTimeout(() => setIsDownloading(false), 1500);
  }, [isDownloading]);

  if (isLink && isInternal) {
    return (
      <Link
        content={content}
        locale={locale}
      >
        <Button
          size="xs"
          as="a"
          leftIcon={<LinkIcon />}
          _leftIcon={{ fill: "#fff" }}
          mt={{ _: "1rem", md: 0 }}
          flex="0 0 auto"
        >
          {children}
        </Button>
      </Link>
    );
  }

  return (
    <Button
      size="xs"
      as="a"
      target="_blank"
      leftIcon={isDownloading
        ? <Spinner display="flex" borderRadius="100%" />
        : isLink ? <LinkIcon /> : <DownloadIcon />}
      _leftIcon={{ fill: "#fff" }}
      mt={{ _: "1rem", md: 0 }}
      flex="0 0 auto"
      onClick={(e) => {
        e.stopPropagation();
        setIsDownloading(true);
      }}
      onBlur={() => setIsDownloading(false)}
      {...props}
    >
      {children}
    </Button>
  );
};

export default DownloadButton;
