import { Link as UILink, Text, useModal } from "@xcorejs/ui";
import SmartLink, { SmartLinkProps } from "components/SmartLink";
import UserLoginModal from "components/UserLoginModal";
import { FC } from "react";
import { useLayout } from "xcore";
import { ForgottenPassword, SmartLink as SmartLinkType, UserLogin } from "xcore/types";

import LostPasswordModal from "../LostPasswordModal";

interface Props extends Omit<SmartLinkProps, "smartLink"> {
  smartLink: SmartLinkType | UserLogin | ForgottenPassword;
}

const FooterLink: FC<Props> = ({ smartLink, ...props }) => {
  const [open] = useModal(Modal, { smartLink });
  const { stringify } = useLayout();

  if (!smartLink.values) return null;

  return (
    smartLink.type === "userLogin" || smartLink.type === "forgottenPassword"
      ? (
        <UILink
          as="span"
          type="simple"
          onClick={open}
          role="group"
          {...props._link}
        >
          <Text {...props._text}>
            {/* @ts-ignore */}
            {stringify(smartLink.values.userLoginHeading) ? stringify(smartLink.values.userLoginHeading) : stringify(smartLink.title)}
          </Text>
        </UILink>
      ) : <SmartLink smartLink={smartLink} {...props} />
  );
};

export default FooterLink;

const Modal: FC<{ smartLink: SmartLinkType | UserLogin | ForgottenPassword }> = ({ smartLink }) =>
  smartLink.type === "userLogin"
    ? <UserLoginModal />
    : smartLink.type === "forgottenPassword"
      ? <LostPasswordModal />
      : null;
