import { getXcoreProps, getXcoreServerProps, GetXcoreStaticLayout, useData } from "@appiodev/xcore-client/xcore-ui";
import { Content, getXcoreConfig, XcoreCMS } from "@appiodev/xcore-core";
import { GetStaticPaths, GetStaticPathsContext, GetStaticPropsContext } from "next";
import { ComponentType, FC } from "react";
import { featuredItemsField } from "utils/products";
import { createCache } from "utils/redis";

import { getRelFields } from "../utils/products";
import { AboutUs, ApplicationPicker, Cookies, Error500, General, Innovations, Offices, Products, SpotOn, Sustainability } from "./types";

export type RobePage = FC & {
  Layout?: ComponentType;
  Subheader?: ComponentType;
};

export interface LayoutContent {
  general: General;
  error500: Error500;
  subheader: {
    appPicker: ApplicationPicker;
  };
  header: {
    nav: {
      homepage: Content;
      spotOn: SpotOn;
      products: Products;
      support: Content;
      stories: Content;
      exhibitions: Content;
      aboutUs: AboutUs<"title" | "owners" | "history" | "legal" | "career" | "dropdownCareerCz" | "careerExtLink" | "madeInEUHeading">;
      innovations: Innovations;
      offices: Offices;
      disclaimer: Content;
      privacy: Content;
      patents: Content;
      trademarks: Content;
      sustainability: Content;
    };
    search: Content;
  };
  cookies: Cookies<"btnMore" | "message" | "btnAgreed" | "btnDecline" | "btnMoreRel">;
  cookiesPage: Content;

  newPassPage: Content;
  login: Content;
  accountUpdate: Content;

  ie: boolean;
  ieConsent: boolean;
}

export const useLayout = <T extends LayoutContent>() => useData<Content, [], [], any, T>();

const getLayout: GetXcoreStaticLayout = ({ cms }) => {
  const locales = cms.locales.content;
  return Promise.all([
    cms.content.single<General>("general", v => [
      ...getRelFields("followUsLinks", locales as "en"[]).map(f => v[f]),
      ...getRelFields("helpdeskLinks", locales as "en"[]).map(f => v[f]),
      ...getRelFields("navigateLinks", locales as "en"[]).map(f => v[f]),
      ...getRelFields("inTouchLinks", locales as "en"[]).map(f => v[f])
    ]),
    cms.content.list([
      "homepage",
      "support",
      "newsPage",
      "exhibitions",
      "disclaimer",
      "privacy",
      "patents",
      "trademarks",
      "cookiesPage",
      "search",
      "newPassPage",
      "userLoginPage",
      "accountUpdate"
    ]),
    cms.content.list<AboutUs>("aboutUs", {
      fields: [
        "title", "owners", "history", "legal", "career", "dropdownCareerCz", "careerExtLink", "madeInEUHeading"
      ]
    }),
    cms.content.list<Products>("products", {
      fields: ["middlePageProductFamily"],
      relations: v => [
        v.middlePageProductFamily?.$options([...featuredItemsField(locales)]),
        ...featuredItemsField(locales).map(f => v.middlePageProductFamily?.values?.[f]
          ?.$options(["thumbnailTitle", ...featuredItemsField(locales) as any])
        )
      ]
    }),
    cms.content.list<Cookies>("cookies", {
      includeRelations: true,
      fields: ["btnMoreRel", "message", "btnAgreed", "btnMore", "btnDecline"]
    }),
    cms.content.single<Innovations>("innovations"),
    cms.content.single<Error500>("error500"),
    cms.content.list<Sustainability>("sustainability"),
    Promise.all([
      cms.content.single<ApplicationPicker>("applicationPicker", {
        relations: v => (v.applications ?? []).map(r =>
          r.relation.$options(["title"])
        )
      }),
      cms.content.single<Offices>("offices", v => [
        v.officeList?.map(o => o.offices)
      ]),
      cms.content.single<SpotOn>("spotOn", {
        relations: v =>
          getRelFields("itemsRelation", locales as "en"[]).map(f => v[f])
      })
    ])
  ] as const)
    .then(([general, { contents }, aboutUs, products, cookies, innovations, error500, sustainability, [appPicker, offices, spotOn]]) => {
      return {
        general: general?.content,
        error500: error500?.content,
        subheader: {
          appPicker: appPicker?.content
        },
        header: {
          nav: {
            homepage: contents[0],
            spotOn: spotOn?.content,
            support: contents[1],
            stories: contents[2],
            exhibitions: contents[3],
            innovations: innovations?.content,
            disclaimer: contents[4],
            privacy: contents[5],
            patents: contents[6],
            trademarks: contents[7],
            products: products.contents[0] as any,
            aboutUs: aboutUs.contents[0],
            offices: offices?.content,
            sustainability: sustainability.contents[0] ?? null
          },
          search: contents[9]
        },
        cookies: cookies.contents[0],
        cookiesPage: contents[8],
        ie: false,
        ieConsent: false,
        newPassPage: contents[10],
        login: contents[11],
        accountUpdate: contents[12]
      };
    });
};

const cachedLayout = createCache(
  getLayout,
  ctx => `layout-data/${ctx.locale ?? "default"}`
);

const cachedXcoreProps = getXcoreProps(cachedLayout);
export const getRobeData = getXcoreServerProps(cachedLayout);

export const getRobeProps: typeof cachedXcoreProps = (...args) =>
  async (ctx: GetStaticPropsContext) =>
    await retryAfterError(() => cachedXcoreProps(...args)(ctx))();

export const getRobePaths = (
  content: string | string[]
): GetStaticPaths => async (ctx: GetStaticPathsContext) => {
  if (process.env.NO_SSG_PATHS === "1") {
    return {
      paths: [],
      fallback: "blocking"
    };
  }

  const config = await getXcoreConfig();

  const getContent = async (cms: XcoreCMS) =>
    await cms.content.list(content, { perPage: 50 });

  const paths = await Promise.all((ctx.locales ?? []).map(l =>
    getContent(config.getCMS({ locales: config.locales[l] }))
      .then((res) => res.contents.map((c) => ({
        locale: l,
        params: {
          id: c.id.toString()
        }
      })))
  ));

  return {
    paths: paths.flat(),
    fallback: "blocking"
  };
};

const timeout = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const DEFAULT_RETRY_AFTER_PRERENDER_ERROR_COUNT = 5;

export const retryAfterError = <TArgs extends any[], TResult>(func: (...args: TArgs) => Promise<TResult>) =>
  async (...args: TArgs): Promise<TResult> => {
    const delay = process.env.XCORE_DELAY_BUILD ?? NaN;

    if (delay) {
      const ms = +delay + 2 * (Math.random() - 0.5) * (+delay / 2);
      await timeout(ms);
    }

    if (!delay) return await func(...args);

    const retryCount = process.env.RETRY_AFTER_PRERENDER_ERROR_COUNT ?? DEFAULT_RETRY_AFTER_PRERENDER_ERROR_COUNT;

    // If error occurs during page prerender it is retried N times
    for (let i = 0; i < +retryCount; i++) {
      try {
        return await func(...args);
      } catch (e) {
        console.error(`\nError occurred prerendering page ${args[0]}. Reason:`);
        console.error(e);
      }
      await timeout(+delay);
      console.info("\nRetrying...");
    }
    console.error(`\nPage ${args[0]} failed to render.`);
    process.exit(1);
  };
