import { Link, ExtendedLinkProps, Box } from "@xcorejs/ui";
import { FC } from "react";
import styled from "styled-components";
import { Link as ReactScrollLink } from "react-scroll";

export type ScrollLinkProps = {
  target?: string;
  hashSpy?: boolean;
} & ExtendedLinkProps;

const ScrollLinkStyle = styled(Box)`
  .active {
    color: #FFFFFF !important;
   
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: .2rem;
      background-color: #e00034;
    }
  }
`;

const ScrollLink: FC<ScrollLinkProps> = (
  {
    target,
    hashSpy = true,
    children,
    ...props
  }
) => {
  return (
    <ScrollLinkStyle>
      <Link variant="simple" as="span" padding={0} {...props}>
        <ReactScrollLink
          activeClass="active"
          to={target!}
          smooth
          spy
          isDynamic
          offset={-140}
          duration={500}
          style={{ textDecoration: "none", color: "inherit", lineHeight: "5rem" }}
        >
          {children}
        </ReactScrollLink>
      </Link>
    </ScrollLinkStyle>
  );
};

export default ScrollLink;
