import { Flex, useTheme } from "@xcorejs/ui";
import { XcoreTheme } from "design-system/xcore/theme";
import { useContext } from "react";
import { components } from "react-select";
import styled, { ThemeContext } from "styled-components";

const ControlStyle = styled(Flex)`
  & > div {
    width: 100%;
    display: inherit;
    flex-wrap: inherit;
    align-items: inherit;
    border-radius: inherit;
    border: 0;
    box-shadow: none !important;
    
    &:hover {
      border-color: transparent;
    }
  }
`;

export const Control = (props: any) => {
  const theme = useTheme() as XcoreTheme;
  const controlProps = theme.select.control;

  return (
    <ControlStyle {...controlProps}>
      <components.Control {...props} />
    </ControlStyle>
  );
};
