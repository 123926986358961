/* eslint-disable quote-props */
import { usePageContent } from "@appiodev/xcore-client/xcore-ui";
import { Relation } from "@appiodev/xcore-core";
import { Product, ShortVideo, ShortVideoValues } from "xcore/types";

export type ProductGoogleStructuredDataType = {
  "@context": "https://schema.org";
  "@type": "Product";
  manufacturer: {
    "@type": "Organization";
    name: string;
    image: string;
    url: string;
    address: {
      "@type": "PostalAddress";
      streetAddress: string;
      postalCode: string;
      addressLocality: string;
      addressCountry: string;
    };
    email: string;
    telephone: string;
  };
  name: string;
  description: string;
  keywords: string;
  category: string;
  image: string;
  brand: {
    "@type": "Brand";
    name: string;
    url: string;
    logo: string;
  };
  review: {
    author: {
      "@type": "Organization";
      name: string;
    };
    "@type": "Review";
    name: string;
    positiveNotes?: {
      "@type": "ItemList";
      itemListElement: {
        "@type": "ListItem";
        position: number;
        name: string;
      }[];
    };
  };
  additionalProperty?: {
    "@type": "PropertyValue";
    "name": string;
    "value": string;
  }[];
};

export const useProductGoogleStructuredData = (
  isAccessoryPage: boolean,
  title: string[],
  keyFeatures: Relation<ShortVideo<keyof ShortVideoValues>>[] | null
) => {
  const [{ values, categories }, { stringify, richtextToString, file, value }] = usePageContent<Product>();

  if (isAccessoryPage) { // add google structured data only to product page, not product/accessory
    return null;
  }

  const googleStructuredDataKeywords = title?.filter(stringPart => stringPart !== value(values).name).join(", "); // GSD keywords should not contain product name

  /**
    * @param positiveNotes - Includes array of itemListElements. Each element is description of one parameter for product specified in paramsOrFeatures
    * @param additionalProperty - Array of key features specified for given product in keyFeatures
  */
  const productGoogleStructuredData: ProductGoogleStructuredDataType = {
    "@context": "https://schema.org",
    "@type": "Product",
    manufacturer: robeGeneralInfo,
    name: value(values).name,
    description: values.intro ? richtextToString(values.intro) : "",
    keywords: googleStructuredDataKeywords,
    category: categories?.["product-type"] && Array.isArray(categories?.["product-type"]) && categories["product-type"].length
      ? stringify(categories?.["product-type"][0]?.name)
      : "",
    image: file(values.images[0], { width: 1200, height: 630 }),
    brand: {
      "@type": "Brand",
      name: "Robe Lighting s.r.o.",
      url: "https://www.robe.cz",
      logo: "https://www.robe.cz/static/robe-black.svg"
    },
    review: {
      author: {
        "@type": "Organization",
        name: "Robe Lighting s.r.o."
      },
      "@type": "Review",
      name: value(values).name,
      ...value(values).paramsOrFeatures && Array.isArray(value(values).paramsOrFeatures) && value(values).paramsOrFeatures?.length && {
        positiveNotes: {
          "@type": "ItemList",
          itemListElement: value(values).paramsOrFeatures!.map((param, i) => ({
            "@type": "ListItem",
            position: i + 1,
            name: param.description ? richtextToString(param.description) : ""
          }))
        }
      }
    },
    ...Array.isArray(keyFeatures) && keyFeatures?.length && {
      additionalProperty: keyFeatures.map(kf => ({
        "@type": "PropertyValue",
        name: "Key Feature",
        value: stringify(kf.values.title ?? "")
      }))
    }
  };

  return productGoogleStructuredData;
};

export const robeGeneralInfo = {
  "@type": "Organization",
  name: "Robe Lighting s.r.o.",
  image: "https://www.robe.cz/static/robe-black.svg",
  url: "https://www.robe.cz",
  address: {
    "@type": "PostalAddress",
    streetAddress: "Hazovice 2090",
    postalCode: "75661",
    addressLocality: "Roznov pod Radhostem, Czech Republic",
    addressCountry: "CZ"
  },
  email: "info@robe.cz",
  telephone: "+420 571 669 500"
} as const;

export const isProductGoogleStructuredDataType = (data: any): data is ProductGoogleStructuredDataType => {
  return (
    data &&
    data["@context"] === "https://schema.org" &&
    data["@type"] === "Product" &&
    typeof data.manufacturer === "object" &&
    data.manufacturer["@type"] === "Organization" &&
    typeof data.manufacturer.name === "string" &&
    typeof data.manufacturer.image === "string" &&
    typeof data.manufacturer.url === "string" &&
    typeof data.manufacturer.address === "object" &&
    data.manufacturer.address["@type"] === "PostalAddress" &&
    typeof data.manufacturer.address.streetAddress === "string" &&
    typeof data.manufacturer.address.postalCode === "string" &&
    typeof data.manufacturer.address.addressLocality === "string" &&
    typeof data.manufacturer.address.addressCountry === "string" &&
    typeof data.manufacturer.email === "string" &&
    typeof data.manufacturer.telephone === "string" &&
    typeof data.name === "string" &&
    typeof data.description === "string" &&
    typeof data.keywords === "string" &&
    typeof data.category === "string" &&
    typeof data.image === "string" &&
    typeof data.brand === "object" &&
    data.brand["@type"] === "Brand" &&
    typeof data.brand.name === "string" &&
    typeof data.brand.url === "string" &&
    typeof data.brand.logo === "string" &&
    typeof data.review === "object" &&
    data.review.author["@type"] === "Organization" &&
    typeof data.review.author.name === "string" &&
    data.review["@type"] === "Review" &&
    typeof data.review.name === "string" &&
    (!data.review.positiveNotes ||
      (typeof data.review.positiveNotes === "object" &&
        data.review.positiveNotes["@type"] === "ItemList" &&
        Array.isArray(data.review.positiveNotes.itemListElement) &&
        data.review.positiveNotes.itemListElement.every(
          item =>
            item["@type"] === "ListItem" &&
            typeof item.position === "number" &&
            typeof item.name === "string"
        ))) &&
    (!data.additionalProperty ||
      (Array.isArray(data.additionalProperty) &&
        data.additionalProperty.every(
          property =>
            property["@type"] === "PropertyValue" &&
            typeof property.name === "string" &&
            typeof property.value === "string"
        )))
  );
};
