import { Content } from "@appiodev/xcore-core";
import { InlineValue, useContent } from "@appiodev/xcore-client/xcore-ui";
import { Button, ButtonProps, useModal } from "@xcorejs/ui";
import InquiryIcon from "components/icons/16/inquiry.svg";
import InquiryModal from "components/InquiryModal";
import { FC } from "react";
import { ProductDetailPage } from "xcore/types";

export const InquiryButton: FC<{ product: Content } & ButtonProps> = ({ product, ...props }) => {
  const [{ values }] = useContent<[ProductDetailPage]>();
  const [open] = useModal(InquiryModal, { product });

  return (
    <Button
      s="sm"
      bg="#099d50"
      leftIcon={<InquiryIcon />}
      _leftIcon={{ fill: "#fff" }}
      fontSize="1.4rem"
      fontWeight="bold"
      lineHeight="2.1rem"
      height="5rem"
      textTransform="none"
      _hover={{
        bg: "#067d3f"
      }}
      _active={{
        bg: "#067d3f"
      }}
      _focus={{
        bg: "#067d3f",
        outline: "2px solid rgba(15, 31, 40, 0.2)",
        outlineOffset: "-2px"
      }}
      onClick={open}
      {...props}
    >
      <InlineValue value={values.inquiryBtn} />
    </Button>
  );
};

export default InquiryButton;
