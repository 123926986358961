import { Richtext, Localized, Content } from "@appiodev/xcore-core";
import { Handler, Link as CMSLink, SlateValue, useSlateValue } from "@appiodev/xcore-client/xcore-ui";
import {
  Box,
  BoxProps,
  Button,
  ExtendedButtonProps,
  ExtendedLinkProps,
  ExtendedTextProps,
  ExtendedTypographyProps,
  Flex,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Link,
  Text,
  Typography
} from "@xcorejs/ui";
import { RenderElementProps, RenderLeafProps } from "slate-react";
import _getSlug from "speakingurl";
import styled from "styled-components";
import SmartLink from "./SmartLink";
import { SmartLink as SmartLinkType } from "xcore/types";

interface RobeRichtextProps extends ElementProps, BoxProps {
  value: Richtext | Localized<Richtext> | Handler<Richtext | undefined> | Handler<Localized<Richtext> | undefined> | undefined;
}

const RobeRichtext = ({ value, ...props }: RobeRichtextProps) => {
  const slateValue = useSlateValue(value);
  const slateValueSanitized = sanitizeRichtextContent(slateValue);

  if (!slateValueSanitized?.value) {
    return null;
  }

  return (
    <SlateValue
      {...slateValueSanitized}
      renderElement={renderElement}
      renderLeaf={renderLeaf}
      elementProps={props}
      {...props}
    />
  );
};

export interface ElementProps {
  _paragraph?: ExtendedTypographyProps;

  _img?: BoxProps;

  _h1?: ExtendedTypographyProps;
  _h2?: ExtendedTypographyProps;
  _h3?: ExtendedTypographyProps;
  _h4?: ExtendedTypographyProps;

  _strong?: ExtendedTextProps;

  _link?: ExtendedLinkProps;
  _button?: ExtendedButtonProps;

  _ulClassName?: string;
  _liClassName?: string;
}

export const renderElement = (
  { _paragraph, _h1, _h2, _h3, _h4, _link, _button, _img, _liClassName, _ulClassName }: ElementProps,
  repo: Record<number, Content>
) =>
  ({ children, element: { children: value, ...element }, ...attributes }: RenderElementProps & { element: any }) => {
    switch (element.type) {
      case "ul":
        return <ul className={`rt-ul ${_ulClassName ?? ""}`}>{children}</ul>;
      case "heading-one": {
        const id = getIdSlug(value);
        return (
          <Anchor href={"#" + id}>
            <Heading1 id={id} {..._h1} {...attributes} {...element.styles}>{children}</Heading1>
          </Anchor>
        );
      }
      case "heading-two": {
        const id = getIdSlug(value);
        return (
          <Anchor href={"#" + id}>
            <Heading2 id={id} {..._h2} className="rt-heading-two" {...attributes} {...element.styles}>{children}</Heading2>
          </Anchor>
        );
      }
      case "heading-three": {
        const id = getIdSlug(value);
        return (
          <Anchor href={"#" + id}>
            <Heading3 id={id} {..._h3} className="rt-heading-three" {...attributes} {...element.styles}>{children}</Heading3>
          </Anchor>
        );
      }
      case "heading-four": {
        const id = getIdSlug(value);
        return (
          <Anchor href={"#" + id}>
            <Heading4 id={id} {..._h4} {...attributes} {...element.styles}>{children}</Heading4>
          </Anchor>
        );
      }
      case "li":
        return (
          <li className={`rt-li${_liClassName ? " " + _liClassName : ""}${element?.liFontSize ? ` rt-li-${element.liFontSize}` : ""}`}>
            <div className="rt-li-content-wrapper">{children}</div>
          </li>
        );
      case "ol":
        return <ol {...attributes}>{children}</ol>;
      case "link":
        return element.linkType === "external" ? (
          <Link
            {...attributes}
            href={element.url}
            {...element.styles}
            {...(element?.liFontSize && {
              className: `rt-li-${element.liFontSize}`
            })}
            {...(element.styles?.target && { rel: "noopener noreferrer" })}
            {..._link}
          >
            {children}
          </Link>
        ) : repo[element.content]?.type === "smartLink" ? (
          <SmartLink
            {...(element?.liFontSize && {
              className: `rt-li-${element.liFontSize}`
            })}
            _link={_link}
            smartLink={repo[element.content] as SmartLinkType}
          />
        ) : (
          <CMSLink
            {...attributes}
            {..._link}
            content={repo[element.content]}
            {...element.styles}
            {...(element?.liFontSize && {
              className: `rt-li-${element.liFontSize}`
            })}
          >
            {children}
          </CMSLink>
        );
      case "paragraph":
        return (
          <Typography
            variant="p"
            {..._paragraph}
            {...attributes}
            {...element.styles}
            {...(element?.liFontSize && {
              className: `rt-li-${element.liFontSize}`
            })}
          >
            {children}
          </Typography>
        );
      case "button":
        return (
          <Typography variant="p" textAlign={element.styles?.textAlign ?? "left"}>
            {element.linkType === "external"
              ? (
                <Button
                  as="a"
                  {...attributes}
                  href={element.url}
                  {...element.styles}
                  {...element.styles?.target && { rel: "noopener noreferrer" }}
                  {..._link}
                >{children}
                </Button>
              )
              : repo[element.content].type === "smartLink"
                ? <SmartLink _buttonProps={_button} asButton smartLink={repo[element.content] as SmartLinkType} />
                : <CMSLink {...attributes} {..._link} content={repo[element.content]} {...element.styles} as={Button}>{children}</CMSLink>}
          </Typography>
        );
      case "html":
        return <div dangerouslySetInnerHTML={{ __html: element.src }} />;
      case "img":
        const { caption, ...rest } = element;

        return (
          <Flex {...attributes} alignItems="center" flexDirection="column">
            <Box as="img" {...rest} maxHeight="20rem" {..._img as any} />
            { caption && <Typography mt="1rem" variant="p">{caption}</Typography>}
          </Flex>
        );
      default:
        return (
          <Typography
            variant="p"
            {..._paragraph}
            {...attributes}
            {...element.styles}
            {...(element?.liFontSize && {
              className: `rt-li-${element.liFontSize}`
            })}
          >
            {children}
          </Typography>
        );
    }
  };

// TODO: fix types
export const renderLeaf = ({ _strong }: ElementProps) => ({ attributes, children, leaf }: RenderLeafProps & { leaf: any }) => {
  if (leaf.color || leaf.liFontSize) {
    children = (
      <span
        style={{ ...leaf.color && { color: leaf.color as any } }}
        {...leaf.liFontSize && { className: `rt-li-${leaf.liFontSize}` }}
      >
        {children}
      </span>
    );
  }

  if (leaf.bold && !leaf?.liFontSize) {
    children = <Text variant="strong" fontSize="inherit" {..._strong}>{children}</Text>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }
  if (leaf.sub) {
    children = <sub>{children}</sub>;
  }
  if (leaf.sup) {
    children = <sup>{children}</sup>;
  }
  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  if (leaf.strikethrough) {
    children = <del>{children}</del>;
  }

  return <span {...attributes}>{children}</span>;
};

export const Anchor = styled.a`text-decoration: none;`;

export const getIdSlug = (value: any[]): string => {
  return _getSlug(value.map(child => child.text).join(""), { truncate: 50 });
};

export default RobeRichtext;

/**
 * Deletes last line if it is an empty paragraph.
 * This empty line is desired while editing the richtext for possible further inserts, but non-desired while rendering its content.
 **/
const sanitizeRichtextContent = (content: Richtext | undefined): Richtext | undefined => {
  if (content?.value.length) {
    const lastItem = content.value[content.value.length - 1] as any; // as any because leafs do not have correct types

    if (lastItem.type === "paragraph" && "text" in lastItem.children[0] && lastItem.children[0].text === "") {
      content.value.pop();
    }
  }

  return content;
};
