import { File } from "@appiodev/xcore-core";
import { AspectRatio, BoxProps, Flex, Link } from "@xcorejs/ui";
import { FC, ReactNode } from "react";
import { ShortVideo as ShortVideoType, ShortVideoValues } from "xcore/types";

import ShortVideoBase from "./ShortVideoBase";

// this type is needed because before using this component, it is firstly needed to pick one of the ratios for video in shortVideo content type.
// Thus, ShortVideoValues type is unusable because the values need to change.
export type ShortVideoValuesRestructured = Pick<ShortVideoValues, "description" | "internalDes" | "title"> & {
  videoThumbnail?: File<"410x230" | "740x416" | "1290x726" | "410x230@2x" | "740x416@2x" | "1290x726@2x" |
  "410x410" | "740x740" | "1290x1290" | "410x410@2x" | "740x740@2x" | "1290x1290@2x" |
  "230x410" | "416x740" | "726x1290" | "230x410@2x" | "416x740@2x" | "726x1290@2x">;
  shortVideo?: File;
  autoPlay?: boolean;
  loop?: boolean;
  mute?: boolean;
  noControl?: boolean;
  darkBackground?: boolean;
  ratio?: number;
};

export type ShortVideoRestructured = Omit<ShortVideoType, "values"> & { values: ShortVideoValuesRestructured };

interface Props {
  video: ShortVideoValuesRestructured;
  id: number;
  ratio?: number;
  targetURL?: string;
  newWindow?: boolean;
  carouselIndex?: number;
  button?: ReactNode;
  _button?: BoxProps;
}

const ShortVideo: FC<Props> = ({ video, id, ratio, targetURL, newWindow, carouselIndex, button, _button, ...props }) => {
  // TODO: clean later
  // Start loading videos only after images are loaded
  // const [loadedImagesIds, setLoadedImagesIds] = useState<Array<number>>([]);

  // First video can be shown immediately
  // useEffect(() => setLoadedImagesIds((keys) => keys.length < 1 && id ? [id] : [...keys]), [id]);

  return (
    <AspectRatio ratio={ratio ?? video.ratio ?? 9 / 16} background="black" {...props}>
      {/* there has to be this ternary, because <Link /> without href prevents video from being played/paused  */}
      {targetURL ? (
        <Link
          width="100%"
          display="flex"
          v="simple"
          border="0"
          href={targetURL}
          target={newWindow ? "_blank" : "_self"}
          cursor={targetURL?.length > 1 ? "pointer !important" : "default !important"}
        >
          {/* TODO: clean later */}
          {/* {loadedImagesIds.includes(id) && video.shortVideo */}
          <ShortVideoBase video={video} id={id} carouselIndex={carouselIndex} />
          {/* : (
          <Img
            key={`thumbnail-${id}`}
            width="100%"
            height="100%"
            src={file(video.videoThumbnail)}
            alt={stringify(video.internalDes)}
            style={{ position: "absolute" }}
            onLoad={() => setLoadedImagesIds((keys) => keys.includes(id) ? [...keys] : [id, ...keys])}
            loading="eager"
          />
        )} */}
        </Link>
      ) : <ShortVideoBase video={video} id={id} carouselIndex={carouselIndex} />}

      <Flex position="absolute" bottom={{ _: "30%", sm: "25%", xl: "21%" }} width="100%" justifyContent="center" {..._button}>
        {button}
      </Flex>
    </AspectRatio>
  );
};

export default ShortVideo;
