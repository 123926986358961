import { BoxProps, Flex, Typography, Link } from "@xcorejs/ui";
import { FC } from "react";

const CreatedBy: FC<BoxProps> = ({ ...props }) => (
  <Flex alignItems="center" {...props}>
    <Typography variant="p" color="#c7cace">
      All rights reserved. Created by{" "}
      <Link href="https://appio.cz/" target="_blank" color="lightGray" borderColor="lightGray" lineHeight="initial" variant="underline">
        Appio
      </Link>
    </Typography>
  </Flex>
);

export default CreatedBy;
