import { AspectRatio, Img } from "@xcorejs/ui";
import RobeDoubleCarousel from "design-system/robe/Carousel/Double";
import { RobeLightboxProps } from "design-system/robe/RobeLightbox";
import { FC, useState } from "react";
import { useGallery } from "templates/products/ProductDetail/data";
import { Skeleton } from "@mui/joy";

interface Props extends Omit<RobeLightboxProps, "index" | "white" | "images"> {
  images: [string, string, string, number][]; // [src, alt, retina, id];
}

const ImagesCarousel: FC<Props> = ({ images, ...rest }) => {
  const [openLightbox] = useGallery({ images: images.map(([,, retina]) => retina), ...rest });
  const [isSingleImageLoading, setIsSingleImageLoading] = useState<boolean>(true);
  const [loadedImages, setLoadedImages] = useState<number[]>([]);

  return images.length === 1 ? (
    <AspectRatio ratio={9 / 16}>
      <Skeleton variant="rectangular" loading={isSingleImageLoading}>
        <Img
          src={images[0][0]}
          srcSet={`${images[0][0]} 1x, ${images[0][2]} 2x`}
          alt={images[0][1]}
          height="100%"
          width="auto"
          cursor="pointer"
          onClick={() => openLightbox(0)}
          onLoad={() => setIsSingleImageLoading(false)}
        />
      </Skeleton>
    </AspectRatio>
  ) : (
    <RobeDoubleCarousel>
      {images.map(([src, alt, retina, id], i) => {
        const isImageInLoadedImagesArray = loadedImages.some(loadedImg => loadedImg === id);

        return (
          <AspectRatio key={i} ratio={9 / 16}>
            <Skeleton loading={!isImageInLoadedImagesArray}>
              <Img
                src={src}
                srcSet={`${src} 1x, ${retina} 2x`}
                alt={alt}
                height="100%"
                width="auto"
                cursor="pointer"
                onClick={() => openLightbox(i)}
                onLoad={() => {
                  if (!isImageInLoadedImagesArray) {
                    setLoadedImages(prevImgs => [...prevImgs, id]);
                  }
                }}
              />
            </Skeleton>
          </AspectRatio>
        );
      })}
    </RobeDoubleCarousel>
  );
};

export default ImagesCarousel;
