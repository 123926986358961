
import { FC, useContext } from "react";

import { XcoreTheme } from "design-system/xcore/theme";
import styled, { ThemeContext } from "styled-components";
import * as system from "styled-system";
import { FlexProps, Flex, useTheme } from "@xcorejs/ui";

export type InputAddonProps =
  & FlexProps
  & system.TypographyProps;

const InputAddonStyle = styled(Flex)`
  ${system.typography}
`;

InputAddonStyle.defaultProps = {
  alignItems: "center"
};

const InputAddon: FC<InputAddonProps> = (
  {
    children,
    ...props
  }
) => {
  const theme = useTheme() as XcoreTheme;
  const addonStyle = theme.input.addon;

  return (
    <InputAddonStyle flexShrink={0} {...addonStyle} {...props}>
      {children}
    </InputAddonStyle>
  );
};

export default InputAddon;
