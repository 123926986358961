import { Box, Flex, FlexProps, Icon, IconProps, useTheme } from "@xcorejs/ui";
import { XcoreTheme } from "design-system/xcore/theme";
import { FC, ReactNode, useContext } from "react";
import styled, { css } from "styled-components";

import AccordionToggle from "./AccordionToggle";
import { AccordionContext, AccordionItemContext, isExpanded } from "./data";

interface AccordionHeaderThemeProps extends FlexProps {
  _icon?: IconProps;
}

export interface AccordionHeaderProps extends AccordionHeaderThemeProps {
  icon?: ReactNode;
  _expanded?: AccordionHeaderThemeProps;
  withAnimatedIcon?: boolean;
  customIcon?: boolean;
}

const AccordionHeaderStyle = styled(Flex)<{ icon: IconProps; iconExpanded?: IconProps; customIcon?: boolean }>`
  @keyframes pulse {
    0% {
      transform: translateY(0rem);
    }
  
    50% {
      transform: translateY(0.5rem);
    }
  
    100% {
      transform: translateY(0rem);
    }
  }

  ${props => props.customIcon && css`
    p {
      svg {
        display: block;
        animation: pulse 2s infinite;

        ${props.icon as any}
        ${props.iconExpanded as any}

        ${props.iconExpanded && css`
          path {
            transform: rotate(180deg) !important;
            transform-origin: center;
            fill: #e00034 !important;
          }
        `}
      }
    }
  `}
`;

AccordionHeaderStyle.defaultProps = {
  cursor: "pointer"
};

const AccordionHeader: FC<AccordionHeaderProps> = ({
  children,
  icon,
  _icon,
  _expanded,
  withAnimatedIcon,
  customIcon,
  ...flex
}) => {
  const accordion = useContext(AccordionContext);
  const { expanded, index } = useContext(AccordionItemContext);
  const {
    accordion: { header }
  } = useTheme() as XcoreTheme;

  return (
    <AccordionToggle>
      <AccordionHeaderStyle
        {...header}
        {...flex}
        {...isExpanded(expanded, accordion.expanded, index) && header._expanded}
        {...isExpanded(expanded, accordion.expanded, index) && _expanded}
        icon={_icon as IconProps}
        {...isExpanded(expanded, accordion.expanded, index) && header._expanded && { iconExpanded: header._expanded._icon as IconProps }}
        customIcon={customIcon}
        role="group"
      >
        {children}
        <AccordionToggle>
          {icon && (
            <Box {...withAnimatedIcon && { animation: "pulse 2s infinite" }}>
              <Icon
                display="block"
                {..._icon}
                {...isExpanded(expanded, accordion.expanded, index) && (header._expanded || {})._icon}
                svg={icon}
              />
            </Box>
          )}
        </AccordionToggle>
      </AccordionHeaderStyle>
    </AccordionToggle>
  );
};

export default AccordionHeader;
