import { list } from "@xcorejs/ui";

export const listTheme = list({
  default: {
    lineHeight: "2.5rem",
    fontSize: "1.5rem",
    fontFamily: "avenir",
    margin: 0,
    padding: 0,
    _bullet: {
      content: '"\\2022"',
      color: "#E00034",
      fontSize: "1.7rem",
      marginRight: "1.5rem"
    },
    _items: {
      color: "#5E6062",
      marginBottom: ".5rem"
    }
  }
});
