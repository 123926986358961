
import { FC, ReactNode } from "react";
import { Box, BoxProps } from "@xcorejs/ui";

type PopoverProps = {
  popover: ReactNode;
  _popover?: BoxProps;
} & BoxProps;

const Popover: FC<PopoverProps> = (
  {
    children,
    popover,
    _popover,
    ...props
  }
) => {
  return (
    <Box role="group" position={{ _: "static", md: "relative" }} {...props}>
      {children}

      <Box
        opacity={0}
        position="absolute"
        pointerEvents="none"
        transition="opacity 300ms"
        zIndex={1000}
        transform="translateX(-50%)"
        _group={{
          _hover: {
            opacity: 1,
            pointerEvents: "auto"
          }
        }}
        {..._popover}
      >
        {popover}
      </Box>
    </Box>
  );
};

export default Popover;
