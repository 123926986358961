import { Relation } from "@appiodev/xcore-core";
import { Box, Collapse, Container, Flex, FlexProps, Grid, Img, Link as UILink, SimpleGrid, Stack, Text, Typography } from "@xcorejs/ui";
import Copyright from "design-system/appio/footer/Copyright";
import CreatedBy from "design-system/appio/footer/CreatedBy";
import FooterHeading from "design-system/robe/RobeFooterHeading";
import RobeHeading from "design-system/robe/RobeHeading";
import { FC, useState, useEffect } from "react";
import { useLocalizedRelation } from "utils/useLocalizedRelation";
import { useLayout } from "xcore";
import { SmartLink, UserLogin } from "xcore/types";

import FooterLink from "./FooterLink";

const FooterSectionStyle: FlexProps = {
  pt: { _: "4rem", lg: "8rem" },
  flexDirection: "column",
  alignItems: { _: "center", md: "flex-start" }
};

const Footer: FC = () => {
  const { general, stringify, file } = useLayout();

  // Possibility to force desktop layout for mobile phones
  const [isForcedDesktopLayout, setIsForcedDesktopLayout] = useState<boolean>(typeof sessionStorage !== "undefined" &&
    sessionStorage.getItem("forceDesktopLayout") === "1");

  useEffect(() => {
    if (isForcedDesktopLayout) {
      setForcedViewport();
    }
  });

  const isTouchScreenDevice = typeof window !== "undefined" &&
    ("ontouchstart" in window || navigator.maxTouchPoints) && window.innerWidth < 1024;

  const [showFooterMenuNavigate, setShowFooterMenuNavigate] = useState(false);
  const [showFooterMenuHelpdesk, setShowFooterMenuHelpdesk] = useState(false);
  const [showFooterMenuFollowus, setShowFooterMenuFollowus] = useState(false);
  const [showFooterMenuDiscover, setShowFooterMenuDiscover] = useState(false);

  const navigate = useLocalizedRelation("navigateLinks", general.values, [] as Relation<SmartLink | UserLogin>[]);
  const helpdesk = useLocalizedRelation("helpdeskLinks", general.values, [] as Relation<SmartLink | UserLogin>[]);
  const followUs = useLocalizedRelation("followUsLinks", general.values, [] as Relation<SmartLink | UserLogin>[]);
  const stayInTouch = useLocalizedRelation("inTouchLinks", general.values, [] as Relation<SmartLink | UserLogin>[]);

  return (
    <Box bg="black" pb="6rem">
      <Container flexDirection="column">
        <Grid width="100%" columns={["1fr", "1fr", "1fr", "1fr", "repeat(4, 1fr)"]} rows="auto" gap="0">
          {/* mobile */}
          {/* navigate */}
          <Flex {...FooterSectionStyle} display={{ _: "flex", md: "none" }}>
            {!showFooterMenuNavigate && (
              <FooterHeading onClick={() => setShowFooterMenuNavigate(!showFooterMenuNavigate)}>
                {stringify(general.values.footerNavigate)}
              </FooterHeading>
            )}
            {showFooterMenuNavigate && (
              <FooterHeading onClick={() => setShowFooterMenuNavigate(!showFooterMenuNavigate)} active>
                {stringify(general.values.footerNavigate)}
              </FooterHeading>
            )}

            <Collapse open={showFooterMenuNavigate}>
              <Grid display="inline-grid" columns="1" rows="auto" gap="2rem 6rem" mt="3rem" justifyItems="center">
                {navigate.map((link, i) =>
                  <FooterLink key={i} smartLink={link} {...FooterLinkStyle} />
                )}
              </Grid>
            </Collapse>
          </Flex>

          {/* helpdesk */}
          <Flex {...FooterSectionStyle} display={{ _: "flex", md: "none" }}>
            {!showFooterMenuHelpdesk && (
              <FooterHeading
                onClick={() => setShowFooterMenuHelpdesk(!showFooterMenuHelpdesk)}
              >
                {stringify(general.values.footerHelpdesk)}
              </FooterHeading>
            )}
            {showFooterMenuHelpdesk && (
              <FooterHeading
                onClick={() => setShowFooterMenuHelpdesk(!showFooterMenuHelpdesk)}
                active
              >
                {stringify(general.values.footerHelpdesk)}
              </FooterHeading>
            )}

            <Collapse open={showFooterMenuHelpdesk}>
              <Grid display="inline-grid" columns="1" rows="auto" gap="2rem 6rem" mt="3rem" justifyItems="center">
                {helpdesk.map((link, i) =>
                  <FooterLink key={i} smartLink={link} {...FooterLinkStyle} />
                )}
              </Grid>
            </Collapse>
          </Flex>

          {/* followUs */}
          <Flex {...FooterSectionStyle} display={{ _: "flex", md: "none" }}>
            {!showFooterMenuFollowus && (
              <FooterHeading onClick={() => setShowFooterMenuFollowus(!showFooterMenuFollowus)}>
                {stringify(general.values.footerFollowUs)}
              </FooterHeading>
            )}
            {showFooterMenuFollowus && (
              <FooterHeading onClick={() => setShowFooterMenuFollowus(!showFooterMenuFollowus)} active>
                {stringify(general.values.footerFollowUs)}
              </FooterHeading>
            )}

            <Collapse open={showFooterMenuFollowus}>
              <Grid display="inline-grid" columns="1" rows="auto" gap="2rem 6rem" mt="3rem" justifyItems="center">
                {followUs.map((link, index) =>
                  <FooterLink key={index} smartLink={link} {...FooterLinkStyle} />
                )}
              </Grid>
            </Collapse>
          </Flex>

          {/* stayInTouch */}
          <Flex {...FooterSectionStyle} display={{ _: "flex", md: "none" }}>
            {!showFooterMenuDiscover && (
              <FooterHeading onClick={() => setShowFooterMenuDiscover(!showFooterMenuDiscover)}>
                {stringify(general.values.footerInTouch)}
              </FooterHeading>
            )}
            {showFooterMenuDiscover && (
              <FooterHeading onClick={() => setShowFooterMenuDiscover(!showFooterMenuDiscover)} active>
                {stringify(general.values.footerInTouch)}
              </FooterHeading>
            )}

            <Collapse open={showFooterMenuDiscover}>
              <Grid display="inline-grid" columns="1" rows="auto" gap="2rem 6rem" mt="3rem" justifyItems="center">
                {stayInTouch.map((link, i) =>
                  <FooterLink key={i} smartLink={link} {...FooterLinkStyle} />
                )}
              </Grid>
            </Collapse>
          </Flex>
        </Grid>

        <SimpleGrid width="100%" columns={{ _: 1, md: 4 }} gap="0 2rem">
          {/* desktop */}
          <Flex {...FooterSectionStyle} display={{ _: "none", md: "flex" }}>
            <RobeHeading fontSize="2.4rem" lineHeight="3.3rem" color="#fff" textAlign={{ _: "center", sm: "left" }} mb="3rem">
              {stringify(general.values.footerNavigate)}
            </RobeHeading>

            <SimpleGrid display="inline-grid" columns={1} gap="2rem 6rem">
              {navigate.map((link, i) =>
                <FooterLink key={i} smartLink={link} {...FooterLinkStyle} />
              )}
            </SimpleGrid>
          </Flex>

          <Flex {...FooterSectionStyle} display={{ _: "none", md: "flex" }}>
            <RobeHeading fontSize="2.4rem" lineHeight="3.3rem" color="#fff" textAlign={{ _: "center", sm: "left" }} mb="3rem">
              {stringify(general.values.footerHelpdesk)}
            </RobeHeading>

            <SimpleGrid display="inline-grid" columns={1} gap="2rem 6rem">
              {helpdesk.map((link, i) =>
                <FooterLink key={i} smartLink={link} {...FooterLinkStyle} />
              )}
            </SimpleGrid>
          </Flex>

          <Flex {...FooterSectionStyle} display={{ _: "none", md: "flex" }}>
            <RobeHeading fontSize="2.4rem" lineHeight="3.3rem" color="#fff" textAlign={{ _: "center", sm: "left" }} mb="3rem">
              {stringify(general.values.footerFollowUs)}
            </RobeHeading>

            <Grid display="inline-grid" columns="1" rows="auto" gap="2rem 6rem">
              {followUs.map((link, i) =>
                <FooterLink key={i} smartLink={link} {...FooterLinkStyle} />
              )}
            </Grid>
          </Flex>

          <Flex {...FooterSectionStyle} display={{ _: "none", md: "flex" }}>
            <RobeHeading fontSize="2.4rem" lineHeight="3.3rem" color="#fff" textAlign={{ _: "center", sm: "left" }} mb="3rem">
              {stringify(general.values.footerInTouch)}
            </RobeHeading>

            <SimpleGrid display="inline-grid" columns={1} gap={{ _: "2rem 2rem", lg: "2rem 6rem" }}>
              {stayInTouch.map((link, i) =>
                <FooterLink key={i} smartLink={link} {...FooterLinkStyle} />
              )}
            </SimpleGrid>
          </Flex>
        </SimpleGrid>

        <SimpleGrid width="100%" columns={{ _: 1, md: 4 }} gap="0">
          {/* desktop */}
          <Flex {...FooterSectionStyle} display={{ _: "none", md: "grid" }}>
            <Text mb="3rem">{stringify(general.values.textPartners)}</Text>
            <Stack alignItems="center" gap="2rem">
              {(general.values.partnersLogos ?? []).map((partner, i) => (
                <UILink key={i} type="simple" href={partner.link ?? ""} target="_blank">
                  <Img src={file(partner.image)} alt={partner.description} loading="lazy" />
                </UILink>
              ))}
            </Stack>
          </Flex>

          <Flex {...FooterSectionStyle} display={{ _: "none", md: "grid" }}>
            <Text mb="3rem">{stringify(general.values.textSponsors)}</Text>
            <Stack alignItems="center" gap="2rem">
              {(general.values.sponsorsLogos ?? []).map((sponsor, i) => (
                <UILink key={i} type="simple" href={sponsor.link ?? ""} target="_blank">
                  <Img src={file(sponsor.image)} alt={sponsor.description} loading="lazy" />
                </UILink>
              ))}
            </Stack>
          </Flex>

          <Flex {...FooterSectionStyle} display={{ _: "none", md: "grid" }}>
            {/* <Text mb="3rem">{stringify(general.values.textCertificates)}</Text> */}
          </Flex>

          <Flex {...FooterSectionStyle} display={{ _: "none", md: "grid" }}>
            <Text mb="3rem">{stringify(general.values.textMember)}</Text>
            {(general.values.membersLogos ?? []).map((member, i) => (
              <UILink key={i} type="simple" href={member.link ?? ""} target="_blank">
                <Img src={file(member.image)} alt={member.description} loading="lazy" />
              </UILink>
            ))}
          </Flex>
        </SimpleGrid>

        <Flex mt="8rem" flexDirection={{ _: "column", sm: "row" }} alignItems="center" justifyContent="space-between">
          <Stack direction={{ _: "column", sm: "row" }} gap="3rem" align="center">
            <UILink type="simple" href="/">
              <Img src="/static/robe-white.svg" alt="ROBE logo" loading="lazy" />
            </UILink>
            <Copyright>ROBE lighting s.r.o.</Copyright>
          </Stack>

          <CreatedBy m={{ _: "1rem 0 1.5rem 0", sm: 0 }} />
        </Flex>
        {isForcedDesktopLayout || isTouchScreenDevice ? (
          <Flex justifyContent="flex-end">
            <Box onClick={() => toggleDesktopLayout(isForcedDesktopLayout, setIsForcedDesktopLayout)}>
              <Typography>{isForcedDesktopLayout ? "Switch to mobile mode" : "Switch to desktop mode"}</Typography>
            </Box>
          </Flex>
        ) : <></>}
      </Container>
    </Box>
  );
};

export default Footer;

const toggleDesktopLayout = (isForcedDesktopLayout, setIsForcedDesktopLayout) => {
  isForcedDesktopLayout ? setDefaultViewport() : setForcedViewport(true);
  setIsForcedDesktopLayout(!isForcedDesktopLayout);
};

const setDefaultViewport = () => {
  document.querySelector("meta[name=viewport]")?.setAttribute("content",
    "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no, viewport-fit=cover");
  sessionStorage.setItem("forceDesktopLayout", "0");
};

const setForcedViewport = (toggleSession = false) => {
  document.querySelector("meta[name=viewport]")?.setAttribute("content",
    `width=1200, initial-scale=${window.innerWidth < 768 ? "0.3" : "0.6"}, user-scalable=yes, viewport-fit=cover`);
  toggleSession && sessionStorage.setItem("forceDesktopLayout", "1");
};

const FooterLinkStyle = {
  _link: {
    display: "flex",
    alignItems: "center",
    color: "lightGray",
    fontWeight: 600,
    fontSize: 1,
    lineHeight: "1.5",
    role: "group",
    borderColor: "transparent",
    _hover: {
      color: "#fff",
      borderColor: "transparent"
    }
  },
  _text: {
    color: "lightGray",
    borderBottom: "2px solid transparent",
    transition: "border 300ms, color 300ms",
    lineHeight: "inherit",
    _group: {
      _hover: {
        color: "#fff",
        borderBottom: "2px solid #ee0037"
      }
    }
  }
};
