import { createContext, FC, ReactNode, useContext } from "react";
import { XcoreTheme } from "design-system/xcore/theme";
import styled, { ThemeContext } from "styled-components";
import Label, { LabelProps } from "design-system/xcore/Form/Label";
import { FlexProps, Flex, Stack, Box, Text, useTheme } from "@xcorejs/ui";

export type FormControlProps = {
  label?: ReactNode;
  labelText?: string;
  _label?: LabelProps;
  additionalInfo?: string;
  error?: string;
  context?: "normal" | "isDisabled" | "isReadOnly" | "isRequired";
  errorContext?: "normal" |"isInvalid";
} & FlexProps;

export const FormControlStyle = styled(Flex)``;

const FormControlContext = createContext("normal");
const FormErrorControlContext = createContext("normal");

export const useFormControlContext = () => {
  return useContext(FormControlContext);
};

export const useFormErrorControlContext = () => {
  return useContext(FormErrorControlContext);
};

const FormControl: FC<FormControlProps> = (
  {
    label,
    labelText,
    _label,
    additionalInfo,
    error,
    context = "normal",
    errorContext = "normal",
    children,
    ...props
  }
) => {
  const theme = useTheme() as XcoreTheme;
  const labelProps = theme.formControl.label;
  const additionalProps = theme.formControl.additional;
  const errorProps = theme.formControl.error;

  return (
    <FormControlContext.Provider value={context}>
      <FormErrorControlContext.Provider value={errorContext}>
        <FormControlStyle {...props}>
          <Stack direction="column" gap={(additionalInfo || error) && "1rem"} width="100%">
            <Label required={context === "isRequired" && true} text={labelText && labelText} {...labelProps} {..._label}>
              {label && label}
              {children}
            </Label>

            <Box>
              {additionalInfo && <Text display="block" {...additionalProps}>{additionalInfo}</Text>}
              {error && <Text display="block" fontWeight="bold" {...errorProps}>{error}</Text>}
            </Box>
          </Stack>
        </FormControlStyle>
      </FormErrorControlContext.Provider>
    </FormControlContext.Provider>

  );
};

export default FormControl;
