import { SFCategory, SFMedia } from "@robe/admin/data/types";
import { InlineValue, Link, resolveLocale, useContent, useContents, usePageContent } from "@appiodev/xcore-client/xcore-ui";
import { Box, Button, Collapse, Container, Flex, Link as UILink, Stack, Typography } from "@xcorejs/ui";
import ExternalSVG from "components/ExternalSVG";
import DownloadIcon4 from "components/icons/download/dl-datasheet.svg";
import DownloadCard from "design-system/robe/cards/Download";
import RobeProductDetailHeading from "design-system/robe/RobeProductDetailHeading";
import { FC, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { useLayout } from "xcore";
import { Product, ProductDetailPage, Support } from "xcore/types";

import { datasheetUrl, useSection } from "./data";
import ProductDownloadsModal from "./ProductDownloadsModal";
import { useRouter } from "next/router";

const ProductDownloads: FC = () => {
  const { locale } = useRouter();
  const [{ id, ...content }, { stringify, file, cms }] = usePageContent<Product>();
  const [{ values }] = useContent<[ProductDetailPage]>();
  const [support] = useContents<[Support]>();
  const anchor = "#" + stringify(values.anchorDownloads);

  const [active, toggle] = useSection(anchor);
  const category = useModalCategory();

  const { header: { nav } } = useLayout();
  const { data } = useSWR(`/api/product/${id}/downloads`);
  const categories: SFCategory[] = data?.categories.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);
  const downloads: SFMedia[] = data?.downloads;

  const findDownloadIcon = (categoryId: number, supportData: Support) => {
    switch (categoryId) {
      case 49:
        return file(supportData.values.dimensionsSvg);
      case 50:
        return file(supportData.values.catalougesSvg);
      case 51:
        return file(supportData.values.certificatesSvg);
      case 53:
        return file(supportData.values.chartsSvg);
      case 54:
        return file(supportData.values.librariesSvg);
      case 55:
        return file(supportData.values.gobosSvg);
      case 57:
        return file(supportData.values.updatesSvg);
      case 58:
        return file(supportData.values.manualsSvg);
      case 56:
        return file(supportData.values.photometricsSvg);
      case 75:
        return file(supportData.values.noiseMeasurementsSvg);
      default:
        return file(supportData.values.catalougesSvg);
    }
  };

  const categoryNames = useMemo(() =>
    categories?.map(c => c.localeName ? resolveLocale(cms.locales.content, content)(c.localeName) : c.name) ?? [],
  [categories, content, cms.locales.content]);

  return (
    <Box id={anchor}>
      <Box id={stringify(values.anchorDownloads)} transform={{ _: "translateY(-6rem)", md: "translateY(-13.9rem)" }} />
      {data ? (
        <Box>
          {categories.map(c =>
            <Box key={c.id} id={`downloads-${c.id}`} transform={{ _: "translateY(-6rem)", md: "translateY(-13.9rem)" }} />
          )}

          {category && (
            <ProductDownloadsModal
              id={id}
              category={category}
              downloads={downloads}
              buttonSuppFiles={stringify(values.displaySuppFiles)!}
            />
          )}

          <Container flexDirection="column" py={{ _: "1.5rem", sm: "5rem" }}>
            <RobeProductDetailHeading onClick={toggle} active={active} {...active && { mb: { _: "3rem", sm: "4rem" } }}>
              <InlineValue value={values.downloads} />
            </RobeProductDetailHeading>

            <Collapse open={active} mt={{ _: "-1rem", sm: 0 }}>
              <Stack direction="column" gap={{ _: "2rem", sm: "4rem" }}>
                <Typography variant="lead" textAlign="center" mt={{ _: "-1rem", sm: 0 }}>
                  <InlineValue value={values.downloadIntro} />
                </Typography>
                <Flex
                  justifyContent="center"
                  flexWrap="wrap"
                  margin={{
                    _: "0 0 -1rem 0",
                    xs: "0 0 -2.2rem 0"
                  }}
                >
                  <Flex
                    maxWidth={{ _: "100%", xs: "50%", sm: "25%" }}
                    flex={{ _: "0 0 100%", xs: "0 0 50%", sm: "0 0 25%" }}
                    paddingX={{ _: 0, sm: "1.5rem" }}
                    paddingBottom={{ _: "1rem", xs: "2.2rem" }}
                  >
                    <UILink
                      variant="simple"
                      target="_blank"
                      href={datasheetUrl(id, locale ?? "en")}
                      width="100%"
                    >
                      <DownloadCard title={stringify(values.dataSheetMenu)} icon={<DownloadIcon4 />} />
                    </UILink>
                  </Flex>
                  {categories.map((c, i) => (
                    <Flex
                      key={c.id}
                      maxWidth={{ _: "100%", xs: "50%", sm: "25%" }}
                      flex={{ _: "0 0 100%", xs: "0 0 50%", sm: "0 0 25%" }}
                      paddingX={{ _: 0, sm: "1.5rem" }}
                      paddingBottom={{ _: "1rem", xs: "2.2rem" }}
                    >
                      <UILink variant="simple" href={`#downloads-${c.id}`} width="100%">
                        <DownloadCard
                          title={categoryNames[i]}
                          icon={<ExternalSVG src={findDownloadIcon(c.id, support[0] as Support)!} width="1.6rem" />}
                        />
                      </UILink>
                    </Flex>
                  ))}
                </Flex>
                {categories.length > 0 && (
                  <Flex justifyContent="center">
                    <Link content={nav.support} query={`?product=${id}`}>
                      <Button>{stringify(values.displaySuppFiles)}</Button>
                    </Link>
                  </Flex>
                )}
              </Stack>
            </Collapse>
          </Container>
        </Box>
      ) : null }
    </Box>
  );
};

export default ProductDownloads;

const useModalCategory = () => {
  const [hash, setHash] = useState<string>();

  const { data } = useLayout();
  const categories: SFCategory[] = data.downloads.categories.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);

  useEffect(() => {
    setHash(window.location.hash);

    const h = () => setHash(window.location.hash);

    window.addEventListener("hashchange", h);
    return () => window.removeEventListener("hashchange", h);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const category = useMemo(() => {
    const slug = hash?.startsWith("#downloads-") && Number(hash.slice(11));
    return categories.find(c => c.id === slug);
  }, [categories, hash]);

  return category;
};
