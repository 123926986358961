import { Box, Icon, useTheme } from "@xcorejs/ui";
import { XcoreTheme } from "design-system/xcore/theme";

import { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

import DropIcon from "design-system/xcore/icons/drop.svg";

const DropdownIndicatorStyle = styled(Box)``;

export const DropdownIndicator = () => {
  const theme = useTheme() as XcoreTheme;
  const dropdownIndicatorProps = theme.select.dropdownIndicator;

  return (
    <DropdownIndicatorStyle {...dropdownIndicatorProps}>
      <Icon svg={<DropIcon />} />
    </DropdownIndicatorStyle>
  );
};
