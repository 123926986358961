import { FC, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { components, ValueContainerProps } from "react-select";
import * as system from "styled-system";

import { XcoreTheme } from "design-system/xcore/theme";
import { BoxProps, Box, TextProps, useTheme } from "@xcorejs/ui";

export type ValueContainerSelectProps =
  & BoxProps
  & TextProps;

const ValueContainerStyle = styled(Box)<ValueContainerSelectProps>`
  display: flex;
  align-items: center;
  width: 100%;
  
  & > div {
    padding: 0;
  }
  
  ${system.typography}
`;

export const ValueContainer: FC<ValueContainerSelectProps & ValueContainerProps<any>> = props => {
  const { children } = props;
  const theme = useTheme() as XcoreTheme;
  const valueContainerProps = theme.select.valueContainer;

  return (
    <ValueContainerStyle {...valueContainerProps as any}>
      <components.ValueContainer {...props}>{children}</components.ValueContainer>
    </ValueContainerStyle>
  );
};
