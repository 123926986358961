import { Box, Flex, FlexProps, TextProps, IconProps, Icon, useTheme } from "@xcorejs/ui";

import { FC, ReactNode, useContext } from "react";

import styled, { ThemeContext } from "styled-components";
import * as system from "styled-system";

import { XcoreTheme } from "../theme";

export type DropdownItemProps = {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  leftNode?: ReactNode;
  rightNode?: ReactNode;
  _leftIcon?: IconProps;
  _rightIcon?: IconProps;
} & FlexProps
& system.TypographyProps;

const DropdownItemStyle = styled(Flex)<DropdownItemProps>`
  ${system.typography}
`;

DropdownItemStyle.defaultProps = {
  alignItems: "center",
  justifyContent: "space-between",
  transition: "color 300ms, background 300ms"
};

const DropdownItem: FC<DropdownItemProps> = ({
  leftIcon,
  rightIcon,
  _leftIcon,
  _rightIcon,
  leftNode,
  rightNode,
  children,
  ...props
}) => {
  const theme = useTheme() as XcoreTheme;
  const itemProps = theme.dropdown.item;
  const itemLeftIconProps = theme.dropdown.leftIcon;
  const itemRightIconProps = theme.dropdown.rightIcon;

  return (
    <DropdownItemStyle {...itemProps} {...props} role="group">
      <Flex width="100%">
        {leftIcon &&
          <Icon mr="1rem" svg={leftIcon} {...itemLeftIconProps} {..._leftIcon} />}

        {leftNode &&
          leftNode}

        {children}
      </Flex>

      <Flex flex="0 0 auto">
        {rightNode &&
          rightNode}

        {rightIcon &&
          <Icon ml="1rem" svg={rightIcon} {...itemRightIconProps} {..._rightIcon} />}
      </Flex>
    </DropdownItemStyle>
  );
};

export default DropdownItem;
