import { FC, useContext } from "react";

import { AccordionContext, AccordionItemContext } from "./data";
import { Box } from "@xcorejs/ui";

const AccordionToggle: FC = ({ children }) => {
  const { onSelect } = useContext(AccordionContext);
  const { index } = useContext(AccordionItemContext);

  return <Box onClick={() => onSelect(index)}>{children}</Box>;
};

export default AccordionToggle;
