import { GetXcoreStaticContext, usePageContent, useXcoreQuery, XcoreOptions } from "@appiodev/xcore-client/xcore-ui";
import { Localized, Relation, reverseRelation, Richtext } from "@appiodev/xcore-core";
import { LayoutNoHead } from "components/Layout";
import RobeHead from "components/RobeHead";
import { useRouter } from "next/router";
import ProductDetailTemplate from "templates/products/ProductDetail";
import { ProductDetailSubheader } from "templates/products/ProductDetail/ProductDetailSubheader";
import { capitalize } from "utils/capitalize";
import { useProductGoogleStructuredData } from "utils/googleStructuredData/useProductGoogleStructuredData";
import { hasStringField } from "utils/migration";
import { getRelFields } from "utils/products";
import { resolveFetchTimeout } from "utils/resolveFetchTimeout";
import { resolveLocaleField } from "utils/useLocalizedRelation";
import { useTagManager } from "utils/useTagManager";
import { getRobePaths, getRobeProps, RobePage } from "xcore";
import { Accessory, Gallery, Inquiry, News, Product, ProductDetailPage, Products, ShortVideo, ShortVideoValues, Support } from "xcore/types";

export const getXcoreProps = async (
  { cms, id, locale }: GetXcoreStaticContext
): Promise<Partial<XcoreOptions<Product | Accessory, [ProductDetailPage, Inquiry], [Support], {}>>> => {
  const pageContent = await cms.content.single<Product | Accessory>(id!, {
    preview: true,
    fetchRichtextLinks: true,
    relations: v => [
      v.specification?.map(s => s.type),
      v.wheels?.map(w => w.wheelSet),
      v.wheels?.map(w => w.wheels),
      v.videos,
      ...getRelFields("keyFeatures", cms.getCtx().locales.content).map(r => v[r]), // main carousel slides
      v.accessories?.$options(["images", "discontinuedGlob", "discontinuedReg", "name"]),
      v.innovations?.$options(["shortcut", "name", "intro", "video"]),
      v.paramsOrFeatures?.map(p => p.type)
    ],
    reverseRelations: {
      relatedNews: reverseRelation<News>(
        "news",
        v => [v.products],
        { perPage: 4, sort: "publishedAt", fields: ["title", "intro", "images", "size", "thumbnail"] }
      ),
      inspiration: reverseRelation<Gallery>("gallery", v => [v.relatedProducts], { fields: ["image"] })
    }
  });

  if (pageContent === null) {
    return {
      pageContent: null
    };
  }

  return {
    pageContent,
    content: [
      cms.content.single("productDetailPage"),
      cms.content.single("inquiry")
    ],
    contents: [
      cms.content.list("support", {
        includeValues: true,
        includeFiles: true
      })
    ],
    // todo xcc, this may be a duplicate try removing this if ok remove from xcore props options as this is the only place this is being used
    data: async () => ({
      downloads: await cms.fetch(`/api/product/${pageContent.content.id}/downloads`, undefined, resolveFetchTimeout(locale ?? "en")).then(res => res.json())
    })
  };
};

export const getStaticProps = getRobeProps(getXcoreProps);

export const getStaticPaths = getRobePaths("product");

const ProductDetail: RobePage = () => {
  const [{ values, categories, publishedAt }, { stringify, richtextToString, file, value, cms }] = usePageContent<Product>();
  const { locale } = useRouter();
  const isCurrentLocalePublished = Object.keys(publishedAt).some(l => l === locale);
  const router = useRouter();
  const isAccessoryPage = router.pathname.includes("/product/accessory");

  // fetch icon names dictionary and get corresponding icon name based on product icon name value
  const productIconName = useXcoreQuery(c =>
    c.content.single<Products>("products")
  )?.data?.content?.values[`iconLegend${value(values).productMenuIcon ?? ""}`];

  useTagManager("product-detail");

  const metaTitle = stringify(values?.metaTitle);
  const description = value(values?.metaDescription)
    ? hasStringField(value(values?.metaDescription))
      ? stringify(values.metaDescription as unknown as Localized<string>)
      : richtextToString(values.metaDescription as unknown as Localized<Richtext>)
    : richtextToString(values.description);

  const title = [
    stringify(values.name),
    categories?.["product-type"] ? `${categories["product-type"].map(pt => stringify(pt.name)).join(" ")} ${value(values).ipCertifiedIcon ? "IP65" : ""} ${stringify(productIconName) ?? ""}` : "",
    categories?.["beam-type"] ? `${categories["beam-type"].map(bt => stringify(bt.name)).join(" ")}` : ""
  ];

  // if current locale is published without any key features, do not show fallback locale key features. Key features will be shown only if specified in current locale or locale fallback logic will be used if current locale is unpublished
  const keyFeatures = `keyFeatures${capitalize(locale ?? "En")}` in value(values) || !isCurrentLocalePublished
    ? resolveLocaleField(
      "keyFeatures",
      value(values),
      [] as Relation<ShortVideo<keyof ShortVideoValues>>[],
      cms.locales.content
    )
    : null;

  const productGoogleStructuredData = useProductGoogleStructuredData(isAccessoryPage, title, keyFeatures);

  return (
    <>
      <RobeHead
        googleStructuredData={productGoogleStructuredData}
        title={title}
        {...metaTitle && metaTitle !== "" ? { metaTitle } : { metaTitle: title }}
        description={description}
        img={file(values.images[0], { width: 1200, height: 630 })}
      />
      <ProductDetailTemplate keyFeatures={keyFeatures} />
    </>
  );
};

ProductDetail.Layout = LayoutNoHead;
ProductDetail.Subheader = ProductDetailSubheader;

export default ProductDetail;
