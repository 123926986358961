import { usePageContent, useContent } from "@appiodev/xcore-client/xcore-ui";
import { Box, Cell, Collapse, Grid, Link, SimpleGrid, Stack, Typography } from "@xcorejs/ui";
import GoboCard from "design-system/robe/cards/Gobo";
import Focus from "design-system/robe/Focus";
import RobeGobosHeading from "design-system/robe/RobeGobosHeading";
import RobeProductDetailHeading from "design-system/robe/RobeProductDetailHeading";
import { FC, useEffect, useState, Fragment } from "react";
import { Product, Wheel, Gobo, ProductDetailPage } from "xcore/types";
import { useGallery } from "templates/products/ProductDetail/data";

const GobosColor: FC = () => {
  const [product, { file, stringify, value }] = usePageContent<Product>();
  const [{ values }] = useContent<[ProductDetailPage]>();
  const [showGobos, setShowGobos] = useState(false);
  const [[activeSet, activeWheel], setActive] = useState<[number, number]>(() => [0, 0]);
  const active = value(product.values?.wheels[activeSet].wheels?.[activeWheel]);
  const [openLightbox] = useGallery({ images: active ? [file(active.values.image, { width: 300, height: 300 })!] : [], white: true });

  useEffect(() => setShowGobos(window.innerWidth >= 1024), []);

  return (
    <Box>
      <RobeProductDetailHeading
        onClick={() => setShowGobos(!showGobos)}
        active={showGobos}
        {...showGobos && { mb: { _: "3rem", sm: "4rem" } }}
      >
        {"gobosColors" in values && stringify(values.gobosColors)}
      </RobeProductDetailHeading>

      <Collapse open={showGobos}>
        <SimpleGrid columns={{ _: 12 }} gap="3rem">
          <Cell gridColumn={{ _: "1/13", sm: "1/8" }}>
            <Stack direction="column" gap={{ _: "3rem", sm: "4rem" }}>
              {product.values.wheels?.map((w, i) => value(w.wheels) && value(w.wheelSet) && (
                <WheelSet
                  key={i}
                  wheelSet={value(w.wheelSet)!}
                  wheels={value(w.wheels)!}
                  setActive={ii => setActive([i, ii])}
                  active={i === activeSet ? activeWheel : undefined}
                />
              ))}
            </Stack>
          </Cell>

          {active && (
            <Cell column={{ sm: "9/13" }} display={{ _: "none", sm: "block" }}>
              <Focus
                imageSrc={file(active.values.image, { width: 300, height: 300 })}
                {...active.values.image && { srcSet: `${file(active.values.image, { width: 300, height: 300 })!} 1x, ${file(active.values.image, { width: 300, height: 300, enlargement: 2 })!} 2x` }}
                onClick={() => openLightbox(0)}
                cursor="pointer"
              />
            </Cell>
          )}
        </SimpleGrid>
      </Collapse>
    </Box>
  );
};

export default GobosColor;

interface WheelSetProps{
  wheelSet: Wheel;
  wheels: Gobo[];
  setActive: (i: number) => unknown;
  active?: number;
}

const WheelSet: FC<WheelSetProps> = ({ wheelSet, wheels, setActive, active, ...props }) => {
  const [showWheels, setShowWheels] = useState(true);
  const [, { file, stringify }] = usePageContent<Product>();
  const [openLightbox] = useGallery({ images: wheels.map(g => file(g.values.image, { width: 300, height: 300 }) ?? ""), white: true });

  useEffect(() => setShowWheels(window.innerWidth >= 768), []);

  return (
    <Box {...props}>
      {wheelSet.values &&
        <Typography variant="h3" display={{ _: "none", sm: "block" }}>{stringify(wheelSet.values.name)}</Typography>}

      <RobeGobosHeading
        display={{ _: "flex", sm: "none" }}
        active={showWheels}
        onClick={() => setShowWheels(!showWheels)}
      >
        {stringify(wheelSet.values?.name)}
      </RobeGobosHeading>

      <Collapse open={showWheels}>
        <Box
          position="relative"
          maxWidth={{ _: "100vw", sm: "5000px" }}
          overflowX={{ _: "auto", sm: "visible" }}
          marginLeft="-1.5rem"
          paddingX="1.5rem"
          mt="2rem"
        >
          <Grid
            columns={wheelSet.values?.large
              ? { _: "repeat(50, 1fr)", sm: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }
              : { _: "repeat(50, 1fr)", sm: "repeat(4, 1fr)", xl: "repeat(6, 1fr)" }}
            rows="auto"
            gap="1rem"
          >
            {wheels.map((g, k) => (
              <Fragment key={k}>
                <Link type="simple" onClick={() => setActive(k)} display={{ _: "none", sm: "inline-flex" }} width="100%">
                  <GoboCard gobo={g} active={active === k} large={wheelSet.values?.large} />
                </Link>
                <Link
                  type="simple"
                  display={{ _: "inline-flex", sm: "none" }}
                  onClick={() => openLightbox(k)}
                  width="100%"
                >
                  <GoboCard gobo={g} active={active === k} large={wheelSet.values?.large} />
                </Link>
              </Fragment>
            ))}
          </Grid>
        </Box>
      </Collapse>
    </Box>
  );
};
