import { useXcoreUtils } from "@appiodev/xcore-client/xcore-ui";
import { Content } from "@appiodev/xcore-core";
import { Box, Flex, Typography } from "@xcorejs/ui";
import React, { Dispatch, FC } from "react";
import { useLocalizedRelation } from "utils/useLocalizedRelation";
import { ProductOverviewPage } from "xcore/types";
import { CustomLink, TagStyle, TextStyle } from ".";
import type { GridItem } from "pages/api/robe/product-grid";

type RightSectionProps = {
  otherTypes: Content[];
  gridSection1: ProductOverviewPage;
  gridSection2: ProductOverviewPage;
  setOpen: Dispatch<boolean>;
  newIconText: string;
};
const RightSection: FC<RightSectionProps> = ({ otherTypes, gridSection1, gridSection2, setOpen, newIconText }) => {
  const { stringify } = useXcoreUtils();

  const gridSection1Items = useLocalizedRelation("itemsRelation", gridSection1.values, [] as GridItem[]);
  const gridSection2Items = useLocalizedRelation("itemsRelation", gridSection2.values, [] as GridItem[]);

  return (
    <Box padding="40px 10px 40px 30px">
      {otherTypes.map(type => (
        <CustomLink key={type.id} display="block" content={type} setOpen={setOpen}>
          <Typography {...Heading} {...TextStyle} textTransform="uppercase">
            {stringify(type.title)}
          </Typography>
        </CustomLink>
      ))}

      <CustomLink display="block" content={gridSection1} marginTop="20px" setOpen={setOpen}>
        <Typography {...Heading} {...TextStyle} textTransform="uppercase">
          {stringify(gridSection1.title)}
        </Typography>
        <Box>
          {gridSection1Items.map(v => (
            <CustomLink display="block" key={v.id} content={v} setOpen={setOpen}>
              <Flex>
                <Typography
                  py="0.25rem"
                  fontWeight={600}
                  color="darkGray"
                  _hover={{ color: "black" }}
                  {...TextStyle}
                >
                  {stringify(v.title)}
                </Typography>
                {v.values?.textTag && <Typography pl="0.5rem" fontSize="1rem">{stringify(v.values.textTag)}</Typography>}
                {"newIcon" in v.values && v.values.newIcon && (
                  <Box mt="0.25rem" padding="0 0.5rem" {...TagStyle}>
                    {stringify(newIconText)}
                  </Box>
                )}
              </Flex>
            </CustomLink>
          ))}
        </Box>
      </CustomLink>

      <CustomLink display="block" content={gridSection2} marginTop="20px" setOpen={setOpen}>
        <Typography {...Heading} {...TextStyle} textTransform="uppercase">
          {stringify(gridSection2.title)}
        </Typography>
        <Box>
          {gridSection2Items.map(v => (
            <CustomLink position="relative" width="fit-content" display="block" key={v.id} content={v} setOpen={setOpen}>
              <Flex>
                <Typography
                  py="0.25rem"
                  fontWeight={600}
                  color="darkGray"
                  _hover={{ color: "black" }}
                  {...TextStyle}
                >
                  {stringify(v.title)}
                </Typography>
                {v.values?.textTag && <Typography pl="0.5rem" fontSize="1rem">{stringify(v.values.textTag)}</Typography>}
                {"newIcon" in v.values && v.values.newIcon && (
                  <Box mt="0.25rem" padding="0 0.5rem" {...TagStyle}>
                    {stringify(newIconText)}
                  </Box>
                )}
              </Flex>
            </CustomLink>
          ))}
        </Box>
      </CustomLink>
    </Box>
  );
};

export default RightSection;

const Heading = {
  fontWeight: 700,
  color: "black",
  padding: "3px 0"
};
