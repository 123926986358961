import { Link } from "@appiodev/xcore-client/xcore-ui";
import { Flex, Icon, Text, TextProps } from "@xcorejs/ui";
import DropIcon from "components/icons/16/drop.svg";
import useDropdown from "design-system/xcore/Dropdown";
import DropdownItem from "design-system/xcore/Dropdown/DropdownItem";
import { useLayout } from "xcore";

import { useRouter } from "next/router";
import { NavLinkStyle } from "./NavItem/NavItemLink";
import { useDomainSwitcher } from "./useDomainSwitcher";

const DomainSwitcher = () => {
  const { domain } = useLayout();
  const { locale } = useRouter();
  const { label, dropdownLinkProps } = useDomainSwitcher();
  const { OnClick } = useDropdown(
    {
      closeOnSecondClick: true,
      position: "absolute",
      locationY: "top",
      locationX: "left",
      _dropdownProps: {
        mx: "0.1rem",
        minWidth: "23rem"
      },
      _firstDropdownProps: {
        right: 0,
        mt: "0.2rem",
        top: "100%"
      }
    }
  );

  return (
    <Flex ml={{ _: "1rem", lg: "2rem" }} display={{ _: "none", md: "flex" }} height="100%" cursor="pointer">
      <OnClick
        props={{
          children: dropdownLinkProps.map(({ locale: l, ...p }, i) => (
            // query is added to the link - if resulted into 404 error after domain switch, user gets redirected onto unavailable locale page instead
            <Link key={i} {...p} display="flex" query="?mode=domainSwitch">
              <DropdownItem
                width="100%"
                rightNode={(
                  <Text {...LanguageStyle} {...p.domain === domain && { ...ActiveLanguageStyle }}>
                    {l.split("-")[0].toUpperCase()}
                  </Text>
                )}
                {...p.domain === domain && { ...ActiveLanguageStyle }}
              >
                {p.label}
              </DropdownItem>
            </Link>
          ))
        }}
      >
        {(isOpen) => (
          <Text {...NavLinkStyle} height="100%" userSelect="none">
            {label}{" / "}
            {locale?.split("-")[0].toUpperCase()}
            <Icon
              svg={<DropIcon />}
              fill="gray"
              ml={{ _: "1rem", lg: "2rem" }}
              _group={{
                _hover: {
                  _icon: { fill: "black" }
                }
              }}
              transition="transform 300ms"
              transform={isOpen ? "rotate(180deg)" : ""}
            />
          </Text>
        )}
      </OnClick>
    </Flex>
  );
};

export default DomainSwitcher;

const LanguageStyle: TextProps = {
  opacity: 0.5,
  color: "#5e6062",
  fontSize: "1.4rem",
  fontWeight: 600,
  lineHeight: "2.1rem",
  textTransform: "none"
};

export const ActiveLanguageStyle: TextProps = {
  color: "primary",
  opacity: 1,

  _hover: {
    color: "primary",
    bg: "#c7cace"
  }
};
