import { useLink } from "@appiodev/xcore-client/xcore-ui";
import { Button, Flex, Icon, Link } from "@xcorejs/ui";
import SmallerCloseIcon from "components/icons/16/close.svg";
import SearchIcon from "components/icons/24/search.svg";
import InputGroup from "design-system/xcore/Form/Input/InputGroup";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useLayout } from "xcore";
import { Tooltip } from "@mui/joy";

interface Props {
  open: boolean;
  close: () => unknown;
}

const SearchField: FC<Props> = ({ open, close }) => {
  const { general, header, stringify } = useLayout();
  const [value, setValue] = useState("");
  const inputElement = useRef<HTMLInputElement>(null);
  const searchElement = useRef<HTMLDivElement>(null);
  const [showTooltipOnError, setShowTooltipOnError] = useState(false);
  const { push } = useLink();

  useEffect(() => inputElement.current?.focus(), [open]);

  const closeSearch = (e: Event) => {
    const element = searchElement.current?.contains(e.target as Node);
    !element && open && close();
  };

  useEffect(() => {
    document.addEventListener("click", closeSearch);

    return () => {
      document.removeEventListener("click", closeSearch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    setShowTooltipOnError(false);
  }, [value]);

  const triggerNotEnoughCharsError = () => {
    setShowTooltipOnError(true);
    setTimeout(() => setShowTooltipOnError(false), 3000);
  };

  const submit = () => value.length > 2 ? push(header.search, { query: `?q=${value}` }) : triggerNotEnoughCharsError();

  return (
    <Tooltip
      title={stringify(general.values.tooShort)}
      open={showTooltipOnError}
      arrow
      disableHoverListener
      variant="solid"
      sx={{ marginTop: "-1.5rem !important", borderRadius: "0" }}
      size="lg"
      color="danger"
    >
      <Flex
        position="absolute"
        top={{ _: "6rem", md: 0 }}
        bottom={{ _: "-11rem", md: 0 }}
        left={{ _: 0, md: "17rem", xl: "22rem" }}
        right={0}
        backgroundColor="#fff"
        display={open ? "flex" : "none"}
        alignItems={{ _: "stretch", md: "center" }}
        padding={{ _: "2rem 1.5rem 3rem 1.5rem", md: 0 }}
        pointerEvents={open ? "all" : "none"}
        ref={searchElement}
      >
        <InputGroup
          _input={{
            placeholder: stringify(general.values.btnSearch),
            value,
            onChange: (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value),
            onKeyDown: e => e.key === "Enter" ? submit() : e.key === "Escape" && close(),
            ...{ ref: inputElement } as any
          }}
          leftElement={<Icon svg={<SearchIcon />} fill="#939598" display={{ _: "none", md: "inline-block" }} />}
          _leftElement={{ pl: { _: 0, md: "3rem" } }}
          rightElement={(
            <Link
              type="simple"
              onClick={value.length > 0 ? () => setValue("") : () => close()}
              display={{ _: "none", md: "inline-flex" }}
            >
              <Icon
                svg={<SmallerCloseIcon />}
                width="1.6rem"
                height="1.6rem"
                fill="#939598"
                fillHover="black"
                cursor="pointer"
              />
            </Link>
          )}
          _rightElement={{ pr: { _: 0, md: "3rem" } }}
          width="80%"
          borderRightWidth={0}
          _hover={{
            borderRightWidth: 0
          }}
          _focusWithin={{
            borderRightWidth: 0
          }}
          _focus={{
            borderRightWidth: 0
          }}
        />

        <Button
          justifyContent="center"
          width="20%"
          margin={0}
          onClick={submit}
        >
          {stringify(general.values.btnSearch)}
        </Button>
      </Flex>
    </Tooltip>
  );
};

export default SearchField;
