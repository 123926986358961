import { resolveLocale } from "@appiodev/xcore-client/xcore-ui";
import { useMemo } from "react";
import { useLayout } from "xcore";

type Locales = "En" | "De" | "Ru" | "EnEn" | "EnNA" | "Fr" | "Cs";

export const useLocalizedRelation = <T, TKey extends string>(
  field: TKey,
  fieldRecord: Partial<Record<`${TKey}${Locales}`, T>>,
  fallback: T
): T => {
  const { cms } = useLayout();

  return useMemo(() =>
    resolveLocaleField<T, TKey>(field, fieldRecord, fallback, cms.locales.content),
  [field, fieldRecord, fallback, cms.locales.content]);
};

export const localeRecord = {
  "en": "En",
  "de": "De",
  "ru": "Ru",
  "en-EN": "EnEN",
  "en-NA": "EnNA",
  "cs": "Cs",
  "fr": "Fr",
  "en-SEA": "EnSEA",
  "en-TEST": "EnTEST"
} as const;

const locales = Object.keys(localeRecord);

const mapFieldNames = <TKey extends string>(field: TKey) => locales.map(l => [l, `${field}${localeRecord[l as keyof typeof localeRecord] as Locales}`] as const);

export const resolveLocaleField = <T, TKey extends string>(
  field: TKey,
  fieldRecord: Partial<Record<`${TKey}${Locales}`, T>>,
  fallback: T,
  l: string[]
): T => resolveLocale(l)(
  Object.fromEntries(mapFieldNames(field).map(([ll, f]) => [ll, fieldRecord?.[f]]))
) ?? fallback;

export const MOBILE_DOMAIN_SWITCHER_LOCALES_ABBREVIATIONS = {
  "en": "EN GLB",
  "en-NA": "EN NA",
  "en-SEA": "EN SEA",
  "de": "DE GER",
  "fr": "FR FRA",
  "cs": "CS CZE",
  "ru": "RU RUS"
};
