import { useEffect, useLayoutEffect, useState } from "react";

export const useIsClient = () => {
  const [isClient, setIsClient] = useState(false);
  useSafeEffect(() => {
    setIsClient(true);
  }, []);
  return isClient;
};

const useSafeEffect = typeof window === "undefined" ? useEffect : useLayoutEffect;
