import css from "@styled-system/css";
import { XcoreTheme } from "design-system/xcore/theme";

import { useContext } from "react";
import { components, OptionProps, OptionTypeBase } from "react-select";
import styled, { ThemeContext } from "styled-components";
import * as system from "styled-system";
import { BoxProps, Box, TextProps, useTheme } from "@xcorejs/ui";
import { OptionType } from ".";

const PseudoSelectors = {
  inner: "& > div"
};

export type OptionSelectProps =
  {
    _inner?: BoxProps & TextProps;
    selected?: BoxProps & TextProps;
  } & BoxProps
  & TextProps;

const OptionStyle = styled(Box)<OptionSelectProps>`
  & > div {
    background: transparent;
    cursor: pointer;
    height: 100%;
    width: 100%; 
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    
    &:hover {
      background: transparent;
    }
    
    &:focus {
      outline: none;
    }
  }
  
  ${({ _inner }) => css({
    [PseudoSelectors.inner]: _inner
  })}
  
  ${system.typography}
`;

export const Option = (props: OptionProps<OptionType>) => {
  const {
    innerRef,
    innerProps,
    isSelected
  } = props;
  const theme = useTheme() as XcoreTheme;
  const optionProps = theme.select.option;
  const selectedOptionProps = theme.select.selectedOption;

  return (
    <OptionStyle
      ref={innerRef}
      {...optionProps as any}
      {...isSelected && selectedOptionProps}
      {...innerProps as any}
    >
      <components.Option {...props} />
    </OptionStyle>
  );
};
