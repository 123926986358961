import { Link } from "@appiodev/xcore-client/xcore-ui";
import { Relation } from "@appiodev/xcore-core";
import { Box, Container, Flex } from "@xcorejs/ui";
import { ProgressBarStyle } from "components/ProgressBar";
import DropdownItem from "design-system/xcore/Dropdown/DropdownItem";
import { FC, useEffect, useMemo, useState } from "react";
import { useLayout } from "xcore";
import { Product, ProductOverviewPage } from "xcore/types";
import { resolveLocaleField, useLocalizedRelation } from "../../utils/useLocalizedRelation";
import { Accessory } from "../../xcore/types";
import CategoryDisplayItem from "./CategoryDisplayItem";

interface Props {
  active: boolean;
  pop?: ProductOverviewPage;
  empty?: boolean;
  close: () => unknown;
  prevent: () => unknown;
}

const CategoryDisplay: FC<Props> = ({ active, prevent, pop, empty, close }) => {
  const { cms } = useLayout();
  const [fetchedPop, setFetchedPop] = useState<ProductOverviewPage>();

  useEffect(() => {
    if (pop) {
      setFetchedPop(pop);
      setActive(resolveLocaleField("itemsRelation", pop.values, undefined as any, cms.locales.content)?.[0].id);
    }
  }, [cms.locales.content, pop]);

  const items = useLocalizedRelation(
    "itemsRelation", fetchedPop?.values ?? {} as any, undefined as Relation<Product | Accessory | ProductOverviewPage>[] | undefined
  );

  const [activeItem, setActive] = useState(() => items?.[0]?.id);

  const item = useMemo(() => items?.find(c => c.id === activeItem)!, [items, activeItem]);

  if (empty) {
    return null;
  }

  return (
    <Box
      position="absolute"
      left={0}
      right={0}
      background="rgba(30, 30, 30, 0.5)"
      height="100%"
      opacity={active ? 1 : 0}
      pointerEvents={active ? null : "none"}
    >
      <Container
        px={0}
        py={0}
        background="#fff"
        maxHeight="100%"
        overflow="auto"
        minHeight="calc(100vh - 18rem)"
        onMouseEnter={prevent}
        onMouseLeave={close}
        boxShadow="0 0.5rem 1rem 0 rgba(30, 30, 30, 0.5)"
      >
        {pop ? (
          <Flex
            alignItems="flex-start"
            position="relative"
            bg="#fff"
            px="1.5rem"
            py="3rem"
            height="100%"
            zIndex={1004}
          >
            <Flex flexDirection="column">
              {items?.map(p => (
                <Link key={p.id} content={p}>
                  <DropdownItem
                    width="30rem"
                    onMouseEnter={() => setActive(p.id)}
                    {...p.id === activeItem && { bg: "lightGray", fontWeight: 600, color: "black" }}
                  >
                    {p.title}
                  </DropdownItem>
                </Link>
              ))}
            </Flex>
            {item && <CategoryDisplayItem item={item} />}
          </Flex>
        ) : (
          <Flex position="relative" width="100%" flexDirection="column">
            <Box
              width="100%"
              height="0.2rem"
              zIndex={1006}
            >
              <ProgressBarStyle
                bg="primary"
                height="100%"
                animation="load 1s forwards"
              />
            </Box>
          </Flex>
        )}
      </Container>
    </Box>
  );
};

export default CategoryDisplay;
