/* eslint-disable quote-props */
import { usePageContent } from "@appiodev/xcore-client/xcore-ui";
import { News } from "xcore/types";
import { robeGeneralInfo } from "./useProductGoogleStructuredData";

export type NewsGoogleStructuredDataType = {
  "@context": "https://schema.org";
  "@type": "Article";
  headline: string;
  author: {
    "@type": "Organization";
    name: string;
    image: string;
    url: string;
    address: {
      "@type": "PostalAddress";
      streetAddress: string;
      postalCode: string;
      addressLocality: string;
      addressCountry: string;
    };
    email: string;
    telephone: string;
  };
  articleBody: string;
  image: string | string[];
  datePublished: string;
  dateModified: string;
};

export const useNewsGoogleStructuredData = () => {
  const [news, { stringify, file, richtextToString }] = usePageContent<News>();
  const { values } = news;

  const newsGoogleStructuredData: NewsGoogleStructuredDataType = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: `${stringify(values.title)}`,
    author: robeGeneralInfo,
    articleBody: richtextToString(values.content) ?? "",
    image: values.images ? values.images.map(image => file(image)) : "",
    datePublished: `${stringify(news.publishedAt)}`,
    dateModified: `${stringify(news.updatedAt)}`
  };

  return newsGoogleStructuredData;
};

export const isNewsGoogleStructuredDataType = (data: any): data is NewsGoogleStructuredDataType => {
  return (
    data &&
    data["@context"] === "https://schema.org" &&
    data["@type"] === "Article" &&
    typeof data.headline === "string" &&
    typeof data.author === "object" &&
    data.author["@type"] === "Organization" &&
    typeof data.author.name === "string" &&
    typeof data.author.image === "string" &&
    typeof data.author.url === "string" &&
    typeof data.author.address === "object" &&
    data.author.address["@type"] === "PostalAddress" &&
    typeof data.author.address.streetAddress === "string" &&
    typeof data.author.address.postalCode === "string" &&
    typeof data.author.address.addressLocality === "string" &&
    typeof data.author.address.addressCountry === "string" &&
    typeof data.author.email === "string" &&
    typeof data.author.telephone === "string" &&
    typeof data.articleBody === "string" &&
    ((typeof data.image === "string") || (Array.isArray(data.image) && data.image.every(img => typeof img === "string"))) &&
    typeof data.datePublished === "string" &&
    typeof data.dateModified === "string"
  );
};
