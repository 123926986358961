import { Link, usePageContent, useXcoreQuery } from "@appiodev/xcore-client/xcore-ui";
import { Flex, Icon, Text } from "@xcorejs/ui";
import { useRouter } from "next/router";
import { FC, useMemo } from "react";
import { Product } from "xcore/types";
import ArrowDown from "components/icons/8/arrow-down.svg";
import { useLayout } from "xcore";
import { only } from "utils/only";

export const BackToProduct: FC = () => {
  const { query } = useRouter();
  const [, { stringify }] = usePageContent<Product>();
  const { general } = useLayout();

  const backToLabels = useMemo(() => ({
    product: general.values.backToProduct,
    news: general.values.backToNews,
    innovation: general.values.backToInnovation,
    _: general.values.backToPage
  }), [general.values.backToInnovation, general.values.backToNews, general.values.backToPage, general.values.backToProduct]);

  const { data } = useXcoreQuery(cms => query?.backto ? cms.content.single(only(query.backto)) : null);

  if (!data) return null;

  return (
    <Link content={data.content}>
      <Flex alignItems="center" title={stringify(data.content.title)}>
        <Icon
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="16px"
          p="8px"
          width="32px"
          height="32px"
          textAlign="center"
          bg="primary"
          fill="white"
          style={{ transform: "rotate(90deg)" }}
          mr="10px"
        >
          <ArrowDown />
        </Icon>
        <Text color="#c7cace" whiteSpace="nowrap">
          {stringify(backToLabels[data.content.type as keyof typeof backToLabels] ?? backToLabels._)}
        </Text>
      </Flex>
    </Link>
  );
};

export default BackToProduct;
