import { Relation } from "@appiodev/xcore-core";
import { Button, Flex, Img, Stack, Strong, Typography, Link as UILink } from "@xcorejs/ui";
import { Link } from "@appiodev/xcore-client/xcore-ui";
import { Accessory, Product, ProductOverviewPage } from "xcore/types";
import SpecificationBox from "components/product/SpecificationBox";
import { useLayout } from "xcore";
import InnovationBox from "components/product/InnovationBox";
import { useEffect, useState } from "react";
import RobeLoader from "components/RobeLoader";

type CategoryItem = {
  item: Relation<ProductOverviewPage | Product | Accessory>;
};

const CategoryDisplayItem = ({ item }: CategoryItem) => {
  const { stringify, file, general, richtextToString, value } = useLayout();

  const [loadedImages, setLoadedImages] = useState<number[]>([]); // store downloaded images to show them immediately
  const [isImageLoading, setIsImageLoading] = useState<boolean>(loadedImages.some(img => img === item.id));
  const [showLoading, setShowLoading] = useState<boolean>(false); // Shows debounced loading if image is still being downloaded
  const timeUntilShowLoading = 300; // in ms

  useEffect(() => {
    setIsImageLoading(!loadedImages.some(img => img === item.id)); // Reset image loading state on item change

    if (isImageLoading) { // Show loading with debounce
      const timeout = setTimeout(() => setShowLoading(true), timeUntilShowLoading);

      return () => clearTimeout(timeout);
    }

    setShowLoading(false); // Do not show loading if image is loaded
  }, [isImageLoading, item, loadedImages]);

  const handleImageLoad = () => {
    if (!loadedImages.some(img => img === item.id)) {
      setLoadedImages((prevImages) => [...prevImages, item.id]);
    }
    setIsImageLoading(false);
    setShowLoading(false); // Hide loading once the image is loaded
  };

  return (
    <Flex>
      <Flex flex={{ _: "0 0 31.5rem", lg: "0 0 45rem" }} maxWidth={{ _: "31.5rem", sm: "45rem" }} mr="3rem">
        <Link content={item}>
          <UILink as="span" display="flex" alignItems="flex-start" ml="1rem">
            {/* Loading logic - image has to be in DOM to fire its onLoad function, so the components have display logic instead of using conditional rendering */}
            <RobeLoader
              display={showLoading ? "flex" : "none"}
              width="440px"
              ml="1rem"
              maxWidth="100%"
              height="470px"
            />
            <Img
              display={showLoading ? "none" : "flex"}
              width="100%"
              mx="auto"
              maxWidth="100%"
              height="auto"
              loading="lazy"
              src={"images" in item.values ? file(item.values.images?.[0], { width: 452, height: 485 }) : undefined}
              srcSet={"images" in item.values ? `${file(item.values.images[0], { width: 452, height: 482 })} 1x, ${file(item.values.images[0], { width: 452, height: 485, enlargement: 2 })} 2x` : undefined}
              alt={"images" in item.values ? stringify(item.values.name) : ""}
              onLoad={handleImageLoad}
            />
          </UILink>
        </Link>

      </Flex>
      <Flex flexDirection="column" height="100%">
        <Typography variant="h3">
          {"name" in item.values && item.values.name}
        </Typography>
        <Stack mt="2rem" direction="column" gap="1rem" px={{ _: "1.5rem", sm: "0" }}>
          {"paramsOrFeatures" in item.values && item.values.paramsOrFeatures?.map((p, i) => (
            <SpecificationBox
              key={i}
              name={stringify(p.type!.title)}
              description={richtextToString(p.description)}
              icon={file(p.type!.values.image)!}
            />
          ))}
        </Stack>

        {"innovations" in item.values && item.values.innovations && (
          <>
            <Strong display="block" mt="2rem" mb="1rem" lineHeight="2rem">Innovations</Strong>

            <Flex flexWrap="wrap" mt="-1rem">
              {item.values.innovations?.map(i => (
                <InnovationBox
                  key={value(i.id)}
                  innovation={i}
                  mt="1rem"
                  mr="1rem"
                  openAs="link"
                />
              ))}
            </Flex>
          </>
        )}

        <Link content={item}>
          <Button as="div" alignSelf="flex-start" mt="3rem" whiteSpace="nowrap">
            {stringify(general.values.productDetailBtn)}
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
};

export default CategoryDisplayItem;
