import { Box, Flex, FlexProps, Icon, LinkProps, Typography } from "@xcorejs/ui";

import { FC } from "react";
import styled from "styled-components";
import DropIcon from "components/icons/16/drop.svg";

type RobeProductDetailHeadingProps =
  {
    active?: boolean;
    fill?: string;
    activeFill?: string;
    _heading?: {
      color?: string;
    };
    disableCollapsable?: boolean;
  }
  & LinkProps
  & FlexProps
;

const RobeProductDetailHeadingStyle = styled(Flex)<RobeProductDetailHeadingProps>`
  @keyframes pulse {
    0% {
      transform: translateY(0rem);
    }

    50% {
      transform: translateY(0.5rem);
    }

    100% {
      transform: translateY(0rem);
    }
  }
`;

const RobeProductDetailHeading: FC<RobeProductDetailHeadingProps> = (
  {
    active,
    _heading,
    fill,
    activeFill,
    children,
    disableCollapsable,
    ...props
  }) => {
  const filled = typeof fill !== "undefined" ? fill : "gray";
  const activeFilled = typeof activeFill !== "undefined" ? activeFill : "robe";
  const iconFill = active ? activeFilled : filled;
  const iconRotate = active ? "rotate(180deg)" : "rotate(0deg)";

  return (
    <RobeProductDetailHeadingStyle
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      position="relative"
      transition="margin 300ms"
      {...props}
    >
      <Typography variant="h2" _after={{ content: '"."', color: "robe" }} {..._heading}>
        {children}
      </Typography>
      {!disableCollapsable && (
        <Box
          animation="pulse 2s infinite"
          position={{ _: "absolute", sm: "relative" }}
          right={{ _: ".5rem", sm: 0 }}
          top={{ _: "2rem", sm: 0 }}
          ml={{ _: 0, sm: "2rem" }}
        >
          <Icon
            svg={<DropIcon />}
            fill={iconFill}
            transform={iconRotate}
            transition="transform 300ms"
          />
        </Box>
      )}
    </RobeProductDetailHeadingStyle>
  );
};

export default RobeProductDetailHeading;
