import { colors, lightColorTheme } from "@xcorejs/ui";

export const colorsTheme = colors(
  lightColorTheme, {
    primary: "#e00034",
    text: "#1e1e1e",
    background: "#fff",
    robe: "#e00034",
    gray: "#939598",
    black: "#1e1e1e",
    blackRGB: {
      o10: "rgba(30, 30, 30, 1)",
      o9: "rgba(30, 30, 30, 0.9)"
    },
    white: "#fff",
    lightGray: "#c7cace",
    darkGray: "#5e6062",
    solidWhite: "#dee1e5",
    ipCertified: "#00a3da"
  }
);
