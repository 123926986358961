import { FC, useEffect, useState } from "react";
import { Box, BoxProps } from "@xcorejs/ui";
import styled from "styled-components";
import { Router } from "next/router";

type ProgressBarProps = {
  completed: boolean;
  _progress?: BoxProps;
} & BoxProps;

const Progress: FC = () => {
  const [state, set] = useState<"loading" | "finishing" | "done">("done");

  useEffect(() => {
    Router.events.on("routeChangeStart", () => set("loading"));

    Router.events.on("routeChangeComplete", () => {
      set("finishing");
      setTimeout(() => set("done"), 500);
    });
  }, []);

  return (
    state === "done" ? null : <ProgressBar position="fixed" top={0} completed={state === "finishing"} />
  );
};

export default Progress;

const ProgressBar: FC<ProgressBarProps> = (
  {
    completed,
    _progress,
    ...props
  }
) => {
  "ok";
  return (
    <Box
      width="100%"
      height="0.2rem"
      bg="rgba(216, 216, 216, 1)"
      zIndex={1006}
      {...props}
    >
      <ProgressBarStyle
        bg="primary"
        height="100%"
        animation={completed ? "loadFull 0.4s forwards" : "load 1s forwards"}
        {..._progress}
      />
    </Box>
  );
};

export const ProgressBarStyle = styled(Box)`
  @keyframes load {
    0% { width: 0; }
    100% { width: 60%; }
  }
  
  @keyframes loadFull {
    0% { width: 60%; }
    100% { width: 100%; display: none }
  }
`;
