import { Box, BoxProps, Collapse, useTheme } from "@xcorejs/ui";
import { XcoreTheme } from "design-system/xcore/theme";
import { FC, Key, useContext } from "react";
import { ThemeContext } from "styled-components";

import { AccordionContext, AccordionItemContext, isExpanded } from "./data";

const AccordionPanel: FC<BoxProps> = ({ ...box }) => {
  const { expanded } = useContext(AccordionContext);
  const { index, expanded: ex } = useContext(AccordionItemContext);
  const {
    accordion: { panel }
  } = useTheme() as XcoreTheme;

  return (
    <Collapse open={isExpanded(ex, expanded, index)} position="relative">
      <Box {...panel} {...box} />
    </Collapse>
  );
};
export default AccordionPanel;
