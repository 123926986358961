import "utils/polyfills";

import { useRequestContext, XcoreClientProvider, XcoreProps } from "@appiodev/xcore-client/xcore-ui";
import { Content } from "@appiodev/xcore-core";
import { ModalProvider, XcoreProvider } from "@xcorejs/ui";
import { DefaultSubheader } from "components/Layout/Subheader/DefaultSubheader";
import Progress from "components/ProgressBar";
import { GlobalStyle } from "design-system/GlobalStyle";
import { theme } from "design-system/theme";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import TagManager from "react-gtm-module";
import { SWRConfig, useSWRConfig } from "swr";
import { LayoutContent, RobePage } from "xcore";
import * as Sentry from "@sentry/nextjs";
import "react-image-gallery/styles/css/image-gallery.css";
import { AppProps as NextAppProps } from "next/app";

interface AppProps extends Omit<NextAppProps, "Component"> {
  pageProps: XcoreProps<Content, Content[], Content[], LayoutContent>;
  Component: FC<XcoreProps<Content, Content[], Content[], LayoutContent>> & RobePage;
}

const App = ({ Component, pageProps }: AppProps) => {
  const { pathname } = useRouter();

  useEffect(() => {
    pageProps._xcoreProps && pageProps.config?.currentEnv !== "prod" && typeof window !== "undefined" &&
      console.info(pageProps, pageProps._xcoreProps, pageProps.content[0]?.id);
  }, [pageProps]);

  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-5B4KCS6" });
  }, []);

  useEffect(() => window.scrollTo(0, 0), [pathname]);

  let swrConfig;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    swrConfig = useMemo(() => ({
      fetcher: swrFetcher(typeof window === "undefined" ? pageProps.config?.apiUrl : "//" + window.location.host)
    }), [pageProps.config?.apiUrl]);
  } catch (e) {
    Sentry.captureEvent(e as Sentry.Event, { data: pageProps });
    throw e;
  }

  const pagePropsMemoized = useMemo(() => pageProps.config?.etc?.robe?.forceLocalApiUrl ? pageProps : {
    ...pageProps,
    config: { ...pageProps.config, apiUrl: typeof window === "undefined" ? pageProps.config?.apiUrl : "//" + window.location.host },
    ctx: { ...pageProps.ctx, apiUrl: typeof window === "undefined" ? pageProps.config?.apiUrl : "//" + window.location.host }
  }, [pageProps]);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no, viewport-fit=cover"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ROBE Lighting" />
        <meta property="fb:app_id" content="598422404039184" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Robelighting" />
        <meta name="theme-color" content="#ee0037" />
      </Head>
      <XcoreProvider theme={theme}>
        <GlobalStyle />
        {!pathname.startsWith("/pdf") && <Progress />}
        <SWRConfig value={swrConfig}>
          {pageProps._xcoreProps
            ? (
              <XcoreClientProvider {...pagePropsMemoized}>
                <ModalProvider>
                  {Component.Layout ? (
                    <Component.Layout>
                      {Component.Subheader
                        ? <Component.Subheader />
                        : <DefaultSubheader />}
                      <Component {...pageProps} />
                    </Component.Layout>
                  ) : <Component {...pageProps} />}
                </ModalProvider>
              </XcoreClientProvider>
            ) : <Component {...pageProps} />}
        </SWRConfig>
      </XcoreProvider>
    </>
  );
};

export default App;

export const swrFetcher = (apiUrl: string) => (
  input: string,
  init?: Parameters<typeof fetch>[1],
  parseRes?: boolean
) => {
  return fetch(formatUrl(apiUrl, input), {
    ...init,
    mode: "cors",
    credentials: "include",
    headers: {
      "mode": "cors",
      "credentials": "include",
      "content-type": "application/json"
    }
  }).then(res => parseRes ?? true ? res.json() : res);
};

const formatUrl = (apiUrl: string, s: string) => {
  s = s.startsWith("/") ? s.slice(1) : s;

  if (s.startsWith("content") && !s.includes("published")) {
    s = s.includes("?") ? `${s}&published=true` : `${s}?published=true`;
  }

  return `${apiUrl}/${s}`;
};

export const useRevalidateCache = () => {
  const { swr: swr2 } = useRequestContext();
  const swr = useSWRConfig();

  return useCallback(() => {
    // @ts-ignore
    [...swr.cache.keys()].forEach(k => swr.mutate(k));
    // @ts-ignore
    [...swr2.cache.keys()].forEach(k => swr2.mutate(k));
  }, [swr, swr2]);
};
