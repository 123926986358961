
import { Modal } from "@xcorejs/ui";
import { FC } from "react";
import UserLoginComponent from "components/UserLoginComponent";

const UserLoginModal: FC = () => {
  return (
    <Modal size="lg" _overlay={{ zIndex: 5 }}>
      <UserLoginComponent />
    </Modal>
  );
};

export default UserLoginModal;
