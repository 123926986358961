import { Card, CardProps, Img, Text, Flex } from "@xcorejs/ui";
import { FC, useEffect, useState } from "react";
import { useLayout } from "xcore";

import { Product, Accessory, ProductOverviewPage, Products } from "xcore/types";
import ProductTag from "components/product/ProductTag";
import { useXcoreQuery } from "@appiodev/xcore-client/xcore-ui";
import { Skeleton } from "@mui/joy";

export type ProductCardProps = (
  {
    product:
    | Product<"images" | "discontinuedGlob" | "discontinuedReg" | "name" | "newIcon" | "ipCertifiedIcon" | "textTag">
    | Accessory<"images" | "discontinuedGlob" | "discontinuedReg" | "name">;
  } | {
    pop: ProductOverviewPage<"thumbnailTitle" | "title" | "thumbImage" | "thumbImageProduct">;
  }
) & CardProps;

const ProductCard: FC<ProductCardProps> = (props) => {
  const { general, stringify, file, value, localize } = useLayout();
  const { data } = useXcoreQuery(cms => cms.content.list<Products>("products", {
    fields: ["newIconText"]
  }));
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [showLoading, setShowLoading] = useState<boolean>(false); // Shows debounced loading if image is still being downloaded
  const timeUntilShowLoading = 400; // in ms

  const productOrPop = "product" in props ? props.product : props.pop;
  const values = productOrPop.values;

  const discontinued = "images" in values ? value(values.discontinuedGlob) || localize(value(values.discontinuedReg)) : null;
  const newIcon = "newIcon" in values && values.newIcon;
  const ipCertifiedIcon = "ipCertifiedIcon" in values && values.ipCertifiedIcon;
  const textTag = "textTag" in values && stringify(values.textTag);
  const text = !("images" in values) ? stringify(values.thumbnailTitle) || stringify(values.title) : null;

  useEffect(() => {
    if (isImageLoading) { // Show loading with debounce
      const timeout = setTimeout(() => setShowLoading(true), timeUntilShowLoading);

      return () => clearTimeout(timeout);
    }

    setShowLoading(false); // Do not show loading if image is loaded
  }, [isImageLoading]);

  return (
    <Skeleton loading={showLoading} variant="rectangular" height={320}>
      <Card
        variant="outline"
        width="100%"
        maxWidth="100%"
        body={(
          <>
            <Img
              src={"images" in values
                ? file(values.images[0], { width: 242, height: 260 })
                : file(values.thumbImageProduct) ?? file(values.thumbImage)}
              srcSet={"images" in values ? `${file(values.images[0], { width: 242, height: 260 })} 1x, ${file(values.images[0], { width: 242, height: 260, enlargement: 2 })} 2x ` : ""}
              alt={"images" in values ? stringify(values.name) : text ?? ""}
              maxWidth="100%"
              loading="lazy"
              onLoad={() => setIsImageLoading(false)}
            />
            <Text variant="strong" mt="2rem" textAlign="center">{"images" in values ? stringify(values.name) : text}</Text>

            <Flex
              justifyContent="space-between"
              width="100%"
              position="absolute"
              top="0"
            >
              <Flex>
                {ipCertifiedIcon && <ProductTag maxHeight="4rem" variant="ipCertified" marginRight="auto">IP65</ProductTag>}
              </Flex>
              <Flex flexDirection="column">
                {newIcon && <ProductTag variant="new" centered>{stringify(data?.contents[0].values.newIconText)}</ProductTag>}
                {discontinued && <ProductTag variant="gray" centered>{stringify(general.values.discontinued)}</ProductTag>}
                {textTag && <ProductTag variant="gray" centered>{textTag}</ProductTag>}
              </Flex>
            </Flex>
          </>
        )}
        _body={{ flexDirection: "column", alignItems: "center", height: "100%", justifyContent: "space-between" }}
        _hover={{
          border: "2px solid rgba(69, 86, 99, 0.75)"
        }}
        transition="border 300ms"
        {...props}
      />
    </Skeleton>
  );
};

export default ProductCard;

export const SkeletonProductCard = () =>
  <Skeleton variant="rectangular" height={320} />
;
