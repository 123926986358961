import { Link } from "@appiodev/xcore-client/xcore-ui";
import { FC, useRef } from "react";
import { Link as UILink } from "@xcorejs/ui";
import { Content } from "@appiodev/xcore-core";

interface Props {
  content: Content;
  set: () => unknown;
}

const SubheaderItem: FC<Props> = ({ content, set, children, ...props }) => {
  const state = useRef(false);

  const p = {
    variant: "simple",
    ...DefaultSubheaderNavLinkStyle,
    whiteSpace: "nowrap",
    position: "relative",

    onMouseEnter: () => {
      state.current = true;
      setTimeout(() => state.current && set(), 200);
    },
    onMouseLeave: () => { state.current = false },
    ...props
  } as const;

  return content ? (
    <Link content={content} {...p}>
      {children}
    </Link>
  ) : (
    <UILink {...p}>
      {children}
    </UILink>
  );
};

export default SubheaderItem;

export const DefaultSubheaderNavLinkStyle = {
  display: "flex",
  alignItems: "center",
  fontSize: { _: "1.2rem", lg: "1.4rem" },
  padding: { _: "0 1rem", md: "0 1.2rem", xl: "0 2rem" },
  height: "5rem",
  fontWeight: 600,
  lineHeight: "2.1rem",
  color: "lightGray",
  transition: "color 300ms, background 300ms",
  textAlign: "center" as const,
  _hover: {
    background: "white",
    color: "#1e1e1e",
    zIndex: 10
  }
};

export const DefaultSubheaderActiveNavLinkStyle = {
  bg: "#fff",
  color: "#1e1e1e"
};
