import { Link } from "@appiodev/xcore-client/xcore-ui";
import { AspectRatio, Box, Card, ExtendedIconProps, Flex, Img, Paragraph, Strong, Tag, Typography, useModal } from "@xcorejs/ui";
import ExternalSVG from "components/ExternalSVG";
import InnovationModal from "components/InnovationModal";
import Popover from "design-system/robe/Popover";
import { FC } from "react";
import { useLayout } from "xcore";
import { Innovation } from "xcore/types";
import { Skeleton } from "@mui/joy";

type InnovationBoxProps = {
  innovation: Innovation;
  openAs: "modal" | "link";
  popover?: boolean;
  backto?: number;
  expandUp?: boolean;
} & ExtendedIconProps;

type InnovationPopoverProps = {
  openAs: "modal" | "link";
  expandUp: boolean;
  intro: string;
  name: string;
  innovation: Innovation;
};

const InnovationBox: FC<InnovationBoxProps> = ({
  innovation,
  openAs,
  popover,
  backto,
  expandUp,
  ...props
}) => {
  const { file, stringify } = useLayout();

  const renderBox = () => (
    <>
      <Card
        v="outline"
        cursor="pointer"
        padding="0"
        transition="box-shadow 300ms"
        border="2px solid transparent"
        borderRadius="2px"
        boxShadow="0 0.5rem 1rem 0 rgba(30, 30, 30, 0.2)"
        _body={{
          padding: 0
        }}
        _hover={{
          boxShadow: "0 .5rem 1rem 0 rgba(224, 0, 52, 0.5)",
          borderRadius: "2px"
        }}
        {...props}
      >
        {innovation.values.icon
          ? (
            <ExternalSVG
              src={file(innovation.values.icon, { width: 64, height: 64 })}
              width="6.4rem"
              height="6.4rem"
              loadingFallback={<SkeletonInnovationBox />}
            />
          )
          : (
            <Tag
              width="6.4rem"
              height="6.4rem"
              alignItems="center"
              justifyContent="center"
              bg="white"
              borderColor="transparent"
              color="black"
            >
              {innovation.values.shortcut}
            </Tag>
          )}
      </Card>
    </>
  );

  return (
    popover ? (
      <InnovationPopover
        openAs={openAs}
        expandUp={expandUp ?? false}
        intro={stringify(innovation.values.intro) ?? ""}
        name={stringify(innovation.values.name)}
        innovation={innovation}
      >
        {openAs === "link" ? (
          <Link content={innovation} {...backto && { query: `?backto=${backto}` }}>
            {renderBox() ?? ""}
          </Link>
        ) : renderBox()}
      </InnovationPopover>
    ) : openAs === "link" ? (
      <Link content={innovation} {...backto && { query: `?backto=${backto}` }}>
        {renderBox()}
      </Link>
    ) : renderBox()

  );
};

export default InnovationBox;

const InnovationPopover: FC<InnovationPopoverProps> =
({ openAs, children, expandUp, intro, name, innovation }) => {
  const [open] = useModal(InnovationModal);

  if (innovation && intro && name) {
    return openAs === "link"
      ? (
        <Link content={innovation}>
          <InnovationCard innovation={innovation} expandUp={expandUp} intro={intro} name={name}>{children}</InnovationCard>
        </Link>
      )
      : (
        <Box onClick={() => openAs === "modal" && open({ innovation })}>
          <InnovationCard innovation={innovation} expandUp={expandUp} intro={intro} name={name}>{children}</InnovationCard>
        </Box>
      );
  } else {
    return (
      <Popover
        popover={(
          <Card
            width="41rem"
            maxWidth="100%"
            body={<Typography>Loading...</Typography>}
          />
        )}
        _popover={{
          marginLeft: "2.5rem",
          pt: "0.6rem",
          transform: "translateX(-100%)"
        }}
      >
        {children}
      </Popover>
    );
  }
};

const InnovationCard: FC<{ innovation: Innovation; expandUp: boolean; intro: string; name: string }> = ({
  innovation: { values },
  expandUp,
  children,
  intro,
  name
}) => {
  const { file } = useLayout();

  return (
    <Popover
      popover={(
        <Card
          v="outline"
          width="41rem"
          maxWidth="100%"
          media={values.image && (
            <AspectRatio ratio={9 / 16}>
              <Img
                src={file(values.image, { width: 410, height: 230 })}
                srcSet={`${file(values.image, { width: 410, height: 230 })} 1x, ${file(values.image, { width: 410, height: 230, enlargement: 2 })} 2x`}
                alt={name}
                loading="lazy"
                width="100%"
              />
            </AspectRatio>
          )}
          _media={{
            maxWidth: "100%"
          }}
          body={(
            <Flex width="100%" flexDirection="column" alignItems="center">
              <Strong>{name}</Strong>
              <Paragraph textAlign="center" mt="1rem">
                {intro}
              </Paragraph>
            </Flex>
          )}
          _body={{
            p: "3rem",
            pt: "2rem"
          }}
          cursor="pointer"
        />
      )}
      _popover={{
        marginLeft: "2.5rem",
        pt: "0.6rem",
        transform: {
          _: expandUp ? "translateX(50% -106%)" : "translateX(50%)",
          md: expandUp ? "translateX(-100%) translateY(-106%)" : "translateX(-100%)"
        },
        display: { _: "none", md: "block" }
      }}
    >
      {children}
    </Popover>
  );
};

export const SkeletonInnovationBox = () => <Skeleton variant="rectangular" width={64} height={64} />;
