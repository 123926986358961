import { Box, FlexProps, Flex, Icon, Typography, LinkProps } from "@xcorejs/ui";

import { FC } from "react";
import styled from "styled-components";

import DropIcon from "components/icons/16/drop.svg";

type RobeGobosHeadingProps =
  {
    active?: boolean;
  }
  & LinkProps
  & FlexProps
;

const RobeGobosHeadingStyle = styled(Flex)<RobeGobosHeadingProps>``;

const RobeGobosHeading: FC<RobeGobosHeadingProps> = (
  {
    active,
    children,
    ...props
  }) => {
  const iconFill = active ? "robe" : "gray";
  const iconRotate = active ? "rotate(180deg)" : "rotate(0deg)";
  const displayShadow = active ? "block" : "none";

  return (
    <RobeGobosHeadingStyle cursor="pointer" position="relative" maxWidth="calc(100vw - 3rem)" {...props}>
      <Typography variant="h3">
        {children}
      </Typography>
      <Icon
        svg={<DropIcon />}
        ml={{ _: 0, sm: "2rem" }}
        fill={iconFill}
        transform={iconRotate}
        position={{ _: "absolute", sm: "relative" }}
        right={0}
        top=".3rem"
      />
      <Box
        display={{ _: displayShadow, sm: "none" }}
        position="absolute"
        top="4.5rem"
        right="-1.5rem"
        height="13.5rem"
        width="1.5rem"
        background="linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,.5) 45%, rgba(255,255,255,0) 100%)"
        zIndex={1000}
      />
    </RobeGobosHeadingStyle>
  );
};

export default RobeGobosHeading;
