
import styled, { ThemeContext } from "styled-components";
import { components } from "react-select";
import * as system from "styled-system";
import { useContext } from "react";
import { XcoreTheme } from "design-system/xcore/theme";
import { BoxProps, Box, TextProps, useTheme } from "@xcorejs/ui";
import { InputProps } from "design-system/xcore/Form/Input";

export type InputSelectProps =
  & BoxProps
  & Omit<TextProps, "as">
  & InputProps;

const InputStyle = styled(Box)<InputSelectProps>`
  & * {
    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    font-family: inherit !important;
    color: inherit !important;
  } 
  ${system.typography};
`;

export const Input = (props: any) => {
  const theme = useTheme() as XcoreTheme;
  const inputProps = theme.select.input;

  return (
    <InputStyle {...inputProps}>
      <components.Input {...props} />
    </InputStyle>
  );
};
