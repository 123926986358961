import { Modal } from "@xcorejs/ui";
import { FC } from "react";

import LostPasswordComponent from "components/LostPasswordComponent";

const LostPasswordModal: FC = () => {
  return (
    <Modal size="lg" _overlay={{ zIndex: 5 }}>
      <LostPasswordComponent />
    </Modal>
  );
};

export default LostPasswordModal;
