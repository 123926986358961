import { SFMedia } from "@robe/admin/data/types";
import { ParsedUrlQuery } from "querystring";
import { makeQuery } from "@appiodev/xcore-utils";
import { only } from "utils/only";

export const parsePagination = (header: string) => {
  const [_, start, end, length] = [...header.matchAll(/items (\d+)-(\d+)\/(\d+)/g)][0] as string[];
  return { start: Number(start), end: Number(end), length: Number(length) };
};

export const getQuery = ({ q, product, category, page }: ParsedUrlQuery) =>
  "?" + makeQuery("term", "contents", "categories", "page")(only(q), product, category, only(page));

export const getFileSizeString = (size: number) => {
  const ext = ["B", "KB", "MB", "GB", "TB"];
  let i = 0;
  // eslint-disable-next-line no-cond-assign
  while ((size = size / 1024) > 1) i++;
  return `${(size * 1024).toFixed(1)} ${ext[i]}`;
};

export const getExt = (path: string) =>
  (path.match(/.[^.]+$/)![0] ?? ".Unknown").startsWith(".") ? (path.match(/.[^.]+$/)![0] ?? ".Unknown").slice(1) : "";

export const duplicationDestroyer = (arr: SFMedia[]): SFMedia[] =>
  arr
    .map(JSON.stringify as any)
    .reverse()
    .filter((item, i, a) => a.indexOf(item, i + 1) === -1)
    .reverse()
    .map(JSON.parse as any);
