import { Box, Button, Icon, useTheme } from "@xcorejs/ui";

import NextIcon from "components/icons/16/next.svg";
import PrevIcon from "components/icons/16/prev.svg";
import { XcoreTheme } from "design-system/xcore/theme";

const ButtonGroup = ({ ...props }) => {
  const { previous, next, _arrow, _group, _icon } = props;
  const theme = useTheme() as XcoreTheme;
  const arrowProps = theme.carousel.arrow;

  return (
    <Box
      position="absolute"
      width={{ _: "calc(100% + 4rem)", md: "calc(100% + 6rem)" }}
      ml={{ _: "-2rem", md: "-3rem" }}
      top="50%"
      transform="translateY(-50%)"
      {..._group}
    >
      <Button
        {...arrowProps}
        {..._arrow}
        left={0}
        onClick={() => previous()}
      >
        <Icon svg={<PrevIcon />} fill="robe" {..._icon} />
      </Button>

      <Button
        {...arrowProps}
        {..._arrow}
        right={0}
        onClick={() => next()}
      >
        <Icon svg={<NextIcon />} fill="robe" {..._icon} />
      </Button>
    </Box>
  );
};

export default ButtonGroup;
