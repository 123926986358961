import { Localized } from "@appiodev/xcore-core";
import { Flex, Typography } from "@xcorejs/ui";
import ExternalSVG from "components/ExternalSVG";
import React, { FC, ReactNode } from "react";
import { useLayout } from "xcore";
import { TextStyle } from ".";

type IconsLegendsProps = {
  legends: {
    type: string;
    image: ReactNode;
    title: Localized<string>;
    preserveColor: boolean | undefined;
  }[] | undefined;
  title: string;
};
const IconsLegends: FC<IconsLegendsProps> = ({ legends, title }) => {
  const { stringify, file } = useLayout();

  return (
    <Flex margin="10px 0 20px 20px" width="90%" justifyContent="space-around">
      <Typography
        fontWeight={600}
        color="gray"
        fontSize={{ _: "1.2rem", lg: "1.4rem" }}
        textTransform="uppercase"
        minWidth="10rem"
        mr="1rem"
      >
        {title}
      </Typography>

      {legends?.map((l, i) => (
        <Flex {...LegendItemStyle} key={i}>
          <ExternalSVG
            width="24px"
            height="24px"
            src={file(l.image as any, { width: 24, height: 24 })}
            {...!l.preserveColor && { fill: "lightgrey" }}
          />
          <Typography {...TextStyle} {...IconTextStyle} whiteSpace="nowrap">{stringify(l.title)}</Typography>
        </Flex>
      ))}
    </Flex>
  );
};

export default IconsLegends;

const LegendItemStyle = {
  marginLeft: "1rem",
  minWidth: "10rem"
};

const IconTextStyle = {
  marginLeft: "0.5rem"
};
