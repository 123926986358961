import { BoxProps, ButtonProps, FlexProps, IconProps, TextProps, XcoreTheme as OriginalXcoreTheme } from "@xcorejs/ui";

import { AccordionHeaderProps } from "./Accordion/AccordionHeader";
import { CloseButtonProps } from "./CloseButton";
import { DropdownItemProps } from "./Dropdown/DropdownItem";
import { CheckboxProps } from "./Form/Checkbox";
import { ControlBoxProps } from "./Form/Checkbox/ControlBox";
import { InputProps } from "./Form/Input";
import { InputAddonProps } from "./Form/Input/InputAddon";
import { LabelProps } from "./Form/Label";
import { IconBoxProps } from "./IconBox";
import { InputSelectProps } from "./Select/Input";
import { MenuSelectProps } from "./Select/Menu";
import { MultiValueSelectProps } from "./Select/MultiValue";
import { OptionSelectProps } from "./Select/Option";
import { PlaceholderSelectProps } from "./Select/Placeholder";
import { SingleValueSelectProps } from "./Select/SingleValue";
import { ValueContainerSelectProps } from "./Select/ValueContainer";
import { TableProps } from "./Table";
import { CellProps } from "./Table/Cell";
import { RowProps } from "./Table/Row";

export interface XcoreTheme extends OriginalXcoreTheme {
  input: {
    invalid: InputProps;
    default: InputProps;
    variant: {
      outline: InputProps;
      line: InputProps;
      filled: InputProps;
      unstyled: InputProps;
    };
    sizes: {
      sm: InputProps;
      md: InputProps;
      lg: InputProps;
    };
    addon: InputAddonProps;
  };
  formControl: {
    additional: TextProps;
    error: TextProps;
    label: LabelProps;
  };
  checkbox: CheckboxProps & {
    control: CheckboxProps &
    ControlBoxProps & {
      sizes: {
        sm: CheckboxProps;
        md: CheckboxProps;
        lg: CheckboxProps;
      };
    };
    children: TextProps & {
      sizes: {
        sm: TextProps;
        md: TextProps;
        lg: TextProps;
      };
    };
    radio: CheckboxProps;
  };
  tables: {
    default: TableProps;
    variant: {
      normal: TableProps & {
        tr: RowProps;
        td: CellProps;
        th: TextProps;
      };
      stripped: TableProps & {
        tr: RowProps;
        td: CellProps;
        th: TextProps;
      };
    };
    tr: RowProps;
    td: CellProps;
    th: TextProps;
  };
  select: {
    control: FlexProps;
    dropdownIndicator: BoxProps;
    separator: BoxProps;
    input: InputSelectProps;
    menu: MenuSelectProps;
    menuList: BoxProps;
    option: OptionSelectProps;
    selectedOption: OptionSelectProps;
    placeholder: PlaceholderSelectProps;
    singleValue: SingleValueSelectProps;
    multiValue: MultiValueSelectProps;
    multiValueRemove: MultiValueSelectProps;
    multiValueContainer: MultiValueSelectProps;
    valueContainer: ValueContainerSelectProps;
    noOptions: TextProps;
  };
  iconBox: IconBoxProps;
  closeButton: {
    default: CloseButtonProps;
    sizes: {
      xs: CloseButtonProps;
      sm: CloseButtonProps;
      md: CloseButtonProps;
      lg: CloseButtonProps;
    };
  };
  accordion: {
    header: AccordionHeaderProps;
    panel: BoxProps;
  };
  carousel: {
    dot: ButtonProps;
    dotActive: ButtonProps;
    arrow: ButtonProps;
  };
  dropdown: {
    box: BoxProps;
    item: DropdownItemProps;
    leftIcon: IconProps;
    rightIcon: IconProps;
    divider: BoxProps;
    group: BoxProps;
    groupHeader: TextProps;
  };
  pagination: {
    default: FlexProps;
    button: ButtonProps;
    buttonActive: ButtonProps;
    more: IconProps;
  };
}

export const theme = {
  list: {
    lineHeight: "2.4rem",
    fontSize: "1.6rem",
    fontFamily: "rubik",
    margin: 0,
    padding: 0,
    _bullet: {
      content: '"\\2022"',
      color: "#152028",
      fontSize: "2.6rem",
      marginRight: "1.5rem"
    },
    _items: {
      color: "#1e3441",
      paddingLeft: ".5rem",
      marginBottom: "1rem"
    }
  },
  input: {
    invalid: {
      borderColor: "#e13030"
    },
    default: {
      padding: "1rem 1.5rem",
      fontFamily: "rubik",
      borderRadius: "0.3rem",
      lineHeight: "2rem",
      border: 0,
      transition: "border 300ms, color 300ms, background 300ms",
      _disabled: {
        opacity: 0.5,
        cursor: "not-allowed"
      }
    },
    sizes: {
      sm: {
        fontSize: "1.3rem",
        padding: "0.5rem 1rem"
      },
      md: {
        fontSize: "1.4rem",
        padding: "0.9rem 1.5rem"
      },
      lg: {
        fontSize: "1.6rem",
        padding: "1.4rem 1.5rem"
      }
    },
    variant: {
      outline: {
        border: "1px solid #d0d5d8",
        color: "rgb(69, 86, 99)",
        _placeholder: {
          color: "rgba(69, 86, 99, 0.5)"
        },
        _hover: {
          border: "1px solid #a2aab2"
        },
        _focus: {
          border: "1px solid #455663",
          outline: 0
        },
        _focusWithin: {
          border: "1px solid #455663",
          outline: 0
        }
      },
      line: {
        paddingLeft: "0.5rem",
        borderBottom: "2px solid #d0d5D8",
        borderRadius: 0,
        background: "transparent",
        color: "rgba(69, 86, 99)",
        _placeholder: {
          color: "rgba(69, 86, 99, 0.5)"
        },
        _hover: {
          borderBottom: "2px solid #a2aab3"
        },
        _focus: {
          borderBottom: "2px solid #455663",
          outline: 0
        }
      },
      filled: {
        background: "#f5f6f7",
        color: "rgb(69, 86, 99)",
        _placeholder: {
          color: "rgba(69, 86, 99, 0.75)"
        },
        _hover: {
          background: "#eceeef"
        },
        _focus: {
          background: "#dadee0",
          outline: 0
        },
        _focusWithin: {
          background: "#dadee0",
          outline: 0
        }
      },
      unstyled: {
        padding: 0,
        fontSize: "inherit",
        lineHeight: "2rem",
        borderRadius: 0,
        background: "transparent",
        color: "rgba(69, 86, 99, 0.5)",
        _hover: {
          color: "rgba(69, 86, 99, 0.8)"
        },
        _focus: {
          color: "rgb(69, 86, 99)",
          outline: "none"
        }
      }
    },
    addon: {
      px: "1.5rem",
      bg: "rgba(69, 86, 99, 0.05)",
      borderLeft: "1px solid #d0d5d8",
      borderRight: "1px solid #d0d5d8",
      borderRadius: "0.3rem",
      fontSize: "1.4rem",
      lineHeight: "2rem",
      fontFamily: "rubik"
    }
  },
  formControl: {
    additional: {
      color: "rgba(69, 86, 99, 0.5)",
      fontFamily: "rubik",
      fontSize: "1.2rem",
      lineHeight: "1.8rem"
    },
    error: {
      color: "#e13030",
      fontFamily: "rubik",
      fontSize: "1.2rem",
      lineHeight: "1.8rem"
    },
    label: {
      _text: {
        marginBottom: "1rem",
        fontSize: "1.4rem",
        lineHeight: "2rem"

      }
    }
  },
  checkbox: {
    control: {
      border: "2px solid #d0d5D8",
      borderRadius: "0.3rem",
      _hover: {
        borderColor: "#a2aab2"
      },
      _checked: {
        bg: "#0171b6",
        border: "2px solid #0171b6"
      },
      sizes: {
        sm: {
          width: "1.6rem",
          height: "1.6rem"
        },
        md: {
          width: "2rem",
          height: "2rem"
        },
        lg: {
          width: "2.4rem",
          height: "2.4rem"
        }
      }
    },
    children: {
      marginLeft: "1rem",
      fontFamily: "rubik",
      sizes: {
        sm: {
          fontSize: "1.3rem"
        },
        md: {
          fontSize: "1.4rem"
        },
        lg: {
          fontSize: "1.6rem"
        }
      }
    },
    radio: {
      bg: "transparent"
    }
  },
  tables: {
    default: {
      fontFamily: "rubik",
      color: "#1e1e1e",
      fontSize: "1.4rem",
      lineHeight: "2.1rem"
    },
    variant: {
      normal: {
        borderTop: "2px solid #dee1e5",
        td: {
          pl: "1.5rem",
          pr: "1.5rem",
          borderBottom: "2px solid #dee1e5",
          height: { _: "auto", sm: "6rem" },
          _first: {
            pl: "3rem"
          },
          _last: {
            pr: "3rem"
          }
        },
        tr: {},
        th: {}
      },
      stripped: {
        border: 0,
        oddLineColor: "#fff",
        evenLineColor: "#dee1e5",
        td: {},
        tr: {},
        th: {}
      }
    },
    tr: {},
    th: {
      fontSize: "1.5rem",
      lineHeight: "2rem",
      borderBottom: "0.2rem solid #1e1e1e",
      padding: "1rem 2.6rem 1.8rem"
    },
    td: {
      padding: "1rem 2.6rem 0.9rem",
      fontFamily: "rubik",
      fontSize: "1.4rem",
      lineHeight: "2.1rem"
    }
  },
  select: {
    control: {
      display: "flex",
      width: "100%",
      flexWrap: "nowrap",
      alignItems: "center",
      border: "2px solid #939598",
      borderRadius: 0,

      _focusWithin: {
        outline: 0,
        borderColor: "#939598"
      }
    },
    dropdownIndicator: {
      padding: "0 3rem"
    },
    separator: {
      background: "transparent"
    },
    input: {
      color: "#1e1e1e",
      margin: 0,
      fontFamily: "rubik",
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: "2rem"
    },
    menu: {
      _inner: {
        mt: "-0.2rem"
      }
    },
    menuList: {
      border: "2px solid #939598",
      padding: "1rem"
    },
    option: {
      fontFamily: "rubik",
      fontSize: "1.4rem",
      lineHeight: "1.9rem",
      color: "#5E6062",
      transition: "background 300ms",

      _hover: {
        background: "#C7CACE",
        color: "#1e1e1e",
        fontWeight: 700
      },

      _focus: {
        outline: "none"
      },

      _inner: {
        padding: "1rem 2rem",
        transition: "color 300ms"
      }
    },
    selectedOption: {
      color: "#1e1e1e",
      fontWeight: 700
    },
    placeholder: {
      color: "#5E6062",
      fontFamily: "rubik",
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: "2rem"
    },
    singleValue: {
      color: "#1e1e1e",
      fontFamily: "rubik",
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: "2rem"
    },
    noOptions: {
      fontFamily: "rubik"
    }
  },
  iconBox: {
    width: "4rem",
    height: "4rem",
    backgroundColor: "#0f1f28",
    _icon: {
      fill: "#fff",
      width: "1.6rem",
      height: "1.6rem"
    }
  },
  closeButton: {
    default: {
      cursor: "pointer",
      _icon: {
        fill: "#455663",
        fillHover: "#1e1e1e"
      }
    },
    sizes: {
      xs: {
        width: "1.2rem",
        height: "1.2rem",
        _icon: {
          width: "0.6rem",
          height: "0.6rem"
        }
      },
      sm: {
        width: "1.6rem",
        height: "1.6rem",
        _icon: {
          width: "0.8rem",
          height: "0.8rem"
        }
      },
      md: {
        width: "2.4rem",
        height: "2.4rem",
        _icon: {
          width: "1.2rem",
          height: "1.2rem"
        }
      },
      lg: {
        width: "3.2rem",
        height: "3.2rem",
        _icon: {
          width: "1.6rem",
          height: "1.6rem"
        }
      }
    }
  },
  accordion: {
    header: {
      p: "1rem",
      bg: "#fff",
      alignItems: "center",
      justifyContent: "space-between",
      borderTop: "1px solid #d0d5d8",
      _icon: {
        fill: "#1e3441",
        fillHover: "#0f1f28"
      },
      _expanded: {
        background: "#0171b6",
        borderTop: "1px solid #0171b6",
        borderRadius: "3px",
        color: "#fff",
        _icon: {
          transform: "rotate(180deg)",
          fill: "#fff"
        }
      }
    },
    panel: {
      position: "relative",
      bg: "#fff",
      p: "1rem 3.6rem 1rem 1rem"
    }
  },
  carousel: {
    dot: {},
    dotActive: {},
    arrow: {}
  },
  dropdown: {
    box: {
      boxShadow: "0 0.3rem 0.8rem 0 rgba(36, 49, 70, 0.25)",
      borderRadius: "3px",
      minWidth: "22rem",
      maxWidth: "32rem",
      bg: "#fff",
      py: "1.4rem"
    },
    item: {
      px: "2rem",
      minHeight: "3.2rem",
      fontFamily: "rubik",
      fontSize: "1.4rem",
      lineHeight: "2rem",
      cursor: "pointer",

      _hover: {
        background: "rgba(69, 86, 99, 0.1)"
      }
    },
    leftIcon: {},
    rightIcon: {},
    divider: {
      display: "block",
      height: "1px",
      marginTop: ".8rem",
      marginBottom: ".7rem",
      backgroundColor: "rgba(69, 86, 99, 0.1)"
    },
    group: {},
    groupHeader: {
      px: "2rem",
      py: "0.6rem",
      m: 0,
      color: "#0f1f28",
      fontFamily: "rubik",
      fontSize: "1.2rem",
      fontWeight: 500,
      lineHeight: "2rem",
      textTransform: "uppercase"
    }
  },
  pagination: {
    default: {
      px: 0,
      m: 0,
      display: "flex",
      justifyContent: "center"
    },
    button: {
      cursor: "pointer",
      mx: "0.5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "4rem",
      width: "4rem",
      border: "2px solid #dee1e5",
      backgroundColor: "#fff",
      padding: 0,
      color: "#5e6062",
      fontFamily: "avenir",
      fontWeight: 600,
      fontSize: "1.5rem",
      lineHeight: "2rem",
      _hover: {
        borderColor: "#939598",
        color: "#1e1e1e"
      },
      _focus: {
        background: "#fff",
        borderColor: "#939598",
        color: "#1e1e1e",
        outline: "none"
      }
    },
    buttonActive: {
      background: "#dee1e5",
      _hover: {
        cursor: "not-allowed",
        background: "#dee1e5"
      },
      _focus: {
        background: "#dee1e5",
        outline: "none"
      }
    },
    more: {
      display: "flex",
      alignItems: "center",
      px: "0.5rem",
      fill: "#dee1e5"
    }
  }
};
