import { text } from "@xcorejs/ui";

export const textTheme = text({
  default: {
    fontFamily: "avenir",
    fontSize: "1.5rem",
    lineHeight: "2.5rem",
    transition: "color 300ms",
    color: "#5E6062"
  },
  variants: {
    strong: {
      color: "#1e1e1e"
    }
  }
});
