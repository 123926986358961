import { Flex, FlexProps, Icon, IconProps, useTheme } from "@xcorejs/ui";
import { FC, ReactNode } from "react";
import styled from "styled-components";

import { XcoreTheme } from "./theme";

export type IconBoxProps = {
  icon?: ReactNode;
  node?: ReactNode;
  _icon?: IconProps;
} & FlexProps;

export const IconBoxStyle = styled(Flex)<IconBoxProps>``;

IconBoxStyle.defaultProps = {
  justifyContent: "center",
  alignItems: "center"
};

const IconBox: FC<IconBoxProps> = (
  {
    icon,
    _icon,
    node,
    ...props
  }
) => {
  const theme = useTheme() as XcoreTheme;
  const iconBoxProps = theme.iconBox;

  return (
    <IconBoxStyle {...iconBoxProps} {...props}>
      {icon &&
        <Icon svg={icon} {...iconBoxProps._icon} {..._icon} />}
      {node && node}
    </IconBoxStyle>
  );
};

IconBox.displayName = "IconBox";

export default IconBox;
