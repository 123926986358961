import { Link } from "@appiodev/xcore-client/xcore-ui";
import { Flex } from "@xcorejs/ui";
import NewsCard from "design-system/robe/cards/News";
import RobeCarousel from "design-system/robe/Carousel";
import { FC } from "react";
import { News } from "xcore/types";

export type TempNewsCarouselProps = {
  news: News[];
  backTo?: number;
};

const NewsCarousel: FC<TempNewsCarouselProps> = ({ news, backTo, ...props }) => (
  <RobeCarousel
    width="100%"
    dots
    bg="transparent"
    slides={news.map(c => (
      <Flex key={0} justifyContent="center" width="100%">
        <Link
          key={c.id}
          content={c}
          variant="simple"
          overflow="hidden"
          maxWidth="30rem"
          display="flex"
          {...backTo && { query: `?backto=${backTo}` }}
        >
          <NewsCard content={c} variant="default" />
        </Link>
      </Flex>
    ))}
    responsive={{
      desktop: {
        breakpoint: { max: 3000, min: 768 },
        items: 1
      },
      sm: {
        breakpoint: { max: 767, min: 480 },
        items: 1
      },
      xs: {
        breakpoint: { max: 479, min: 0 },
        items: 1
      }
    }}
    arrows={false}
    pb="8rem"
    {...props}
  />
);

export default NewsCarousel;
